import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Select,
  Button,
  Input,
  Form,
  message,
  Checkbox,
  Alert,
  InputNumber,
  Radio,
} from "antd";
import _ from "lodash";

const { Option } = Select;
const RadioGroup = Radio.Group;
class AddCustomer extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      designation: [],
      loading: false,
      iscitclient: false,
      Services: [],
      pincodestatus: "",
      emailexists: true,
      Access: [],
    };
  }
  onChange = (e) => {
    console.log(e.target.value);
    this.setState({
      iscitclient: !e.target.value,
    });
  };
  componentWillMount() {
    console.log(this.props.userinfo);
    this.props.getServices().then((res) => {
      console.log(res);
      this.setState({
        Services: res,
      });
    });
    this.props.getAccess().then((res) => {
      console.log(res);
      this.setState({
        Access: res,
      });
    });
  }

  checkEmail(email) {
    console.log(email);
    this.props
      .checkEmail(email)
      .then((res) => {
        this.setState({
          emailexists: false,
        });
        this.props.form.setFields({
          Email: {
            value: email,
            errors: [new Error("Email already exists!")],
          },
        });
      })
      .catch((err) => {
        this.setState({
          emailexists: true,
        });
      });
  }

  pincodechange = (e) => {
    // console.log(e);
    var pin = e.target.value;
    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
    if (reg.test(pin) && pin.length === 6) {
      this.setState({
        pincodestatus: "validating",
      });
      this.props
        .checkPincode(pin)
        .then((res) => {
          var pinfo = res.info[0].info;
          console.log(pinfo);
          this.props.form.setFieldsValue({
            stateCode: pinfo.state,
            cityCode:
              pinfo.city === "" ||
              pinfo.city === undefined ||
              pinfo.city === null
                ? pinfo.district
                : pinfo.city,
          });
          this.setState({
            pincodestatus: "success",
          });
        })
        .catch((err) => {
          message.error("This Pincode is not under our serviceable area!");
          this.setState({
            pincodestatus: "error",
          });
          this.props.form.setFieldsValue({
            stateCode: "",
            cityCode: "",
          });
        });
    } else {
      this.setState({
        pincodestatus: "error",
      });
    }
  };
  onChangeRangeFrom = (e) => {
    const {
      form: { validateFields, setFields },
    } = this.props;
    setTimeout(() => {
      validateFields(["docketRangefrom", "docketRangeto"], (errors, values) => {
        console.log(errors, values);
        if (
          values.docketRangeto < values.docketRangefrom &&
          values.docketRangeto !== undefined
        ) {
          setFields({
            docketRangeto: {
              value: "",
              errors: [new Error("Select valid date")],
            },
          });
        }
      });
    }, 100);
  };
  onChangeRangeTo = (e) => {
    const {
      form: { validateFields, setFields },
    } = this.props;
    setTimeout(() => {
      validateFields(["docketRangefrom", "docketRangeto"], (errors, values) => {
        console.log(errors, values);
        if (values.docketRangeto < values.docketRangefrom) {
          setFields({
            docketRangeto: {
              value: "",
              errors: [new Error("Select valid date")],
            },
          });
        }
      });
    }, 100);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.state.emailexists) {
          this.setState({
            loading: true,
          });
          console.log(
            "Received values of form: ",
            values,
            this.props.userinfo.employeeID
          );
          this.props
            .addCustomer(values, this.props.userinfo.employeeID)
            .then((res) => {
              setTimeout(() => {
                message.success("Customer created successfully!");
                this.props.form.resetFields();
                this.setState({
                  loading: false,
                });
              }, 3000);
            })
            .catch((res) => {
              message.error(res.Message);
              this.setState({
                loading: false,
              });
            });
        } else {
          this.props.form.setFields({
            Email: {
              errors: [new Error("Email already exists!")],
            },
          });
        }
      }
    });
  };

  mobilevalidation = (value) => {
    console.log(value);
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    // this.props.form.setFieldsValue({
    //   MobileNo: value.match(phoneno) ? value : "000",
    // });
    if (value.test(phoneno)) {
      console.log(value.match(phoneno));
      this.props.form.setFieldsValue({
        MobileNo: value,
      });
    }
  };

  renderHubs() {
    return _.map(this.state.hubs, (hub, index) => {
      return (
        <Option value={hub.hubCode} key={index}>
          {hub.hubName}
        </Option>
      );
    });
  }

  renderServices() {
    return _.map(this.state.Services, (cus, index) => {
      return (
        <Option value={cus.serviceId} label={cus.serviceId} key={index}>
          {cus.Name}
        </Option>
      );
    });
  }

  renderAccess() {
    return _.map(this.state.Access, (cus, index) => {
      return (
        <Option value={cus.id} label={cus.Name} key={index}>
          {cus.Name}
        </Option>
      );
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loading, iscitclient } = this.state;
    // eslint-disable-next-line
    var mailformat =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    return (
      <div className="container-fluid">
        <div className="row padd20 bg-grey">
          <div className="col-xs-6">
            <h4>Add Customer</h4>
          </div>
          <div className="col-xs-6 text-right">
            <Link to="/customers">
              <Button type="default">Back</Button>
            </Link>
          </div>
        </div>

        <div className="customerform bg-white">
          <Form onSubmit={this.handleSubmit} className="addbookform row">
            <Form.Item className="col-sm-12 col-xs-12 padd15 bg-beige">
              <h5>Customer Info</h5>
            </Form.Item>
            <Form.Item
              label="Customer Name"
              className="col-md-3 col-sm-6 col-xs-12"
            >
              {getFieldDecorator("CompanyName", {
                rules: [
                  {
                    required: true,
                    pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                    message: "Please enter valid Customer Name!",
                  },
                ],
              })(<Input placeholder="Enter Customer Name" />)}
            </Form.Item>

            <Form.Item
              label="Product List"
              className="col-md-6 col-sm-6 col-xs-12"
            >
              {getFieldDecorator("CompanyType", {
                rules: [
                  {
                    required: true,
                    message: "Please input Product List!",
                  },
                ],
              })(
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Select Product List"
                  maxTagCount={2}
                  // optionFilterProp="children"
                  // optionLabelProp="label"
                  // filterOption={(input, option) =>
                  //   option.props.children
                  //     .toLowerCase()
                  //     .indexOf(input.toLowerCase()) >= 0
                  // }
                  // onChange={this.onChange}
                >
                  {this.renderServices()}
                </Select>
              )}
            </Form.Item>

            <Form.Item label="Address" className="col-md-3 col-sm-6 col-xs-12">
              {getFieldDecorator("Address", {
                rules: [
                  {
                    required: true,
                    message: "Please input Address!",
                  },
                ],
              })(<Input placeholder="Enter Address" />)}
            </Form.Item>

            <Form.Item
              label="Pincode"
              className="col-md-3 col-sm-6 col-xs-12"
              hasFeedback
              validateStatus={this.state.pincodestatus}
            >
              {getFieldDecorator("Pincode", {
                rules: [
                  {
                    required: true,
                    message: "Pincode Wrong format!",
                  },
                ],
              })(
                <Input
                  placeholder="Enter Pincode"
                  onChange={this.pincodechange}
                  maxLength={6}
                  minLength={6}
                  autoComplete="new-password"
                />
              )}
            </Form.Item>
            <Form.Item label="City" className="col-md-3 col-sm-6 col-xs-12">
              {getFieldDecorator("cityCode", {
                initialValue: "",
                rules: [
                  {
                    required: false,
                    message: "Please input City Code!",
                  },
                ],
              })(<Input placeholder="Enter City Code" readOnly />)}
            </Form.Item>
            <Form.Item label="State" className="col-md-3 col-sm-6 col-xs-12">
              {getFieldDecorator("stateCode", {
                initialValue: "",
                rules: [
                  {
                    required: false,
                    message: "Please input State!",
                  },
                ],
              })(<Input placeholder="Enter State" readOnly />)}
            </Form.Item>

            <Form.Item label="Email" className="col-md-3 col-sm-6 col-xs-12">
              {getFieldDecorator("Email", {
                rules: [
                  {
                    required: true,
                    pattern: new RegExp(mailformat),
                    message: "Please enter valid Email!",
                  },
                ],
              })(
                <Input
                  type="email"
                  placeholder="Enter Email"
                  onBlur={(e) => this.checkEmail(e.target.value)}
                />
              )}
            </Form.Item>

            <Form.Item
              label="Mobile No"
              className="col-md-3 col-sm-6 col-xs-12"
            >
              {getFieldDecorator("MobileNo", {
                rules: [
                  {
                    required: true,
                    pattern: new RegExp(/^[0-9]{10,10}$/),
                    message: "Please enter valid MobileNo!",
                  },
                ],
              })(
                <InputNumber
                  placeholder="Enter MobileNo"
                  minLength={10}
                  maxLength={10}
                  min={0}
                  step={0}
                  autoComplete="new-password"
                />
              )}
            </Form.Item>

            <Form.Item
              label="GST Number"
              className="col-md-3 col-sm-6 col-xs-12"
            >
              {getFieldDecorator("GSTnumber", {
                rules: [
                  {
                    required: true,
                    pattern: new RegExp(/^[a-z0-9]+$/i),
                    message: "Please input GSTnumber!",
                  },
                ],
              })(<Input placeholder="Enter GSTnumber" />)}
            </Form.Item>

            <Form.Item
              label="PAN number"
              className="col-md-3 col-sm-6 col-xs-12"
            >
              {getFieldDecorator("PANnumber", {
                rules: [
                  {
                    required: true,
                    pattern: new RegExp(/^[a-z0-9]+$/i),
                    message: "Please input PANnumber!",
                  },
                ],
              })(<Input placeholder="Enter PANnumber" />)}
            </Form.Item>

            {/* <Form.Item
              label="GST number 2"
              className="col-md-3 col-sm-6 col-xs-12"
            >
              {getFieldDecorator("GSTnumber2", {
                rules: [
                  {
                    required: false,
                    message: "Please input GSTnumber 2!",
                  },
                ],
              })(<Input placeholder="Enter GSTnumber 2" />)}
            </Form.Item> */}
            <Form.Item
              label="Contact person name"
              className="col-md-3 col-sm-6 col-xs-12"
            >
              {getFieldDecorator("contactpersonname", {
                rules: [
                  {
                    required: true,
                    pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                    message: "Please enter valid name!",
                  },
                ],
              })(<Input placeholder="Enter name" />)}
            </Form.Item>

            <Form.Item
              label="Contact person Email"
              className="col-md-3 col-sm-6 col-xs-12"
            >
              {getFieldDecorator("contactpersonemail", {
                rules: [
                  {
                    required: true,
                    pattern: new RegExp(mailformat),
                    message: "Please enter valid Contact person Email!",
                  },
                ],
              })(<Input type="email" placeholder="Enter Email" />)}
            </Form.Item>

            <Form.Item
              label="Contact person Mobile"
              className="col-md-3 col-sm-6 col-xs-12"
            >
              {getFieldDecorator("contactpersonmobile", {
                rules: [
                  {
                    required: true,
                    pattern: new RegExp(/^[0-9]{10,10}$/),
                    message: "Please input first name!",
                  },
                ],
              })(
                <InputNumber
                  placeholder="Enter Contact Person Number"
                  minLength={10}
                  maxLength={10}
                  min={0}
                  step={0}
                  autoComplete="new-password"
                />
              )}
            </Form.Item>

            {/* <Form.Item
              label="Last Name"
              className="col-md-3 col-sm-6 col-xs-12"
            >
              {getFieldDecorator("Lastname", {
                rules: [{ required: true, message: "Please input Last name!" }],
              })(<Input placeholder="Enter Last name" />)}
            </Form.Item> */}
            <Form.Item
              label="Is CIT Customer"
              className="col-md-2 col-sm-6 col-xs-12"
            >
              {getFieldDecorator("IsCITClient", {
                initialValue: false,
                rules: [
                  {
                    required: false,
                    message: "Please input Is CIT Customer!",
                  },
                ],
              })(<Checkbox onChange={this.onChange} />)}
            </Form.Item>
            {iscitclient ? (
              <div>
                <Form.Item
                  label="Docket Range From"
                  className="col-md-2 col-sm-3 col-xs-12 paddlr15"
                >
                  {getFieldDecorator("docketRangefrom", {
                    rules: [
                      {
                        required: true,
                        pattern: new RegExp(/^[0-9]/),
                        message: "Please input From!",
                      },
                    ],
                  })(
                    <InputNumber
                      placeholder="Enter From"
                      onChange={this.onChangeRangeFrom}
                    />
                  )}
                </Form.Item>
                <Form.Item
                  label="To"
                  className="col-md-2 col-sm-3 col-xs-12 paddlr15"
                >
                  {getFieldDecorator("docketRangeto", {
                    rules: [
                      {
                        required: true,
                        pattern: new RegExp(/^[0-9]/),
                        message: "Please input To!",
                      },
                    ],
                  })(
                    <InputNumber
                      placeholder="Enter To"
                      onChange={this.onChangeRangeTo}
                    />
                  )}
                </Form.Item>
              </div>
            ) : null}

            {/* <Form.Item className="col-sm-12 col-xs-12 padd15 bg-beige">
              <h5>Insurance details</h5>
            </Form.Item>
            <Form.Item
              label="Use Shippers's Policy"
              className="col-md-3 col-sm-6 col-xs-12 paddlr15"
            >
              {getFieldDecorator("shipperPolicy", {
                initialValue: false,
                rules: [
                  {
                    required: false,
                    message: "Please input Is CIT Client!",
                  },
                ],
              })(<Checkbox 
              // onChange={this.onChange} 
              />)}
            </Form.Item>
            <Form.Item
              label="Insurance company"
              className="col-md-3 col-sm-6 col-xs-12 paddlr15"
            >
              {getFieldDecorator("insuranceCompany", {
                rules: [
                  {
                    required: true,
                    message: "Please input insuranceCompany!",
                  },
                ],
              })(<Input placeholder="Enter Insurance Company" />)}
            </Form.Item>
            <Form.Item
              label="Policy number"
              className="col-md-3 col-sm-6 col-xs-12 paddlr15"
            >
              {getFieldDecorator("Policynumber", {
                rules: [
                  {
                    required: true,
                    message: "Please input Policynumber!",
                  },
                ],
              })(<Input placeholder="Enter Policynumber" />)}
            </Form.Item>
            <Form.Item
              label="Policy start date"
              className="col-md-3 col-sm-6 col-xs-12 paddlr15"
            >
              {getFieldDecorator("PolicyStartdate", {
                rules: [
                  {
                    required: true,
                    message: "Please input PolicyStartdate!",
                  },
                ],
              })(<DatePicker />)}
            </Form.Item>
            <Form.Item
              label="Policy end date"
              className="col-md-3 col-sm-6 col-xs-12 paddlr15"
            >
              {getFieldDecorator("PolicyEnddate", {
                rules: [
                  {
                    required: true,
                    message: "Please input PolicyEnddate!",
                  },
                ],
              })(<DatePicker />)}
            </Form.Item>
             */}

            <Form.Item className="col-sm-12 col-xs-12 padd15 bg-beige">
              <h5>AWB details</h5>
            </Form.Item>
            <Form.Item
              label="Max value allowed per AWB"
              className="col-md-3 col-sm-6 col-xs-12 paddlr15"
            >
              {getFieldDecorator("ConnoteMaxvalue", {
                rules: [
                  {
                    required: true,
                    message: "Please input AWB Maxvalue!",
                  },
                ],
              })(
                <InputNumber placeholder="Enter AWB Maxvalue" precision={2} />
              )}
            </Form.Item>
            <Form.Item
              // {...formItemLayout}
              label="Lock Number Entry"
              className="col-md-4 col-sm-6 col-xs-12 paddlr15"
            >
              {getFieldDecorator("Locknumberentrymandatory", {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: "Please input Is Lock Number Entry!",
                  },
                ],
              })(
                <RadioGroup>
                  <Radio value="true">True</Radio>
                  <Radio value="false">False</Radio>
                </RadioGroup>
              )}
            </Form.Item>
            <Form.Item
              label="Email alert required to be sent on AWB creation/delivery?"
              className="col-md-4 col-sm-6 col-xs-12 paddlr15"
            >
              {getFieldDecorator("IsEmailalert", {
                initialValue: false,
                rules: [
                  {
                    required: false,
                    message: "Please input IsEmailalert",
                  },
                ],
              })(
                <Checkbox
                // onChange={this.onChange}
                />
              )}
            </Form.Item>
            <Form.Item
              label="SMS alert required to be sent on AWB creation/delivery?"
              className="col-md-6 col-sm-6 col-xs-12 paddlr15"
            >
              {getFieldDecorator("IsSMSalert", {
                initialValue: false,
                rules: [
                  {
                    required: false,
                    message: "Please inputIsSMSalert!",
                  },
                ],
              })(
                <Checkbox
                // onChange={this.onChange}
                />
              )}
            </Form.Item>
            <Form.Item
              label="Is box number auto generation required?"
              className="col-md-6 col-sm-6 col-xs-12 paddlr15"
            >
              {getFieldDecorator("IsboxnumAutoenerate", {
                initialValue: false,
                rules: [
                  {
                    required: false,
                    message: "Please input Is auto generation required?",
                  },
                ],
              })(
                <Checkbox
                // onChange={this.onChange}
                />
              )}
            </Form.Item>
            <Form.Item className="col-sm-12 col-xs-12 padd15 bg-beige">
              <h5>Preferences</h5>
            </Form.Item>
            <Form.Item
              label="Is Active"
              className="col-md-3 col-sm-6 col-xs-12 paddlr15"
            >
              {getFieldDecorator("IsActive", {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: "Please input Is Active!",
                  },
                ],
              })(
                // <Checkbox
                // />
                <RadioGroup>
                  <Radio value="true">Active</Radio>
                  <Radio value="false">In-Active</Radio>
                </RadioGroup>
              )}
            </Form.Item>

            <Form.Item
              label="Is Reverse Shipment"
              className="col-md-3 col-sm-6 col-xs-12 paddlr15"
            >
              {getFieldDecorator("IsReverseShipment", {
                initialValue: false,
                rules: [
                  {
                    required: false,
                    message: "Please input Is Reverse Shipment!",
                  },
                ],
              })(
                <Checkbox
                // onChange={this.onChange}
                />
              )}
            </Form.Item>
            <Form.Item
              label="COD FLag"
              className="col-md-3 col-sm-6 col-xs-12 paddlr15"
            >
              {getFieldDecorator("CODFLag", {
                initialValue: false,
                rules: [
                  {
                    required: false,
                    message: "Please input COD FLag!",
                  },
                ],
              })(
                <Checkbox
                // onChange={this.onChange}
                />
              )}
            </Form.Item>
            <Form.Item
              label="OTP Flag"
              className="col-md-3 col-sm-6 col-xs-12 paddlr15"
            >
              {getFieldDecorator("OTPFlag", {
                initialValue: false,
                rules: [
                  {
                    required: false,
                    message: "Please input OTP Flag!",
                  },
                ],
              })(
                <Checkbox
                // onChange={this.onChange}
                />
              )}
            </Form.Item>
            <Form.Item
              label="CFT Division value"
              className="col-md-3 col-sm-6 col-xs-12 paddlr15"
            >
              {getFieldDecorator("CFTDivisionvalue", {
                rules: [
                  {
                    required: true,
                    pattern: new RegExp(/^\d*$/),
                    message: "Please input CFT Division value!",
                  },
                ],
              })(<InputNumber placeholder="Enter CFT Division value" />)}
            </Form.Item>
            <Form.Item
              label="CFT Division type"
              className="col-md-3 col-sm-6 col-xs-12 paddlr15"
            >
              {getFieldDecorator("CFTDivisiontype", {
                rules: [
                  {
                    required: false,
                    pattern: new RegExp(/^\d*$/),
                    message: "Please input CFT Division type!",
                  },
                ],
              })(<Input placeholder="Enter CFT Division type" />)}
            </Form.Item>
            <Form.Item
              label="Customer API"
              className="col-md-3 col-sm-6 col-xs-12 paddlr15"
            >
              {getFieldDecorator("ClientAPI", {
                rules: [
                  {
                    required: false,
                    message: "Please input Customer API!",
                  },
                ],
              })(<Input placeholder="Enter Customer API" />)}
            </Form.Item>
            <Form.Item
              label="Customer Type"
              className="col-md-3 col-sm-6 col-xs-12"
            >
              {getFieldDecorator("clientType", {
                rules: [
                  {
                    required: false,
                    message: "Please select Consignee Type..!",
                  },
                ],
              })(
                <Select
                  placeholder="select Customer Type"
                  style={{ width: "100%" }}
                  // onChange={this.handleChange}
                >
                  <Option value="B2B">B2B</Option>
                  <Option value="B2C">B2C</Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item
              label="Menu Access"
              className="col-md-3 col-sm-6 col-xs-12"
            >
              {getFieldDecorator("MenuAccess", {
                initialValue: [1],
                rules: [
                  {
                    required: false,
                    message: "Please input Menu Access!",
                  },
                ],
              })(
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Select Menu Access"
                  optionFilterProp="children"
                  optionLabelProp="label"
                  // filterOption={(input, option) =>
                  //   option.props.children
                  //     .toLowerCase()
                  //     .indexOf(input.toLowerCase()) >= 0
                  // }
                  // onChange={this.onChange}
                >
                  {this.renderAccess()}
                </Select>
              )}
            </Form.Item>
            <div className="padd15 col-xs-12">
              <Alert
                message="Note:"
                description="Customer will be created with Unique account number and mobile number will be password. Email will be unique account identifier for each customer."
                type="info"
              />
            </div>
            <div className="padd15 col-xs-12">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
              >
                Create Customer
              </Button>
              <br />
              <br />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user, userinfo: state.userinfo };
}
const WrappedAddCustomer = Form.create({ name: "AddCustomer" })(AddCustomer);
export default connect(mapStateToProps, {})(WrappedAddCustomer);
