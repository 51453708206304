import axiosInstance from "./AxiosInstance";
import { GET_INVENTORY_ORDER } from "../actionTypes";

export function getinventoryorder(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = `/api/v1/inventory_request_order_list?office_id=${
        params.office_id ?? ""
      }&status=${params.status ?? ""}&approval_status=${
        params.approval_status ?? ""
      }&filter=${params.sort ?? ""}&sort_by=${params.sort_by ?? ""}&sort=${
        params.sort ?? ""
      }&keyword=${params.search_text ?? ""}&page=${params.page}&retailer_id=${
        params.retailer_id ?? ""
      }`;
      console.log(`url`, url);
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_INVENTORY_ORDER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getagedinventory(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          "/api/v1/aged_inventory_summary_reports/retailer?page=" + params.page
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_INVENTORY_ORDER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function getagedinventorymarket(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          params?.url
            ? params?.url
            : "/api/v1/aged_inventory_summary_reports/ibo_marketer?page=" +
                params.page
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_INVENTORY_ORDER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getagedinventoryretailer(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          "/api/v1/aged_inventory_summary_reports/retailer?page=" + params.page
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_INVENTORY_ORDER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getagedinventorymanager(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          "/api/v1/aged_inventory_summary_reports/manager?page=" + params.page
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_INVENTORY_ORDER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getagedinventoryagent(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          params?.url
            ? params?.url
            : "/api/v1/aged_inventory_summary_reports/agent?page=" + params.page
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_INVENTORY_ORDER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getagedinventoryoffice(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          params?.url
            ? params?.url
            : "/api/v1/aged_inventory_summary_reports/office?page=" +
                params.page
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_INVENTORY_ORDER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function InventoryIbo(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          params?.url
            ? params?.url
            : `api/v1/inventory_summary_reports/ibo_marketer?filter=${
                params.sort ?? ""
              }&sort_by=${params.sort_by ?? ""}&sort=${
                params.sort ?? ""
              }&ibo_marketer_id=${params.retailer ?? ""}&page=${
                params.page ?? 1
              }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_INVENTORY_ORDER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}

export function InventoryAgent(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          params?.url
            ? params?.url
            : `api/v1/inventory_summary_reports/agent?filter=${
                params.sort ?? ""
              }&sort_by=${params.sort_by ?? ""}&sort=${
                params.sort ?? ""
              }&agent_id=${params.retailer ?? ""}&page=${params.page ?? 1}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_INVENTORY_ORDER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}

export function Inventoryretail(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          params?.url
            ? params?.url
            : `api/v1/inventory_summary_reports/retailer?filter=${
                params.sort ?? ""
              }&sort_by=${params.sort_by ?? ""}&sort=${
                params.sort ?? ""
              }&retailer_id=${params.retailer ?? ""}&page=${params.page ?? 1}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_INVENTORY_ORDER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}

export function InventoryOffice(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          params?.url
            ? params?.url
            : `api/v1/inventory_summary_reports/office?filter=${
                params.sort ?? ""
              }&sort_by=${params.sort_by ?? ""}&sort=${
                params.sort ?? ""
              }&office_id=${params.retailer ?? ""}&page=${params.page ?? 1}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_INVENTORY_ORDER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}

export function InventoryManager(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          params?.url
            ? params?.url
            : `api/v1/inventory_summary_reports/manager?filter=${
                params.sort ?? ""
              }&sort_by=${params.sort_by ?? ""}&sort=${
                params.sort ?? ""
              }&manager_id=${params.retailer ?? ""}&page=${params.page ?? 1}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_INVENTORY_ORDER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}

export function DetailInventoryView(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          params?.url
            ? params?.url
            : `api/v1/detailed_inventory_report?page=${params.page}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_INVENTORY_ORDER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}

export function inventory_dashboard() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/inventory_dashboard")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getrmareport(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/rma_report?page=" + params.page)
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_INVENTORY_ORDER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getrmarequestaccept(params, obj_arr) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params, obj_arr);
      axiosInstance
        .post(
          "/api/v1/inventory_request_order_accept_reject/" + params,
          obj_arr
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          if (error.response.status === 400) {
            reject(error.response.data.message ?? error.response.data.error);
          } else {
            reject("Something Went Wrong..!");
          }
        });
    });
  };
}

// Inventory order
export function createinventoryorder(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`/api/v1/inventory_request_order`, params)
        .then(function (response) {
          console.log(response);
          resolve(response);
          // dispatch({
          //   type: GET_RMA_PENDING,
          //   payload: response.data,
          // });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function inventorylog(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/v1/inventory_request_order_log/${id}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getinventoryupload(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/v1/inventory_upload", params)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function getinventoryallocationupload(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/v1/inventory_allocation_file_upload", params)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function egetinventoryallocationupload(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/v1/inventory_upload", params)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getinventoryuploadlist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      //console.log(params);
      axiosInstance
        .get("/api/v1/inventory_upload_status?page=" + params.page)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getinventoryallocationuploadlist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      //console.log(params);
      axiosInstance
        .get(
          "/api/v1/inventory_allocation_file_upload_status?page=" + params.page
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function egetinventoryallocationuploadlist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      //console.log(params);
      axiosInstance
        .get("/api/v1/inventory_upload_status?page=" + params.page)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function purchaseorderview(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("api/v1/purchase_order_report?page=" + params.page)
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_INVENTORY_ORDER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}

export function getinventorystatus() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      //console.log(params);
      axiosInstance
        .get("/api/v1/reference/inventory_status")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getdevicelogstatus(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      //console.log(params);
      axiosInstance
        .get("/api/v1/device_log/" + params)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Aged Inventory Office
export function getagedinventoryofficesearch(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          params?.url
            ? params?.url
            : `/api/v1/aged_inventory_summary_reports/office?filter=${
                params.sort ?? ""
              }&sort_by=${params.sort_by ?? ""}&sort=${
                params.sort ?? ""
              }&office_id=${params.summary ?? ""}&page=${params.page ?? 1}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
          // dispatch({
          //   type: GET_INVENTORY_ORDER,
          //   payload: response.data,
          // });
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}

// Aged Inventory Market
export function getagedinventorymarketsearch(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          params?.url
            ? params?.url
            : `/api/v1/aged_inventory_summary_reports/ibo_marketer?filter=${
                params.sort ?? ""
              }&sort_by=${params.sort_by ?? ""}&sort=${
                params.sort ?? ""
              }&ibo_marketer_id=${params.market ?? ""}&page=${params.page ?? 1}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
          // dispatch({
          //   type: GET_INVENTORY_ORDER,
          //   payload: response.data,
          // });
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}

// Aged Inventory Manager
export function getagedinventorymanagersearch(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          params?.url
            ? params?.url
            : `/api/v1/aged_inventory_summary_reports/manager?filter=${
                params.sort ?? ""
              }&sort_by=${params.sort_by ?? ""}&sort=${
                params.sort ?? ""
              }&manager_id=${params.manager ?? ""}&page=${params.page ?? 1}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
          // dispatch({
          //   type: GET_INVENTORY_ORDER,
          //   payload: response.data,
          // });
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}

// Aged Inventory Agent
export function getagedinventoryagentsearch(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          params?.url
            ? params?.url
            : `/api/v1/aged_inventory_summary_reports/agent?filter=${
                params.sort ?? ""
              }&sort_by=${params.sort_by ?? ""}&sort=${
                params.sort ?? ""
              }&agent_id=${params.agent ?? ""}&page=${params.page ?? 1}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
          // dispatch({
          //   type: GET_INVENTORY_ORDER,
          //   payload: response.data,
          // });
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}

// Aged Inventory Market
export function getrerunjob(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/v1/rerun_inventory_allocation_job", params)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}

// Aged Inventory Market
export function getapproveorder(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("api/v1/verify_inventory_order/" + params.id)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}

// Aged Inventory Market
export function getfulfillorder(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("api/v1/fulfill_inventory_order/" + params.id)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}

// updatetrackinginfo
export function updatetrackinginfo(id, params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("api/v1/update_tracking_number_to_inventory_order/" + id, params)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}

// to get inventory order summary boxes

export function inventory_order_summary() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("api/v1/inventory_order_summary")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}

export function inventory_office_search(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          params?.url
            ? params?.url
            : `/api/v1/purchase_order_report?sort_by=${
                params.sort_by ?? ""
              }&sort=${params.sort ?? ""}&office_id=${
                params.office ?? ""
              }&page=${params.page ?? 1}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}

export function exportallocationreportlist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);

      var url = `/api/v1/inventory_allocation_report_export
?sort_by=${params.sort_by ?? ""}&sort=${params.sort ?? ""}&office_id=${
        params.office ?? ""
      }`;

      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function exportinventoryorder(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = `/api/v1/inventory_request_orders_export?office_id=${
        params.office_id ?? ""
      }&status=${params.status ?? ""}&approval_status=${
        params.approval_status ?? ""
      }&filter=${params.sort ?? ""}&sort_by=${params.sort_by ?? ""}&sort=${
        params.sort ?? ""
      }&keyword=${params.search_text ?? ""}`;
      console.log(`url`, url);
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_INVENTORY_ORDER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
//Edit Order
export function get_rmarequest_edit(params, obj_arr) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params, obj_arr);
      axiosInstance
        .post(
          "/api/v1/update_inventory_request_order_details/" + params,
          obj_arr
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          if (error.response.status === 400) {
            reject(error.response.data.message ?? error.response.data.error);
          } else {
            reject("Something Went Wrong..!");
          }
        });
    });
  };
}
