import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getinventoryorder,
  getrmarequestaccept,
} from "../../actions/inventoryAction";
import _ from "lodash";
import { Button, Table, Tag, Input, Select, Divider, Modal, Icon } from "antd";
const { Option } = Select;
const { confirm } = Modal;
class InventoryOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      marketer: [],
      data: [],
      pagination: {},
      loading: true,
      retailer: [],
      selectedItems: [],
    };
  }

  componentWillMount() {
    this.getdesignation({
      results: 10,
      page: 1,
    });
  }

  getdesignation(params = {}) {
    this.props
      .getinventoryorder(params)
      .then((res) => {
        console.log(res);
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.items.total;
        this.setState({
          data: res.data.items.data,
          loading: false,
          pagination,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }

  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      results: pagination.pageSize,
      page: pagination.current,
    });
  };
  fetch = (params = {}) => {
    console.log("params:", params);
    this.getdesignation(params);
  };

  searchMarketer = () => {
    this.setState({ loading: true });
    var status = this.state.status;
    var search_text = this.state.name;
    var retailer = this.state.selectedItems;
    console.log(
      `status: ${status} search_text: ${search_text} retailer: ${retailer}`
    );
    this.props
      .searchibomarket({
        status: status,
        search_text: search_text,
        retailer: retailer,
      })
      .then((res) => {
        console.log(res);
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.items.total;
        this.setState({
          data: res.data.items.data,
          loading: false,
          pagination,
        });
      })
      .catch((err) => {});
  };

  clearSearch = () => {
    this.setState({ loading: true });
    this.setState({
      status: undefined,
      name: "",
      selectedItems: [],
    });

    this.getdesignation({
      results: 10,
      page: 1,
    });
  };
  renderRetailer() {
    return _.map(this.state.retailer, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  onchangeRole = (selectedItems) => {
    this.setState({ selectedItems });
    console.log(this.state.selectedItems);
  };

  showDrawer = (e, stat) => {
    console.log(e);
    // setOpen(true);
    var obj_arr = {
      req_action: stat === true ? 2 : 3,
      devices_approved: e.devices_requested,
      admin_comments: "",
    };
    confirm({
      icon: <Icon type="exclamation-circle" />,
      title: "Are you sure ?",
      content: " To do this task?",
      onOk: () => {
        this.props
          .getrmarequestaccept(e.id, obj_arr)
          .then((res) => {
            console.log(res);
            this.getdesignation({
              results: 10,
              page: 1,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // ======================================================
  render() {
    const columns = [
      {
        title: "Office Number",
        dataIndex: "office_number",
        key: "office_number",
      },
      {
        title: "Manager",
        dataIndex: "manager_name",
        key: "manager_name",
      },
      {
        title: "Devices Requested",
        dataIndex: "devices_requested",
        key: "devices_requested",
      },
      {
        title: "Delivery Method",
        dataIndex: "method_of_delivery_text",
        key: "method_of_delivery_text",
      },
      {
        title: "Comments",
        dataIndex: "comments",
        key: "comments",
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        render: (text, record) => (
          <span>
            <Tag color={record.status === 1 ? "orange" : "green"}>
              {record.status_text}
            </Tag>
          </span>
        ),
      },
      {
        title: "Created At",
        dataIndex: "created_at",
        key: "created_at",
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <div>
            <div>
              {record.status === 1 && (
                <span>
                  <Button
                    onClick={() => this.showDrawer(record, true)}
                    type="primary"
                  >
                    Accept
                  </Button>
                  <Divider type="vertical" />
                  <Button
                    onClick={() => this.showDrawer(record, false)}
                    type="danger"
                  >
                    Decline
                  </Button>
                </span>
              )}
            </div>
          </div>
        ),
      },
    ];
    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            <div className="col-sm-4">
              <h4>Order Requests</h4>
            </div>
          </div>
          <div className="bg-white padd0">
            <Table
              columns={columns}
              dataSource={this.state.data}
              scroll={{ x: true }}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              // size="small"
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    ibomarket: state.data,
    data: state.data,
  };
}
export default connect(mapStateToProps, {
  getinventoryorder,
  getrmarequestaccept,
})(InventoryOrder);
