import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Select, Button, Input, Form, message, Spin, Icon } from "antd";
import _ from "lodash";
import {
  getretailers,
  getdistributers,
  getmaster,
  updateibomarket,
} from "../../actions/assetAction";
import { ibo_supervisor, ibo_manager } from "../../actions/userListAction";

const { Option } = Select;
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

class EditMarkter extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      loading: false,
      spinloader: true,
      distributor: [],
      master: [],
      retailer: [],
      market: [],
      ibo_manager: [],
      ibo_supervisor: [],
      super_user: [],
      marketrole: false,
    };
  }

  componentWillMount() {
    const marketer = this.props.user.role_id.filter((word) => word === 5);
    if (marketer.length !== 0) {
      this.setState({
        marketrole: true,
      });
    }
    console.log(this.props.location.market);
    if (this.props.location.market === undefined) {
      this.props.history.push("/marketer");
    } else {
      this.setState({
        market: this.props.location.market,
      });
      this.props
        .ibo_manager(this.props.location.market.retailer_id)
        .then((res) => {
          console.log(res);
          this.setState({
            ibo_manager: res.data,
          });
        });
      // this.props
      //   .ibo_supervisor(this.props.location.market.retailer_id)
      //   .then((res) => {
      //     console.log(res);
      //     this.setState({
      //       ibo_supervisor: res.data,
      //     });
      //   });

      this.props.getretailers().then((res) => {
        this.props.getdistributers().then((res) => {
          this.props.getmaster().then((res) => {
            this.setState({
              distributor: this.props.data.distributor,
              master: this.props.data.master,
              retailer: this.props.data.retailer,
              spinloader: false,
            });
          });
        });
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        });

        this.props
          .updateibomarket(values, this.state.market)
          .then((res) => {
            message.success("IBO/Market updated successfully");
            this.props.form.resetFields();
            this.setState({
              loading: false,
            });
            this.props.history.push("/marketer");
          })
          .catch((err) => {
            message.error(err);
            this.setState({
              loading: false,
            });
          });
      }
    });
  };

  renderMaster() {
    return _.map(this.state.master, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderDistributor() {
    return _.map(this.state.distributor, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderRetailer() {
    return _.map(this.state.retailer, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  rendermarket_manager() {
    return _.map(this.state.ibo_manager, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  rendermarket_supervisor() {
    return _.map(this.state.ibo_supervisor, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  render() {
    // eslint-disable-next-line

    const { getFieldDecorator } = this.props.form;
    const { loading, market, spinloader } = this.state;

    return (
      <div className="container-fluid">
        <div class="row justify-content-between padd20 bg-grey">
          <div class="col-4">
            <h4 className="mt-1">Edit IBO/Market</h4>
          </div>
          <div class="col-4">
            <Link to="/marketer" className="float-right">
              <Button type="default">Back</Button>
            </Link>
          </div>
        </div>

        <Spin indicator={antIcon} spinning={spinloader}>
          <div className=" bg-white padd15">
            <Form onSubmit={this.handleSubmit} className="addbookform row">
              {this.state.marketrole === false ? (
                <Form.Item
                  label="Master"
                  className="col-md-4 col-sm-6 col-xs-12"
                >
                  {getFieldDecorator("Master", {
                    initialValue: market.master_agent_id,
                    rules: [
                      { required: true, message: "Please Select Master" },
                    ],
                  })(
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select Master"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.renderMaster()}
                    </Select>
                  )}
                </Form.Item>
              ) : null}
              {this.state.marketrole === false ? (
                <Form.Item
                  label="Distributor"
                  className="col-md-4 col-sm-6 col-xs-12"
                >
                  {getFieldDecorator("DistributorName", {
                    initialValue: market.distributor_id,
                    rules: [
                      {
                        required: true,
                        message: "Please input Distributor ",
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select Distributor"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      // onChange={(z) => this.setState({ hubs: _.filter(this.state.allhubs, ["Zone", z]) })}
                    >
                      {this.renderDistributor()}
                    </Select>
                  )}
                </Form.Item>
              ) : null}
              {this.state.marketrole === false ? (
                <Form.Item
                  label="Retailer"
                  className="col-md-4 col-sm-6 col-xs-12"
                >
                  {getFieldDecorator("RetailerName", {
                    initialValue: market.retailer_id,
                    rules: [
                      {
                        required: true,
                        message: "Please input Retailer",
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select Retailer"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      // onChange={(z) => this.setState({ hubs: _.filter(this.state.allhubs, ["Zone", z]) })}
                    >
                      {this.renderRetailer()}
                    </Select>
                  )}
                </Form.Item>
              ) : null}
              <Form.Item
                label="IBO / Marketer"
                className="col-md-4 col-sm-6 col-xs-12"
              >
                {getFieldDecorator("iboname", {
                  initialValue: market.name,
                  rules: [
                    {
                      required: true,
                      message: "Please input IBO / Marketer",
                    },
                  ],
                })(<Input placeholder="Enter IBO / Marketer" />)}
              </Form.Item>
              <Form.Item
                label="IBO Manager"
                className="col-md-4 col-sm-6 col-xs-12"
              >
                {getFieldDecorator("manager_id", {
                  // initialValue:
                  //   market.get_manager_info.length !== 0
                  //     ? market.get_manager_info[0].user_id
                  //     : "",
                  initialValue: market.get_manager_info.map(
                    (market) => market.user_id
                  ),
                  rules: [
                    { required: false, message: "Please Select IBO Manager" },
                  ],
                })(
                  <Select
                    showSearch
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder="Select IBO Manager"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }

                    // onChange={(z) => this.setState({ hubs: _.filter(this.state.allhubs, ["Zone", z]) })}
                  >
                    {this.rendermarket_manager()}
                  </Select>
                )}
              </Form.Item>
              {/* <Form.Item
                label="IBO Add on Manager"
                className="col-md-4 col-sm-6 col-xs-12"
              >
                {getFieldDecorator("supervisor_id", {
                  initialValue: market.get_supervisor_info.map(
                    (market) => market.user_id
                  ),
                  rules: [
                    {
                      required: false,
                      message: "Please Select IBO Add on Manager",
                    },
                  ],
                })(
                  <Select
                    showSearch
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder="Select IBO Add on Manager"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }

                    // onChange={(z) => this.setState({ hubs: _.filter(this.state.allhubs, ["Zone", z]) })}
                  >
                    {this.rendermarket_supervisor()}
                  </Select>
                )}
              </Form.Item> */}

              <br />
              <br />
              <div className="col-md-12 col-sm-12 col-xs-12 padd15">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={loading}
                >
                  Update IBO/Market
                </Button>
              </div>
            </Form>
          </div>
        </Spin>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user, data: state.data };
}
const WrappedAddEmployee = Form.create({ name: "AddEmployee" })(EditMarkter);
export default connect(mapStateToProps, {
  getretailers,
  getdistributers,
  getmaster,
  updateibomarket,
  ibo_supervisor,
  ibo_manager,
})(WrappedAddEmployee);
