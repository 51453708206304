import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { emailLogin } from "../../actions/userAction";
import {} from "../../actions/assetAction";
import "../../styles/css/App.css";
import Logo from "../../styles/img/wies-logo.svg";
import Loginimg from "../../styles/img/delivery.svg";
import { Button, message, Alert, Input } from "antd";

class Login extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      email: "",
      password: "",
      loading: false,
      AILockAttempt: 0,
      UserLockAttempt: 0,
      LockTime: 0,
      isLocked: false,
    };
    // bind
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.user !== null) {
      this.props.history.push("/dashboard");
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.user !== null) {
      nextProps.history.push("/dashboard");
    }
  }
  // handle change
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  // handle submit
  handleSubmit(e) {
    this.setState({
      iconLoading: true,
    });
    e.preventDefault();
    this.props
      .emailLogin(this.state.email, this.state.password)
      .then((res) => {
        console.log(`logged in: `, res);

        message.success("Logged in!");
        this.props.history.push("/dashboard");
        // this.navigate(res);
      })
      .catch((err) => {
        console.log(err);
        message.error(err);
        this.setState({
          iconLoading: false,
        });
      });
  }

  render() {
    const { AILockAttempt, UserLockAttempt, isLocked } = this.state;
    return (
      <div className="cover-full">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7 hidden-sm hidden-xs padd0">
              <div className="img-flex bg-red">
                <img src={Loginimg} className="loginimg" alt="WiESSystem" />
              </div>
            </div>
            <div className="col-md-5 col-sm-12 col-xs-12 bg-white">
              <div className="panel-flex">
                <div className="panel panel-default loginpanel">
                  <div className="panel-body">
                    <img
                      src={Logo}
                      className="logologin mb30"
                      alt="WiESSystem"
                    />
                    <h4 className="text-left">Login</h4>

                    {isLocked ? (
                      <div className="paddtb15">
                        <Alert
                          message="Your account has been locked!"
                          description={
                            "You have exceeded your login attempt. Please contact admin to gain access."
                          }
                          type="warning"
                        />
                      </div>
                    ) : (
                      <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                          <Input
                            type="text"
                            name="email"
                            onChange={this.handleChange}
                            value={this.state.email}
                            id="email"
                            placeholder="Email"
                            required
                          />
                        </div>
                        <div className="form-group">
                          <Input.Password
                            type="password"
                            name="password"
                            onChange={this.handleChange}
                            value={this.state.password}
                            id="pwd"
                            placeholder="Password"
                            required
                          />
                        </div>
                        {/* <div className="form-group">
                          <Select placeholder="Select Role" required>
                            <Option value="jack">Admin</Option>
                            <Option value="lucy">Master Agent</Option>
                            <Option value="Yiminghe">Distributor</Option>
                            <Option value="jack">Retailer</Option>
                            <Option value="lucy">IBO Marketer</Option>
                            <Option value="Yiminghe">Office Manager</Option>
                          </Select>
                        </div> */}
                        <Button
                          htmlType="submit"
                          type="primary"
                          loading={this.state.iconLoading}
                          block
                          className="mb20"
                        >
                          Login
                        </Button>
                        <Link to="/forgotpassword">
                          <p>Forgot Password?</p>
                        </Link>
                        {UserLockAttempt >= 1 ? (
                          <div>
                            <p>Lock Attempt - {UserLockAttempt}</p>
                            <p>
                              Remaining Lock Attempt -{" "}
                              {AILockAttempt - UserLockAttempt}
                            </p>
                          </div>
                        ) : null}
                        {/* <div className="paddtb15">
                          <Alert
                            message="Note:"
                            description={
                              "Your Session will be automatically logged out after " +
                              LockTime +
                              " mins. You need to login again to continue!"
                            }
                            type="info"
                          />
                        </div> */}
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user, userinfo: state.userinfo };
}

export default connect(mapStateToProps, { emailLogin })(Login);
