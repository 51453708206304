import { USER_STATUS } from '../actionTypes';

const loadingReducer = function (state = {}, action) {
    switch (action.type) {

        case USER_STATUS:
            return { ...state, user: action.payload };

        default:
            return state;
    }
}

export default loadingReducer