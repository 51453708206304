import React, { Component } from "react";
import { connect } from "react-redux";
import {
  purchaseorderview,
  inventory_office_search,
  exportallocationreportlist,
} from "../../actions/inventoryAction";
import _ from "lodash";
import { Button, Table, Tag, Input, Select, Empty, Spin, message } from "antd";
import axiosInstance from "../../actions/AxiosInstance";
import debounce from "lodash/debounce";

const { Option } = Select;

class PurchaseOrder extends Component {
  constructor(props) {
    super(props);
    this.fetchUser = debounce(this.fetchUser, 800);
    this.state = {
      marketer: [],
      data: [],
      pagination: {},
      loading: true,
      loadingbtn:false,
      loadingEPbtn:false,
      retailer: [],
      selectedItems: [],
      fdata: [],
      fetching: false,
    };
  }

  componentWillMount() {
    this.getdesignation({
      results: 10,
      page: 1,
    });
  }

  getdesignation(params = {}) {
    this.setState({
      loading: true,
    });
    this.props
      .purchaseorderview(params)
      .then((res) => {
        console.log(res);
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.items.total;
        this.setState({
          data: res.data.items.data,
          loading: false,
          pagination,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }
  handleTableChange = (pagination, filter, sorter) => {
    const pager = { ...this.state.pagination };
    console.log(sorter.order);

    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      sortField: sorter.order === undefined ? "" : sorter.field,
      sortOrder:
        sorter.order === "ascend"
          ? "ASC"
          : sorter.order === undefined
          ? ""
          : "DESC",
    });
    setTimeout(() => {
      this.searchMarketer();
    }, 100);
  };

  fetchUser = (value) => {
    console.log("fetching user", value);
    if (value.length > 1) {
      this.setState({ fdata: [], fetching: true });
      console.log(this);
      axiosInstance
        .get("/api/v1/reference/offices?term=" + value)
        .then((response) => {
          console.log(response.data);
          const fdata = response.data.map((user) => ({
            text: user.name,
            value: user.id,
          }));
          console.log(this);
          this.setState({ fdata, fetching: false });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  onchangeRole = (selectedItems) => {
    this.setState({ selectedItems });
  };
  clearSearch = () => {
    this.setState({ loading: true });
    this.setState({
      selectedItems: [],
      selectedfieldname: [],
      selectedfilter: [],
      fdata:[],
    });
    this.getdesignation({
      results: 10,
      page: 1,
    });
  };
  searchMarketer = (reset) => {
    this.setState({ loadingbtn: true, loading: true });
    var office = this.state.selectedItems.key;
    var sort = this.state.sortField;
    var sort_by = this.state.sortOrder;

    this.props
      .inventory_office_search({
        office: office,
        page: reset ? 1 : this.state.pagination?.current ?? 1,
        sort,
        sort_by,
      })
      .then((res) => {
        console.log(res);
        this.setState({
          dashdata: res.data.grand_totals,
          loadingbtn: false,
        });
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.items.total;
        pagination.current = res.data.items.current_page;
        this.setState({
          data: res.data.items.data,
          loading: false,
          pagination,
        });
      })
      .catch((err) => {
        this.setState({ loadingbtn: false });
      });
  };
  getexportbtn = () => {
    this.setState({ loadingEPbtn: true, loading: true });
    var office = this.state.selectedItems.key;
    var sort = this.state.sortField;
    var sort_by = this.state.sortOrder;

    this.props
      .exportallocationreportlist({
        office: office,
        sort,
        sort_by,
      })
      .then((res) => {
        this.setState({ loading: false, loadingEPbtn: false });
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        this.setState({ loading: false, loadingEPbtn: false });
      });
  };
  // ======================================================

  render() {
    const columns = [
      {
        title: "Office",
        dataIndex: "office_number",
        key: "office_number",
      },
      {
        title: "No of Devices",
        dataIndex: "count",
        key: "count",
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
      },
      {
        title: "Uploaded by",
        dataIndex: "uploaded_by_name",
        key: "uploaded_by_name",
      },
    ];

    const { fetching, fdata } = this.state;
    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            <div className="col-sm-4">
              <h4>
                Allocation Report{" "}
                {this.state.pagination.total &&
                  `(${this.state.pagination.total})`}
              </h4>
            </div>
            <div className="col-sm-8 text-right">
              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingEPbtn}
                onClick={() => this.getexportbtn()}
              >
                <i className="uil uil-export" /> Export Report
              </Button>
            </div>
          </div>
          <div className="row padd20 bg-grey">
            <div className="col-4">
              <label for="exampleFormControlInput1">Select Office</label>
              <Select
                showSearch
                style={{ width: "100%" }}
                labelInValue
                placeholder="Type 2 characters to search..!"
                notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
                filterOption={false}
                onSearch={this.fetchUser}
                defaultActiveFirstOption={false}
                value={this.state.selectedItems}
                onChange={this.onchangeRole}
              >
                {fdata.map((d) => (
                  <Option key={d.value}>{d.text}</Option>
                ))}
              </Select>
            </div>

            <div className="col mt-6">
              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingbtn}
                onClick={() => this.searchMarketer("reset")}
              >
                Search
              </Button>
              <Button type="default" onClick={() => this.clearSearch()}>
                Clear
              </Button>
            </div>
          </div>
          <div className="bg-white padd0">
            <Table
              rowKey={Math.random().toString()}
              columns={columns}
              dataSource={this.state.data}
              scroll={{ x: true }}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              // size="small"
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    data: state.data,
  };
}

export default connect(mapStateToProps, {
  purchaseorderview,
  inventory_office_search,
  exportallocationreportlist,
})(PurchaseOrder);
