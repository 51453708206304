import { combineReducers } from "redux";
import userReducer from "./userReducer";
import loadingReducer from "./loadingReducer";
import inventoryReducer from "./inventoryReducer";
import rmaReducer from "./rmaReducer";
import dataReducer from "./dataReducer";

const rootReducer = combineReducers({
  user: userReducer,
  loading: loadingReducer,
  data: dataReducer,
  inventory: inventoryReducer,
  rma: rmaReducer,
});

export default rootReducer;
