import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getrmarequestaccept,
  exportrmarequestaccept,
  updatermastatus,
} from "../../actions/rmaAction";
import {
  getretailers,
  rma_order_status,
  getibomarketlistnew,
  rma_imei_upload,
  rma_imei_received,
} from "../../actions/assetAction";
import _ from "lodash";
import {
  Upload,
  Button,
  Icon,
  Table,
  Input,
  Form,
  Drawer,
  Row,
  Col,
  Select,
  Modal,
  Tooltip,
  message,
  Tag,
  Divider,
} from "antd";
const { confirm } = Modal;
const { Option } = Select;
const { Dragger } = Upload;

class RMARequestApprove extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pagesize: 10,
      imei: [],
      IMEIOpen: false,
      imeiList: [],
      isApprove: false,
      selectedRowKeys: [],
      hasSelected: false,
      fileList: [],
      marketer: [],
      data: [],
      pagination: {},
      loading: true,
      loadingbtn: false,
      loadingRDbtn: false,
      loadingARMAbtn: false,
      retailer: [],
      selectedItems: [],
      retailers: [],
      order_status: [],
      sortField: null,
      sortOrder: null,
      ibo: [],
      open: false,
      record: null,
      visible: false,
      retailerrole: false,
      marketrole: false,
      superrole: false,
      superadmin: false,
      isapprove: false,
      selectedretailer: [],
      selectedorderstatus: [],
      selectedibo: [],
      search_key: null,
      uploading: false,
      fileList1: [],
      selectedItemss: [],
      IMEIReceive: false,
      activeimei: [],
      activerma: [],
    };
  }

  componentWillMount() {
    this.getdesignation({
      results: 10,
      page: 1,
    });
    this.getIbolist();
    this.getRetailers();
    this.getOrderStatus();
    const superadmin = this.props.user.role_id.filter((word) => word === 10);
    const masteruser = this.props.user.role_id.filter((word) => word === 1);
    const Retailer = this.props.user.role_id.filter((word) => word === 5);
    const marketer = this.props.user.role_id.filter(
      (word) => word === 4 || word === 8
    );

    if (Retailer.length !== 0) {
      this.setState({
        retailerrole: true,
      });
    }
    if (marketer.length !== 0) {
      this.setState({
        marketrole: true,
      });
    }
    if (masteruser.length !== 0) {
      this.setState({
        superrole: true,
      });
    }
    if (superadmin.length !== 0) {
      this.setState({
        superadmin: true,
      });
    }
  }

  getdesignation(params = {}) {
    this.props
      .getrmarequestaccept(params)
      .then((res) => {
        console.log(res);
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.items.total;
        this.setState({
          data: res.data.items.data,
          loading: false,
          loadingbtn: false,
          pagination,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          loadingbtn: false,
        });
      });
  }
  handleTableChange = (pagination, filter, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    const order =
      sorter.order === "ascend"
        ? "ASC"
        : sorter.order === undefined
        ? ""
        : "DESC";
    const fieldName = sorter.order === undefined ? "" : sorter.field;
    this.setState({
      pagination: pager,
      sortField: fieldName,
      sortOrder: order,
    });
    this.searchOrders(fieldName, order, pager);
  };

  fetch = (params = {}) => {
    console.log("params:", params);
    this.getdesignation(params);
  };

  searchOrders = (sortName, order, pager, reset) => {
    this.setState({ loading: true, loadingbtn: true });
    var retailer_id = this.state.selectedretailer;
    var orderstatus = this.state.selectedorderstatus;
    var ibo_marketer_id = this.state.selectedibo;
    var search_text = this.state.search_key;
    var sort = sortName ?? this.state.sortField;
    var sort_by = order ?? this.state.sortOrder;
    console.log(
      `retailer_id: ${retailer_id} orderstatus: ${orderstatus} ibo_marketer_id: ${ibo_marketer_id}`
    );
    var page = reset
      ? 1
      : pager !== ""
      ? pager?.current
      : this.state.pagination?.current ?? 1;
    this.fetch({
      results: 10,
      page: page,
      ibo_marketer_id: ibo_marketer_id,
      retailer_id: retailer_id,
      status: orderstatus,
      search_text: search_text,
      sort: sort,
      sort_by: sort_by,
    });
  };

  onchangeretailerfilter = (selectedretailer) => {
    this.setState({ selectedretailer });
  };

  onchangeorderfilter = (selectedorderstatus) => {
    this.setState({ selectedorderstatus });
  };

  onchangeibo = (selectedibo) => {
    this.setState({ selectedibo });
  };

  clearSearch = () => {
    this.setState({ loading: true });
    this.setState({
      selectedretailer: [],
      selectedorderstatus: [],
      selectedibo: [],
      search_key: null,
      sortField: null,
      sortOrder: null,
    });
    this.fetch({
      results: 10,
      page: 1,
    });
  };

  onRowsSelectionHandler = (ids, rma_ids) => {
    console.log(ids);
    this.setState({
      imei: ids,
      imeiList: rma_ids,
      selectedRowKeys: ids,
      hasSelected: ids.length > 0,
    });
  };

  showDrawer = ({ isApprove, imei }) => {
    this.setState({
      IMEIOpen: true,
      isApprove: isApprove,
      imei: imei,
    });
  };

  onClose = () => {
    confirm({
      icon: <Icon type="exclamation-circle" />,
      title: "Are you sure you want to cancel the RMA?",
      // content: " To cancel RMA approval/rejection?",
      onOk: () => {
        this.setState({
          IMEIOpen: false,
          imeiList: [],
          imei: [],
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  resetform = () => {
    this.props.form.resetFields();
    this.props.form.setFieldsValue({
      file: null,
    });
    this.setState({ fileList: [], loading: false });
  };

  handleSubmit = (e) => {
    console.log("Success:", e);
    e.preventDefault();
    try {
      this.props.form.validateFieldsAndScroll(
        ["id", "comments", "file"],
        (err, values) => {
          if (!err) {
            console.log("Success:", values);
            Modal.confirm({
              title: "Are you sure you want submit the RMA?",
              // content: "Do you want to reject RMA",
              okText: "Yes",
              cancelText: "No",
              onOk: () => {
                console.log(`Ok`);
                this.submitRMA({
                  isApprove: this.state.isApprove,
                  imei: this.state.imei,
                  comments: values?.comments ?? "",
                });
              },
            });
          } else {
            console.log(`error in setting order`);
          }
        }
      );
    } catch (ex) {
      console.log(`error: `, ex);
    }
  };

  handleReject = (e, imei) => {
    e.preventDefault();
    try {
      Modal.confirm({
        title: "Are you sure you want to reject the RMA?",
        // content: "Do you want to reject RMA",
        okText: "Yes",
        cancelText: "No",
        onOk: () => {
          console.log(`Ok`);
          this.showDrawer({ isApprove: false, imei: imei });
        },
      });
    } catch (ex) {
      console.log(`error: `, ex);
    }
  };

  submitRMA = ({ isApprove, imei, comments }) => {
    const formData = new FormData();
    this.setState({ loadingARMAbtn: true, loading: true });
    formData.append("rma_order_id", imei.id);
    formData.append("status", isApprove ? 2 : 3);
    formData.append("comments", comments);
    if (isApprove) {
      formData.append(
        "shipping_label",
        this.state.fileList !== undefined &&
          this.state.fileList !== null &&
          this.state.fileList.length > 0
          ? this.state.fileList[0]
          : null
      );
    }
    console.log(`formData`, formData);
    this.props
      .updatermastatus(formData)
      .then((res) => {
        console.log(`response of RMA approve`, res);
        this.setState({
          IMEIOpen: false,
          loadingARMAbtn: false,
          loading: false,
          // imeiList: [],
          imei: null,
        });
        this.props.form.resetFields();
        this.props.form.setFieldsValue({
          file: null,
        });
        this.setState({ fileList: [] });
        this.getdesignation({
          results: 10,
          page: 1,
        });
        message.success(
          isApprove ? "RMA approved successfully" : "RMA rejected successfully"
        );
      })
      .catch((err) => {
        this.setState({
          IMEIOpen: false,
          loadingARMAbtn: false,
          loading: false,
          // imeiList: [],
          imei: null,
        });
      });
  };

  getRetailers() {
    this.props.getretailers().then((res) => {
      console.log(res.data);
      this.setState({
        retailers: res.data,
      });
    });
  }

  getOrderStatus() {
    this.props.rma_order_status().then((res) => {
      console.log(res.data);
      this.setState({
        order_status: res.data,
      });
    });
  }

  getIbolist() {
    if (this.state.ibo === undefined || this.state.ibo.length <= 0) {
      // nprogress.start();
      const Retailer = this.props.user.role_id.filter((word) => word === 5);
      this.props
        .getibomarketlistnew(
          Retailer.length !== 0 ? this.props.user.user_id : null
        )
        .then((res) => {
          console.log(res);
          this.setState({
            ibo: res.data,
          });
          // nprogress.done();
        })
        .catch((err) => {
          this.setState({
            ibo: [],
          });
          // nprogress.done();
        });
    }
  }

  onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    if (
      newSelectedRowKeys.length === this.state.data.length &&
      this.state.hasSelected
    ) {
      this.setState({
        selectedRowKeys: [],
        hasSelected: false,
      });
    } else {
      const tempdata = this.state.data;
      var selRow = [];
      var rma_ids = [];

      newSelectedRowKeys.map((selectedRow) => {
        var index = tempdata.findIndex((res) => res.id === selectedRow);
        if (index >= 0) {
          console.log("tempdata", tempdata[index]);
          selRow.push(tempdata[index].id);
          rma_ids.push(tempdata[index]);
        }
        return selRow;
      });
      console.log(selRow, rma_ids);
      this.onRowsSelectionHandler(selRow, rma_ids);
    }
  };

  renderibo() {
    // console.log(`this.state.ibo`, this.state.ibo)
    return _.map(this.state.ibo, (dep, index) => {
      return (
        <Option value={dep.id} key={index}>
          {dep.name}
        </Option>
      );
      // });
    });
  }

  renderretailers() {
    return _.map(this.state.retailers, (master, index) => {
      //console.log(master.id);
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }

  renderorderstatus() {
    return _.map(this.state.order_status, (master, index) => {
      //console.log(master.id);
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }

  insertAt(array, index, ...elementsArray) {
    array.splice(index, 0, ...elementsArray);
  }

  handleSubmit1 = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(
      ["received_imeis_file"],
      (err, values) => {
        if (!err) {
          const { fileList1 } = this.state;
          this.setState({ uploading: true });
          const formData = new FormData();
          fileList1.forEach((file) => {
            formData.append("received_imeis_file", file);
          });
          console.log(formData);
          this.props
            .rma_imei_upload(formData)
            .then((res) => {
              this.props.form.resetFields();
              console.log(res);
              message.success(res.data.message);
              this.setState({
                uploading: false,
                fileList1: [],
              });
              this.getdesignation({
                results: 10,
                page: 1,
              });
            })
            .catch((err) => {
              message.error(err);
              this.setState({
                uploading: false,
              });
            });
        }
      }
    );
  };

  handleReset = () => {
    this.props.form.resetFields();
    this.props.form.setFieldsValue({
      received_imeis_file: null,
    });
    this.setState({ fileList1: [] });
  };

  handleChange = (selectedItemss) => {
    this.setState({ selectedItemss });
  };
  handlereceivedevice = (params) => {
    console.log(params);
    this.setState({
      activerma: params,
      activeimei: params.imei_numbers,
      IMEIReceive: true,
    });
  };
  handleSubmitIMEIs = (e) => {
    console.log("Success:", e);
    e.preventDefault();

    this.props.form.validateFieldsAndScroll(
      ["idd", "imei_numbers"],
      (err, values) => {
        if (!err) {
          console.log("Success:", values);
          this.setState({ loadingRDbtn: true });
          var obj_arr = {
            rma_order_id: values.idd,
            imei_list: values.imei_numbers,
          };
          this.props
            .rma_imei_received(obj_arr)
            .then((res) => {
              this.setState({
                activerma: [],
                activeimei: [],
                IMEIReceive: false,
              });
              this.setState({ loadingRDbtn: false });
              message.success(res.data.message);
              this.getdesignation({
                results: 10,
                page: 1,
              });
            })
            .catch((err) => {
              console.log(err);
              this.setState({ loadingRDbtn: true });
              message.error(err);
            });
        } else {
          console.log(`error in setting order`);
        }
      }
    );
  };
  onClose1 = () => {
    this.setState({
      activerma: [],
      activeimei: [],
      IMEIReceive: false,
    });
    this.props.form.resetFields(["idd", "imei_numbers"]);
  };

  getexportbtn = () => {
    var retailer_id = this.state.selectedretailer;
    var orderstatus = this.state.selectedorderstatus;
    var ibo_marketer_id = this.state.selectedibo;
    var search_text = this.state.search_key;
    var sort = this.state.sortField;
    var sort_by = this.state.sortOrder;
    this.setState({ loading: true, loadingEPbtn: true });
    this.props
      .exportrmarequestaccept({
        ibo_marketer_id: ibo_marketer_id,
        retailer_id: retailer_id,
        status: orderstatus,
        search_text: search_text,
        sort: sort,
        sort_by: sort_by,
      })
      .then((res) => {
        this.setState({ loading: false, loadingEPbtn: false });
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        this.setState({ loading: false, loadingEPbtn: false });
      });
  };

  // ======================================================

  render() {
    const {
      imei,
      activerma,
      isApprove,
      imeiList,
      fileList,
      fileList1,
      IMEIOpen,
      selectedRowKeys,
      record,
      visible,
      superrole,
      superadmin,
      retailerrole,
      marketrole,
      isapprove,
      IMEIReceive,
      activeimei,
    } = this.state;
    const { selectedItemss } = this.state;
    const filteredOptions = activeimei.filter(
      (o) => !selectedItemss.includes(o)
    );
    const { getFieldDecorator } = this.props.form;

    const propsthumb = {
      multiple: false,
      listType: "picture-card",
      accept: ".pdf",
      showUploadList: { showPreviewIcon: false, showRemoveIcon: true },
      onRemove: (file) => {
        this.setState((state) => {
          return {
            fileList: [],
          };
        });
        // console.log(this.state.fileList);
      },
      beforeUpload: (file) => {
        console.log(file);
        this.setState((state) => ({
          fileList: [file],
        }));
        return false;
      },
      fileList,
    };
    const propsthumb1 = {
      multiple: false,
      accept: ".xlsx",
      showUploadList: true,
      fileList: fileList1,
      onRemove: (file) => {
        this.setState((state) => {
          return {
            fileList1: [],
          };
        });
        // console.log(this.state.fileList);
      },
      beforeUpload: (file) => {
        console.log(file);
        this.setState((state) => ({
          fileList1: [file],
        }));
        return false;
      },
      fileList1,
    };

    var imglength = fileList.length;

    var columns = [
      {
        title: "RMA Id",
        dataIndex: "id",
        key: "id",
        align: "center",
        headerAlign: "center",
        headerClassName: "bold-text",
        width: 200,
        sorter: true,
      },
      {
        key: "submitted_user_name",
        dataIndex: "submitted_user_name",
        title: "Submitted By",
        headerClassName: "bold-text",
        width: 200,
        headerAlign: "center",
        align: "center",
        sorter: true,
      },
      {
        key: "no_of_boxes",
        dataIndex: "no_of_boxes",
        title: "No. of boxes",
        // headerClassName: "bold-text",
        // width: 200,
        headerAlign: "center",
        align: "center",
        sorter: true,
      },
      {
        title: "No. of IMEIs",
        dataIndex: "rma_device_count",
        key: "rma_device_count",
        align: "center",
        headerAlign: "center",
        headerClassName: "bold-text",
        width: 200,
        sorter: true,
      },
      {
        title: "Approved at",
        dataIndex: "approved_at",
        key: "approved_at",
        align: "center",
        headerAlign: "center",
        headerClassName: "bold-text",
        width: 200,
        sorter: true,
      },
      {
        key: "comments",
        dataIndex: "comments",
        title: "Comments",
        headerAlign: "center",
        align: "center",
        sorter: true,
      },
      {
        key: "rma_shipping_label_url",
        title: "Shipping label",
        headerAlign: "center",
        align: "center",
        render: (text, record) => {
          // console.log(`rma_shipping_label_url`, record.rma_shipping_label_url)
          return record?.rma_shipping_label_url !== undefined &&
            record?.rma_shipping_label_url !== null &&
            record?.rma_shipping_label_url.length > 0 ? (
            <div>
              <div>
                <span>
                  <Tooltip title="Download RMA shipping label">
                    <Button
                      type="primary"
                      shape="circle"
                      icon="download" // disabled={record.status >= 2}
                      onClick={(e) => {
                        window.open(
                          record?.rma_shipping_label_url ?? "",
                          "_blank"
                        );
                      }}
                    />
                  </Tooltip>
                </span>
              </div>
            </div>
          ) : null;
        },
      },
      {
        key: "rma_form_url",
        dataIndex: "rma_form_url",
        title: "RMA Form",
        headerAlign: "center",
        align: "center",
        render: (text, record) => {
          return record?.rma_form_url !== undefined &&
            record?.rma_form_url !== null &&
            record?.rma_form_url.length > 0 ? (
            <div>
              <div>
                <span>
                  <Tooltip title="Download RMA Form">
                    <Button
                      type="primary"
                      shape="circle"
                      icon="download" // disabled={record.status >= 2}
                      onClick={(e) => {
                        window.open(record?.rma_form_url ?? "", "_blank");
                      }}
                    />
                  </Tooltip>
                </span>
              </div>
            </div>
          ) : null;
        },
      },
      {
        key: "order_status_text",
        dataIndex: "order_status_text",
        title: "RMA Status",
        headerAlign: "center",
        align: "center",
        render: (text, record) => {
          return (
            <span>
              <Tag
                color={
                  record.order_status_text === undefined ||
                  record.order_status_text === null
                    ? "red"
                    : record.order_status_text?.toLowerCase() === "completed" ||
                      record.order_status_text?.toLowerCase() === "approved"
                    ? "green"
                    : record.order_status_text?.toLowerCase() === "submitted"
                    ? "lightgreen"
                    : "orange"
                }
              >
                {record.order_status_text === undefined ||
                record.order_status_text === null
                  ? "Not-Started"
                  : record.order_status_text}
              </Tag>
            </span>
          );
        },
      },
      {
        title: "Created at",
        dataIndex: "created_at",
        key: "created_at",
        align: "center",
        headerAlign: "center",
        headerClassName: "bold-text",
        width: 200,
        sorter: true,
      },
    ];
    if (retailerrole) {
      this.insertAt(columns, 1, {
        title: "IBOs / Markets",
        dataIndex: "ibo_market_name",
        key: "ibo_market_name",
        align: "center",
        headerAlign: "center",
        headerClassName: "bold-text",
        width: 200,
        sorter: true,
      });
    }
    if (superrole || superadmin) {
      this.insertAt(
        columns,
        1,
        {
          title: "Retailer Name",
          dataIndex: "retailer_name",
          key: "retailer_name",
          align: "center",
          headerAlign: "center",
          headerClassName: "bold-text",
          width: 200,
          sorter: true,
        },
        {
          title: "IBOs / Markets",
          dataIndex: "ibo_market_name",
          key: "ibo_market_name",
          align: "center",
          headerAlign: "center",
          headerClassName: "bold-text",
          width: 200,
          sorter: true,
        }
      );
      columns.push({
        title: "Action",
        key: "action",
        width: 150,
        fixed: "right",
        render: (text, record) => (
          <div>
            <div>
              <span>
                <Tooltip title="Accept RMA">
                  <Button
                    type="primary"
                    shape="circle"
                    icon="shop"
                    disabled={record.status >= 2}
                    onClick={(e) => {
                      if (record.status <= 2) {
                        this.showDrawer({ imei: record, isApprove: true });
                        console.log(record.id);
                      }
                    }}
                  />
                </Tooltip>
                <Divider type="vertical" />
                <Tooltip title="Reject RMA">
                  <Button
                    type="primary"
                    shape="circle"
                    icon="close"
                    disabled={record.status >= 2}
                    onClick={(e) => {
                      if (record.status <= 2) {
                        this.handleReject(e, record);
                        console.log(record.id);
                      }
                    }}
                  />
                </Tooltip>
                <Divider type="vertical" />
                <Tooltip title="Received Device">
                  <Button
                    type="primary"
                    shape="circle"
                    icon="mobile"
                    disabled={record.status !== 4}
                    onClick={(e) => this.handlereceivedevice(record)}
                  />
                </Tooltip>
              </span>
            </div>
          </div>
        ),
      });
    }

    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            <div className="col-sm-4">
              <h4>
                RMA Status{" "}
                {this.state.pagination.total &&
                  `(${this.state.pagination.total})`}
              </h4>
            </div>
            <div className="col-sm-8 text-right">
              <Button
                type="default"
                className="mr5"
                onClick={() => this.clearSearch()}
              >
                <i className="uil uil-sync" /> Refresh
              </Button>

              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingEPbtn}
                onClick={() => this.getexportbtn()}
              >
                <i className="uil uil-export" /> Export Report
              </Button>
            </div>
          </div>
          {superrole && (
            <>
              <div className="text-right">
                <a
                  href="https://wieserp.s3.amazonaws.com/Sample+Template/Received+Imeis+Sample+file.xlsx"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    icon="file-excel"
                    type="default"
                    className="samplefilebtn"
                  >
                    Sample Template
                  </Button>
                </a>
              </div>
              <Form name="uploadrma" onSubmit={this.handleSubmit1}>
                <Form.Item
                  name="received_imeis_file"
                  label="Upload RMA Devices"
                >
                  {getFieldDecorator("received_imeis_file", {
                    rules: [
                      {
                        required: true,
                        message: "Please select file",
                      },
                    ],
                  })(
                    <Dragger {...propsthumb1}>
                      <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                    </Dragger>
                  )}
                </Form.Item>

                <Form.Item>
                  <Button
                    className="mt-3"
                    type="primary"
                    htmlType="submit"
                    loading={this.state.uploading}
                  >
                    Upload
                  </Button>
                  <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
                    Clear
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}

          <div className="row padd20 bg-grey">
            {retailerrole || marketrole ? null : (
              <>
                <div className="col">
                  <label for="exampleFormControlInput1">Select Retailers</label>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select option"
                    value={this.state.selectedretailer}
                    onChange={this.onchangeretailerfilter}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.renderretailers()}
                  </Select>
                </div>
              </>
            )}
            {marketrole ? null : (
              <div className="col">
                <label for="exampleFormControlInput1">
                  Select IBOs / Markets
                </label>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select Option"
                  value={this.state.selectedibo}
                  onChange={this.onchangeibo}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="0">Any</Option>
                  {this.renderibo()}
                </Select>
              </div>
            )}
            <div className="col">
              <label for="exampleFormControlInput1">Select RMA Status</label>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select Option"
                value={this.state.selectedorderstatus}
                onChange={this.onchangeorderfilter}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.renderorderstatus()}
              </Select>
            </div>

            <div className="col mt-6">
              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingbtn}
                onClick={() => this.searchOrders("", null, null, "reset")}
              >
                Search
              </Button>
              <Button type="default" onClick={() => this.clearSearch()}>
                Clear
              </Button>
            </div>
          </div>
          <div className="bg-white padd0">
            <Table
              columns={columns}
              dataSource={this.state.data}
              scroll={{ x: true }}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
            />
          </div>
        </div>
        <Drawer
          width={680}
          onClose={this.onClose}
          visible={IMEIOpen}
          bodyStyle={{
            paddingBottom: 80,
          }}
        >
          <Form
            // form={this.props.form}
            onSubmit={this.handleSubmit}
            autoComplete="off"
            name="basic"
            layout="vertical"
          >
            <>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label={`RMA Order id`}>
                    {getFieldDecorator(`id`, {
                      disabled: true,
                      initialValue: imei?.id !== "" ? imei?.id : "",
                      rules: [
                        {
                          required: true,
                          message: "Please input RMA number",
                        },
                      ],
                    })(<Input disabled={true} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Comment">
                    {" "}
                    {getFieldDecorator("comments", {
                      rules: [
                        {
                          required: false,

                          message: "Please input comment",
                        },
                      ],
                    })(<Input.TextArea rows={2} placeholder="Enter comment" />)}
                  </Form.Item>
                </Col>
              </Row>
            </>

            {isApprove ? (
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label="Upload File"
                    name="file"
                    className="col-sm-12 col-xs-12 paddlr15"
                  >
                    {getFieldDecorator("file", {
                      rules: [
                        {
                          required: true,
                          message: "Please upload shipping label!",
                        },
                      ],
                    })(
                      <Upload {...propsthumb}>
                        {0 >= imglength ? (
                          <div>
                            <p className="ant-upload-drag-icon">
                              <Icon type="inbox" />
                            </p>
                            <p className="ant-upload-text">
                              Click this area to upload
                            </p>
                            <p className="ant-upload-hint">
                              Support for a single or bulk upload. Please upload
                              data in '.pdf' file format!
                            </p>
                          </div>
                        ) : null}
                      </Upload>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            ) : null}
            <Form.Item
              className="mt-3"
              wrapperCol={{
                offset: 0,
                span: 16,
              }}
            >
              {" "}
              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.loadingARMAbtn}
              >
                Submit
              </Button>
              <Button
                type="dashed"
                style={{ marginLeft: 8 }}
                onClick={this.resetform}
              >
                Reset
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
        <Drawer
          width={680}
          onClose={this.onClose1}
          visible={IMEIReceive}
          bodyStyle={{
            paddingBottom: 80,
          }}
        >
          <Form
            // form={this.props.form}
            onSubmit={this.handleSubmitIMEIs}
            autoComplete="off"
            name="basic"
            layout="vertical"
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label={`RMA Order id`}>
                  {getFieldDecorator(`idd`, {
                    disabled: true,
                    initialValue: activerma?.id !== "" ? activerma?.id : "",
                    rules: [
                      {
                        required: true,
                        message: "Please input RMA number",
                      },
                    ],
                  })(<Input disabled={true} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="IMEI Number">
                  {getFieldDecorator("imei_numbers", {
                    rules: [
                      {
                        required: true,
                        // pattern: new RegExp(/^[0-9]+$/),
                        message: "Please select IMEIs",
                      },
                    ],
                  })(
                    <Select
                      mode="multiple"
                      placeholder="IMEIs List"
                      onChange={this.handleChange}
                      style={{ width: "100%" }}
                    >
                      {filteredOptions.map((item) => (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              wrapperCol={{
                offset: 0,
                span: 16,
              }}
            >
              {" "}
              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.loadingRDbtn}
              >
                Submit
              </Button>
              <Button type="dashed" onClick={this.onClose1}>
                Close
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    ibomarket: state.data,
    data: state.data,
    rma: state.rma,
  };
}

const WrappedRMARequestApprove = Form.create({ name: "RMARequestApprove" })(
  RMARequestApprove
);

export default connect(mapStateToProps, {
  getrmarequestaccept,
  updatermastatus,
  getretailers,
  rma_order_status,
  getibomarketlistnew,
  rma_imei_upload,
  rma_imei_received,
  exportrmarequestaccept,
})(WrappedRMARequestApprove);
