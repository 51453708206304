import axiosInstance from "./AxiosInstance";
import moment from "moment";

export function detailed_sales_report(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          params?.url
            ? params?.url
            : "/api/v1/detailed_sales_report?page=" + params.page
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function detailed_sales_report_ibo(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/sales_summary_reports/ibo_marketer?page=${
            params.page ?? 1
          }&retailer_id=${params.retailer_id ?? ""}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function detailed_sales_report_retailer(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/sales_summary_reports/retailer?page=" + params.page)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function detailed_sales_report_office(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          params?.url
            ? params?.url
            : "/api/v1/sales_summary_reports/office?page=" + params.page
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function detailed_sales_report_manager(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/sales_summary_reports/manager?page=" + params.page)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function detailed_sales_report_agent(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          params?.url
            ? params?.url
            : "/api/v1/sales_summary_reports/agent?page=" + params.page
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function sales_dashboard_data(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/sales_dashboard_activations_data")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function sales_dashboard_orders(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/sales_dashboard_orders_data")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function sales_dashboard_agents(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/v1/sales_dashboard_top_agents_data/${params}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function sales_dashboard_office(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/v1/sales_dashboard_top_offices_data/${params}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Sales Manager Search
export function detailed_sales_report_manager_search(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          params?.url
            ? params?.url
            : `/api/v1/sales_summary_reports/manager?filter=${
                params.sort ?? ""
              }&sort_by=${params.sort_by ?? ""}&sort=${
                params.sort ?? ""
              }&manager_id=${params.manager ?? ""}&page=${params.page ?? 1}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
          // dispatch({
          //   type: GET_INVENTORY_ORDER,
          //   payload: response.data,
          // });
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}

// Sales Agent Report
export function detailed_sales_report_agent_search(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          params?.url
            ? params?.url
            : `/api/v1/sales_summary_reports/agent?filter=${
                params.sort ?? ""
              }&sort_by=${params.sort_by ?? ""}&sort=${
                params.sort ?? ""
              }&agent_id=${params.agent ?? ""}&page=${params.page ?? 1}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
          // dispatch({
          //   type: GET_INVENTORY_ORDER,
          //   payload: response.data,
          // });
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}

// Sales Market Report
export function detailed_sales_report_ibo_search(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          params?.url
            ? params?.url
            : `/api/v1/sales_summary_reports/ibo_marketer?sort_by=${
                params.sort_by ?? ""
              }&sort=${params.sort ?? ""}&ibo_marketer_id=${
                params.market ?? ""
              }&page=${params.page ?? 1}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
          // dispatch({
          //   type: GET_INVENTORY_ORDER,
          //   payload: response.data,
          // });
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}

// Sales Retailer Report
export function detailed_sales_report_retailer_search({
  retailer,
  page,
  sort,
  sort_by,
}) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/sales_summary_reports/retailer?sort_by=${
            sort_by ?? ""
          }&sort=${sort ?? ""}&retailer_id=${retailer ?? ""}&page=${page ?? 1}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
          // dispatch({
          //   type: GET_INVENTORY_ORDER,
          //   payload: response.data,
          // });
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}
// Sales Office Report
export function detailed_sales_report_office_search(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          params?.url
            ? params?.url
            : `/api/v1/sales_summary_reports/office?filter=${
                params.sort ?? ""
              }&sort_by=${params.sort_by ?? ""}&sort=${
                params.sort ?? ""
              }&office_id=${params.office ?? ""}&page=${params.page ?? 1}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
          // dispatch({
          //   type: GET_INVENTORY_ORDER,
          //   payload: response.data,
          // });
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}

// Sales Summary Report
export function detailed_sales_report_search(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (params.date_range_start && params.date_range_end) {
        var StartDate = moment(params.date_range_start).format("MM-DD-YYYY");
        var EndDate = moment(params.date_range_end).format("MM-DD-YYYY");
      }
      if (params.date_range_start1 && params.date_range_end1) {
        var StartDate1 = moment(params.date_range_start1).format("MM-DD-YYYY");
        var EndDate1 = moment(params.date_range_end1).format("MM-DD-YYYY");
      }
      axiosInstance
        .get(
          params?.url
            ? params?.url
            : `/api/v1/detailed_sales_report?sort_by=${
                params.sort_by ?? ""
              }&sort=${params.sort ?? ""}&office_id=${
                params.summary ?? ""
              }&keyword=${params.search_text ?? ""}&date_range_start=${
                StartDate ?? ""
              }&date_range_end=${EndDate ?? ""}&order_date_range_start=${
                StartDate1 ?? ""
              }&order_date_range_end=${EndDate1 ?? ""}&page=${params.page ?? 1}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
}

export function inventory_dashboard() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/agent_onboarding_summary_dashboard")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get Payroll Data
export function get_payroll_data(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (params.date_range_start && params.date_range_end) {
        var StartDate = moment(params.date_range_start).format("YYYY-MM-DD");
        var EndDate = moment(params.date_range_end).format("YYYY-MM-DD");
      }
      axiosInstance
        .get(
          `/api/v1/get_payroll_data?office_id=${
            params.office_id ?? ""
          }&agent_code=${params.search_text ?? ""}&week_type=${
            params.week_type ?? ""
          }&payroll_type=${params.payroll_type ?? ""}&start_date=${
            StartDate ?? ""
          }&end_date=${EndDate ?? ""}&page=${params.page ?? 1}&term=${
            params.term ?? ""
          }`
        )
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
          reject(error.response.data.error);
        });
    });
  };
}

// Get Payroll Weeks Date
export function get_payroll_date() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("api/payroll_week_data")
        .then(function (response) {
          resolve(response.data.items);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };
}
