import React, { Component } from "react";
import { connect } from "react-redux";
import { logout } from "../actions/userAction";
import { Layout, Icon, Dropdown, Button, Tag } from "antd";
import { Link } from "react-router-dom";
import AuthVerify from "../component/AuthVerify";
import IdleAuth from "../component/idleauth";
import ProDropdown from "./prodropdown";
import _ from "lodash";
import logo from "../styles/img/wies-logo.svg";
const { Header } = Layout;

class TopHeader extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      current: "1",
      notidot: false,
      notilist: [],
      visible: false,
    };
  }
  handleMenuClick = (e) => {
    e.preventDefault();
    // this.setState({ visible: true });
  };
  handleVisibleChange = (flag) => {
    this.setState({ visible: flag });
  };
  rendernotifications() {
    var notis = _.slice(this.props.notifications, 0, 5);
    return _.map(notis, (o, index) => {
      return (
        <li key={index} className={!o.read ? "active" : null}>
          {o.Text} <span>{o.Id}</span> <br />
          <span className="time">{o.time}</span> - {o.Hub}
        </li>
      );
    });
  }

  playsound() {
    var noti = _.every(this.props.notifications, ["read", false]);
    // console.log(noti);
    setTimeout(() => {
      if (noti === true) {
        this.setState({
          notidot: true,
        });
        // var x = document.getElementById("myAudio");
        // x.play();
      } else {
        this.setState({
          notidot: false,
        });
      }
    }, 500);
  }
  logOut =()=> {
    console.log("logout")
   this.props.logout();
  }
  render() {
    // console.log(this.props.notifications);
    return (
      <Header className="text-right">
        <IdleAuth />
        <AuthVerify logOut={this.logOut} />
        <div className="pull-left">
          <img
            style={{ height: "50px", marginTop: "5px" }}
            className="img-responsive"
            src={logo}
            alt=""
          />
        </div>
        {/* <Tag color="green">Version - 3.4.2</Tag> */}
        {this.props?.user?.role_name?.map((item, i) => (
          <Tag key={i} color="#108ee9">
            {" "}
            {item}
          </Tag>
        ))}

        <ProDropdown />
      </Header>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    userinfo: state.userinfo,
    notifications: state.notifications,
  };
}

export default connect(mapStateToProps, {logout})(TopHeader);
