import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { message } from "antd";
import moment from "moment-timezone";

class AuthVerify extends Component {
  constructor(props) {
    super(props);

    props.history.listen(() => {
      const user = JSON.parse(localStorage.getItem("user"));

      if (user) {
        const decodedJwt = user.expires_in;
        const utc = moment.utc().format(); // UTC date
        console.log(moment(decodedJwt).valueOf());
        console.log(moment(utc).valueOf());

        if (moment(decodedJwt).valueOf() < moment(utc).valueOf()) {
          this.props.logOut();
          message.warning("Your session has expired. Please try login");
        }
      }
    });
  }
  componentDidMount() {
    console.log("Auth Verify====================");
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      const decodedJwt = user.expires_in;
      const utc = moment.utc().format(); // UTC date
      console.log(moment(decodedJwt).valueOf());
      console.log(moment(utc).valueOf());

      if (moment(decodedJwt).valueOf() < moment(utc).valueOf()) {
        this.props.logOut();
        //message.warning("Your session has expired. Please try login")
      }
    }
  }

  render() {
    return <div></div>;
  }
}

export default withRouter(AuthVerify);
