import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  get_send_to_dropdown,
  create_announcement,
  get_announcement,
} from "../../actions/userAction";
import _ from "lodash";
import {
  Button,
  Table,
  message,
  Tag,
  Upload,
  Icon,
  Form,
  Modal,
  Select,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import master from "./master";

const { Dragger } = Upload;
const { Option } = Select;

class Announcement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      pagination: {},
      loading: true,
      uploading: false,
      fileList: [],
      selectedRole: [],
      roleData: [],
      superrole: false,
    };
  }

  componentWillMount() {
    this.getdesignation({
      results: 10,
      page: 1,
    });
    this.props
      .get_send_to_dropdown()
      .then((res) => {
        this.setState({ roleData: res.data, loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
    const masteruser = this.props.user.role_id.filter((role) => role === 1);
    if (masteruser.length !== 0) {
      this.setState({
        superrole: true,
      });
    }
  }

  componentWillUnmount() {
    
    clearInterval(this.state.intervalId);
  }
  getdesignation(params = {}) {
    this.setState({ loading: true });
    this.props
      .get_announcement(params)
      .then((res) => {
        console.log(res.data.items);
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.items.total;
        this.setState({
          data: res.data.items.data,
          loading: false,
          pagination,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }
  handleTableChange = (pagination) => {
    clearInterval(this.state.intervalId);
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      results: pagination.pageSize,
      page: pagination.current,
    });
  };
  fetch = (params = {}) => {
    this.getdesignation(params);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(["message_text"], (err, values) => {
      if (!err) {
        this.setState({
          uploading: true,
        });

        const { fileList } = this.state;
        const formData = new FormData();

        fileList?.forEach((file, index) => {
          formData.append(`documents[${index}]`, file ?? []);
        });
        formData.append("send_to", this.state.selectedRole.key);
        formData.append("message_text", values.message_text);
        this.props
          .create_announcement(formData)
          .then((res) => {
            this.props.form.resetFields();

            console.log(res);
            message.success(res.data.message);
            this.props.form.resetFields();
            this.setState({
              uploading: false,
              fileList: [],
              selectedRole: [],
            });
            this.getdesignation({
              results: 10,
              page: 1,
            });
            const intervalId = setInterval(
              () =>
                this.getdesignation({
                  results: 10,
                  page: 1,
                }),
              5000
            );
            this.setState({ intervalId });
          })
          .catch((err) => {
            message.error("Something Went Wrong!");
            this.setState({
              uploading: false,
            });
          });
      }
    });
  };

  handleReset = () => {
    this.props.form.resetFields();
    this.setState({
      fileList: [],
      selectedRole: [],
      uploading: false,
    });
  };

  onchangeRole = (selectedRole) => {
    console.log(selectedRole);
    this.setState({ selectedRole });
  };

  // ======================================================

  render() {
    const { getFieldDecorator } = this.props.form;
    const { fileList, uploading, selectedRole, roleData, superrole } =
      this.state;
    const propsthumb = {
      accept: ".jpg,.jpeg,.png,.gif,.pdf,.doc,.docx",
      showUploadList: { showPreviewIcon: false, showRemoveIcon: true },
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
        // console.log(this.state.fileList);
      },
      beforeUpload: (file) => {
        console.log(file);
        this.setState((state) => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };

    const columns = [
      // {
      //   title: "S#No",
      //   dataIndex: "id",
      //   key: "id",
      // },
      {
        title: "Send To",
        dataIndex: "send_to_text",
        key: "send_to_text",
        hidden: superrole === true ? false : true,
      },
      {
        title: "Message",
        dataIndex: "message_text",
        key: "message_text",
      },
      {
        title: "File",
        dataIndex: "documents",
        key: "documents",
        width: 200,
        render: (record) => (
          <span>
            {record.map((item, index) => {
              return (
                <a
                  key={index}
                  href={item.aws_document_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button type="primary" shape="circle" icon="download" />
                </a>
              );
            })}
          </span>
        ),
      },

      // {
      //   title: "Created By",
      //   dataIndex: "created_by_name",
      //   key: "created_by_name",
      // },
      {
        title: "Created At",
        dataIndex: "created_at",
        key: "created_at",
      },
    ].filter((item) => !item.hidden);

    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            <div className="col-sm-4">
              <h4>
                Announcements{" "}
                {this.state.pagination.total &&
                  `(${this.state.pagination.total})`}
              </h4>
            </div>
          </div>
          {/* <div className="text-right">
            <a
              href="https://wieserp.s3.amazonaws.com/Sample+Template/Existing_Inventory_Upload_Template.xlsx"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                icon="file-excel"
                type="default"
                className="samplefilebtn"
              >
                Sample Template
              </Button>
            </a>
          </div> */}
          {/* Visible only to Admin */}
          {superrole && (
            <div>
              <div className="row mb-4">
                <div className="col-3 ml-4">
                  <label for="exampleFormControlInput1">Select Role</label>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    labelInValue
                    placeholder="Select Role"
                    filterOption={false}
                    value={selectedRole}
                    onChange={this.onchangeRole}
                  >
                    {roleData.map((d) => (
                      <Option key={d.id}>{d.name}</Option>
                    ))}
                  </Select>
                </div>
              </div>
              <Form onSubmit={this.handleSubmit} className="row">
                <div className="col-md-6">
                  <Form.Item label="Message">
                    {getFieldDecorator("message_text", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter your message here",
                        },
                      ],
                    })(
                      <TextArea
                        style={{ minHeight: 175 }}
                        placeholder="Please enter your message here..."
                      />
                    )}
                  </Form.Item>
                </div>
                <div className="col-md-6">
                  <Form.Item label="Upload Message">
                    {getFieldDecorator("file", {
                      rules: [
                        {
                          required: false,
                          message: "Please select file",
                        },
                      ],
                    })(
                      <Dragger {...propsthumb}>
                        <p className="ant-upload-drag-icon">
                          <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>
                        <p className="ant-upload-hint">
                          Support for a single or bulk upload. Strictly prohibit
                          from uploading company data or other band files
                          Acceptable format: .jpg, .jpeg, .png, .gif, .pdf,
                          .doc, .docx
                        </p>
                      </Dragger>
                    )}
                  </Form.Item>
                </div>
                <Form.Item>
                  <Button
                    className="my-3 ml-3"
                    type="primary"
                    htmlType="submit"
                    loading={uploading}
                  >
                    Send Message
                  </Button>
                  <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
                    Clear
                  </Button>
                </Form.Item>
              </Form>
            </div>
          )}

          <div className="bg-white padd0">
            <Table
              columns={columns}
              dataSource={this.state.data}
              scroll={{ x: true }}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              // size="small"
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    ibomarket: state.data,
    data: state.data,
  };
}

const WrappedHorizontalLoginForm = Form.create({ name: "horizontal_login" })(
  Announcement
);

export default connect(mapStateToProps, {
  get_send_to_dropdown,
  create_announcement,
  get_announcement,
})(WrappedHorizontalLoginForm);
