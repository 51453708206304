import moment from "moment";
import axiosInstance from "./AxiosInstance";
// Reset password link
export function office_export(search_text) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/v1/office_export?search_text=${search_text ?? ""}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function master_export(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/master_agent_export?search_text=${params.search_text ?? ""}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function retailer_export(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/retailer_export?search_text=${
            params.search_text ?? ""
          }&distributor=${params.distributor ?? ""}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function distributor_export(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/distributor_export?master_agent=${
            params.master_agent ?? ""
          }&search_text=${params.search_text ?? ""}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function ibo_market_export(search_text) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/v1/ibo_market_export?search_text=${search_text ?? ""}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function user_export(search_text, rolename, retailer) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/user_export?search_text=${search_text ?? ""}&role=${
            rolename ? rolename : ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function detailed_sales_export(
  summary,
  search_text,
  date_range_start,
  date_range_end,
  date_range_start1,
  date_range_end1
) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (date_range_start && date_range_end) {
        var StartDate = moment(date_range_start).format("MM-DD-YYYY");
        var EndDate = moment(date_range_end).format("MM-DD-YYYY");
      }
      if (date_range_start1 && date_range_end1) {
        var StartDate1 = moment(date_range_start1).format("MM-DD-YYYY");
        var EndDate1 = moment(date_range_end1).format("MM-DD-YYYY");
      }
      axiosInstance
        .get(
          `/api/v1/detailed_sales_export?summary=${summary ?? ""}&search_text=${
            search_text ?? ""
          }&date_range_start=${StartDate ?? ""}&date_range_end=${
            EndDate ?? ""
          }&order_date_range_start=${StartDate1 ?? ""}&order_date_range_end=${
            EndDate1 ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function detailed_inventory_export(val, sort, sort_by) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(val, sort, sort_by);
      if (val.date_range_start && val.date_range_end) {
        var StartDate = moment(val.date_range_start).format("MM-DD-YYYY");
        var EndDate = moment(val.date_range_end).format("MM-DD-YYYY");
      }
      var url = `/api/v1/detailed_inventory_export?keyword=${
        val.keyword ?? ""
      }&agent_id=${val?.agent?.key ?? ""}&office_id=${
        val?.office_id?.key ?? ""
      }&status=${val.status ?? ""}&date_range_start=${
        StartDate ?? ""
      }&date_range_end=${EndDate ?? ""}&sort=${sort ?? ""}&sort_by=${
        sort_by ?? ""
      }`;

      console.log(url);
      axiosInstance
        .get(url)
        .then(function (response) {
          //console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function detailed_sales_export_agent({ agent }) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/sales_summary_reports_export/agent?agent_id=${agent ?? ""}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function detailed_sales_export_manager({ manager }) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/sales_summary_reports_export/manager?manager_id=${
            manager ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function detailed_sales_export_office({ office }) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/sales_summary_reports_export/office?office_id=${
            office ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function detailed_sales_export_ibo_marketer({ market }) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/sales_summary_reports_export/ibo_marketer?ibo_marketer_id=${
            market ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function detailed_sales_export_retailer({ retailer }) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/sales_summary_reports_export/retailer?retailer_id=${
            retailer ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function aged_by_agent_export({ agent }) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/aged_inventory_summary_reports_export/agent?agent_id=${
            agent ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function aged_by_manager_export({ manager }) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/aged_inventory_summary_reports_export/manager?manager_id=${
            manager ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function aged_by_retailer_export({ retailer }) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/aged_inventory_summary_reports_export/retailer?retailer_id=${
            retailer ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function aged_by_market_export({ market }) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/aged_inventory_summary_reports_export/ibo_marketer?ibo_marketer_id=${
            market ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function aged_by_office_export({ summary }) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/aged_inventory_summary_reports_export/office?office_id=${
            summary ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function detailed_sales_summary_export() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/detailed_sales_summary_export")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function inventory_summary_retailer({ retailer }) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/inventory_summary_reports_export/retailer?retailer_id=${
            retailer ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function inventory_summary_ibo_marketer(val) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = `/api/v1/inventory_summary_reports_export/ibo_marketer?ibo_marketer_id=${
        val?.retailer ?? ""
      }`;
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function inventory_summary_office(val) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = `/api/v1/inventory_summary_reports_export/office?office_id=${
        val?.retailer ?? ""
      }`;
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function inventory_summary_manager(val) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = `/api/v1/inventory_summary_reports_export/manager?manager_id=${
        val?.retailer ?? ""
      }`;
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function inventory_summary_agent(val) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = `/api/v1/inventory_summary_reports_export/agent?agent_id=${
        val?.retailer ?? ""
      }`;
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Export Payroll Data
export function export_payroll_data(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (params.date_range_start && params.date_range_end) {
        var StartDate = moment(params.date_range_start).format("YYYY-MM-DD");
        var EndDate = moment(params.date_range_end).format("YYYY-MM-DD");
      }
      axiosInstance
        .get(
          `/api/v1/export_payroll_data?office_id=${
            params.office_id ?? ""
          }&agent_code=${params.search_text ?? ""}&week_type=${
            params.week_type ?? ""
          }&payroll_type=${params.payroll_type ?? ""}&start_date=${
            StartDate ?? ""
          }&end_date=${EndDate ?? ""}&term=${params.term ?? ""}`
        )
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
          reject(error.response.data.error);
        });
    });
  };
}
