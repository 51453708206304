import { GET_USER, USER_STATUS, API_URL } from "../actionTypes";
import axiosInstance from "./AxiosInstance";
import axios from "axios";

export function getUser() {
  return (dispatch) => {
    // show loading status before getting user to true
    dispatch({
      type: USER_STATUS,
      payload: true,
    });
    var user = localStorage.getItem("user");
    console.log(user);
    if (user !== null) {
      console.log(user);
      dispatch({ type: GET_USER, payload: JSON.parse(user) });
      // axiosInstance
      //   .get(API_URL + "/api/v1/refresh")
      //   .then(function (response) {
      //     console.log(response);
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //     localStorage.removeItem("user");
      //     user = null;
      //     dispatch({ type: GET_USER, payload: user });
      //   });
    } else {
      user = null;
      dispatch({ type: GET_USER, payload: user });
      console.log(user);
    }

    dispatch({
      type: USER_STATUS,
      payload: false,
    });
  };
}

export function emailLogin(accountno, password) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var obj_arr = {
        email: accountno,
        password: password,
        portal_type: 1,
      };
      axios
        .post(API_URL + "/api/login", obj_arr)
        .then(function (response) {
          dispatch({ type: GET_USER, payload: response.data.items });
          console.log(response);
          localStorage.setItem("user", JSON.stringify(response.data.items));
          resolve(response.data.message);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Reset password link
export function resetpasswordemail(email) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var obj_arr = {
        email: email,
      };
      axios
        .post(API_URL + "/api/forgot_password", obj_arr)
        .then(function (response) {
          console.log(response);
          resolve(response.data.message);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Reset password link
export function logout() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: USER_STATUS,
        payload: true,
      });
      axiosInstance
        .post(API_URL + "/api/v1/logout")
        .then(function (response) {
          console.log(response);
          localStorage.removeItem("user");
          resolve(response.data.message);
          dispatch({ type: GET_USER, payload: null });
          dispatch({
            type: USER_STATUS,
            payload: false,
          });
        })
        .catch(function (error) {
          console.log(error);
          localStorage.removeItem("user");
          dispatch({ type: GET_USER, payload: null });
          dispatch({
            type: USER_STATUS,
            payload: false,
          });
          // reject(error.response.data.error);
        });
    });
  };
}

// Reset password link
export function updatepassword(values) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var obj_arr = {
        old_password: values.oldpassword,
        password: values.newpassword,
      };
      axiosInstance
        .post(API_URL + "/api/v1/change_password", obj_arr)
        .then(function (response) {
          console.log(response);
          resolve(response.data.message);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Reset password link
export function get_app_version_info() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(API_URL + "/api/v1/get_app_version_info")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get Send to Dropdown
export function get_send_to_dropdown() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(API_URL + "/api/v1/reference/send_to_dropdown")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response);
        });
    });
  };
}

// Get Create Announcement
export function create_announcement(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(API_URL + "/api/v1/announcements", params)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response);
        });
    });
  };
}

// Get Announcement Detail

export function get_announcement(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`${API_URL}/api/v1/announcements?page=${params.page ?? 1}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response);
        });
    });
  };
}
