import axiosInstance from "./AxiosInstance";

export function ibo_manager() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/userlist_based_on_role/ibo_manager")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function ibo_supervisor() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/userlist_based_on_role/ibo_supervisor")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function office_manager() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/userlist_based_on_role/office_manager")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function office_manager_name(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        //.get("/api/v1/userlist_based_on_role/office_manager")
        .get(`/api/v1/get_office_info/${params}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function office_supervisor() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/userlist_based_on_role/office_supervisor")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function retailerlist() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/userlist_based_on_role/office_supervisor")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function distributorlist() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/userlist_based_on_role/office_supervisor")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function master_agent() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/userlist_based_on_role/master_agent")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getagentlistname() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/userlist_based_on_role/agent")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
