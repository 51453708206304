import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getagedinventory } from "../../actions/inventoryAction";
import _ from "lodash";
import { Button, Table, Tag, Input, Select, Divider, Modal, Icon } from "antd";

class AgedInventory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      marketer: [],
      data: [],
      pagination: {},
      loading: true,
      retailer: [],
      selectedItems: [],
    };
  }

  componentWillMount() {
    this.getdesignation({
      results: 10,
      page: 1,
    });
  }

  getdesignation(params = {}) {
    this.props
      .getagedinventory(params)
      .then((res) => {
        console.log(res);
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.items.total;

        this.setState({
          data: res.data.items.data,
          loading: false,
          pagination,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }

  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      results: pagination.pageSize,
      page: pagination.current,
    });
  };
  fetch = (params = {}) => {
    console.log("params:", params);
    this.getdesignation(params);
  };

  // ======================================================

  render() {
    const columns = [
      {
        title: "Retailer",
        dataIndex: "ret_name",
        key: "ret_name",
      },
      {
        title: "DAY 1-30",
        dataIndex: "days1_15",
        key: "days1_15",
        sorter: true,
      },
      {
        title: "DAY 31-45",
        dataIndex: "days16_30",
        key: "days16_30",
        sorter: true,
      },
      {
        title: "DAY 45-60",
        dataIndex: "days31_45",
        key: "days31_45",
        sorter: true,
      },
      {
        title: "DAY 61-90",
        dataIndex: "days46_90",
        key: "days46_90",
        sorter: true,
      },
      {
        title: "DAY >90",
        dataIndex: "days91",
        key: "days91",
        sorter: true,
      },
    ];

    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            <div className="col-sm-4">
              <h4>Aged Inventory Report</h4>
            </div>
            <div className="col-sm-8 text-right">
              {/* {this.rendercsvformat(data)} */}
              <Button
                type="primary"
                className="mr5"
                onClick={() => this.getexportbtn()}
              >
                <i className="uil uil-export" /> Export Report
              </Button>
            </div>
          </div>

          <div className="bg-white padd0">
            <Table
              columns={columns}
              dataSource={this.state.data}
              scroll={{ x: true }}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              // size="small"
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    ibomarket: state.data,
    data: state.data,
  };
}

export default connect(mapStateToProps, {
  getagedinventory,
})(AgedInventory);
