import axiosInstance from "./AxiosInstance";

// Get IBO Marketers list
export function getMarketerslist(role, id) {
  console.log(id);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/get_ibo_marketer_info/" + role + "/" + id)
        .then(function (response) {
          console.log("ibo_marketers", response);
          const newArr1 = response.data.map((v) => ({
            ...v,
            key: v.id,
          }));
          // console.log(`newArr1`, newArr1)
          resolve(newArr1);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get roles list
export function getRolelist() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/roles")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get roles list
export function getsortlistuser() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/user_sort_keys")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get Offices list
export function getOfficelist() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/offices")
        .then(function (response) {
          console.log(response);
          const newArr1 = response.data.items.data.map((v) => ({
            ...v,
            key: v.id,
          }));
          // console.log(`newArr1`, newArr1)
          resolve(newArr1);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getofficelistname(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/v1/reference/offices/${params}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get Gender list
export function getGenderlist() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/genders")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get Employee list
export function getEmployeelist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/users?page=" + params.page)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get Employee list
export function getallsearchresult(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      axiosInstance
        .get(params.path + "page=" + params.page)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function searchEmployeelist(url) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(url);
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function createEmployee(values) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(values);

      axiosInstance
        .post("/api/v1/users", values)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function editEmployee(values, id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(values);
      axiosInstance
        .post(`/api/v1/users/${id}`, values)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function deleteEmployee(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .delete(`/api/v1/users/${id}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function Employeeaction(id, stat) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(id);
      var obj_arr = {
        status: stat,
      };
      axiosInstance
        .post(`/api/v1/users/action/${id}`, obj_arr)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function changeofficeuser(val, params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(val);
      var obj_arr = {
        office_id: val.office_id,
        agent_id: params.id,
      };
      axiosInstance
        .post(`/api/v1/change_office`, obj_arr)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
