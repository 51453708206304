import axios from "axios";
import { store } from "../store/store";

const axiosInstance = axios.create({
  baseURL: `https://services.excesswies.com`,
});

axiosInstance.interceptors.request.use((config) => {
  const state = store.getState();
  const token = state.user.token;
  config.headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  return config;
});
axiosInstance.interceptors.response.use(
  (response) => {
    //return response;
    return Promise.resolve(response);
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("user");
      window.location.reload();
    }
    //return error;
    return Promise.reject(error);
  }
);

export default axiosInstance;
