import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { resetpasswordemail } from "../actions/userAction";
import "../styles/css/App.css";
import Logo from "../styles/img/wies-logo.svg";
import Loginimg from "../styles/img/forgotpassword.svg";
import { Button, message, Input, Form } from "antd";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      loading: false,
    };
    // bind
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.user !== null) {
      this.props.history.push("/dashboard");
    }
  }

  // handle submit
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log("Received values of form: ", values);
      if (!err) {
        this.setState({
          iconLoading: true,
        });
        this.props
          .resetpasswordemail(values.Email)
          .then((res) => {
            console.log(res);
            message.success("Password reset link sent successfully!");
            this.setState({
              iconLoading: false,
            });
            this.props.form.resetFields();
          })
          .catch((err) => {
            console.log(err);
            message.error(err);
            this.setState({
              iconLoading: false,
            });
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    // eslint-disable-next-line
    var mailformat =
      // eslint-disable-next-line no-control-regex
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    return (
      <div className="cover-full">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7 hidden-sm hidden-xs padd0">
              <div className="img-flex bg-red">
                <img src={Loginimg} className="loginimg" alt="WiESSystem" />
              </div>
            </div>
            <div className="col-md-5 col-sm-12 col-xs-12 bg-white">
              <div className="panel-flex">
                <div className="panel panel-default loginpanel">
                  <div className="panel-body">
                    <img
                      src={Logo}
                      className="logologin mb20"
                      alt="WiESSystem"
                    />
                    <h4 className="text-left">Forgot Password</h4>
                    <p>
                      Enter your email linked with account to send password
                      reset link
                    </p>
                    <Form onSubmit={this.handleSubmit} className="">
                      <Form.Item label="Email">
                        {getFieldDecorator("Email", {
                          rules: [
                            {
                              required: true,
                              pattern: new RegExp(mailformat),
                              message: "Enter valid Email!",
                            },
                          ],
                        })(<Input type="email" placeholder="Email" />)}
                      </Form.Item>
                      <Form.Item>
                        <Button
                          htmlType="submit"
                          type="primary"
                          loading={this.state.iconLoading}
                          block
                          className="mb20"
                        >
                          Send Password Reset Email
                        </Button>
                        <Link to="/">
                          <p>Remember Password? Login</p>
                        </Link>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const WrappedForgotPassword = Form.create({ name: "ForgotPassword" })(
  ForgotPassword
);

function mapStateToProps(state, ownProps) {
  return { user: state.user };
}

export default connect(mapStateToProps, { resetpasswordemail })(
  WrappedForgotPassword
);
