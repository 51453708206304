/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { connect } from "react-redux";
import {} from "../../actions/assetAction";
import { Link } from "react-router-dom";
import bgimage from "../../styles/img/w4-circle.png";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {};
  }

  componentWillMount() {
    const { user } = this.props;
    console.log(user);
    if (!user) {
      this.props.history.push("/");
    } else if (user.password_changed === 0) {
      this.props.history.push("/changepassword");
    }
  }

  componentWillReceiveProps(prevProps, nextProps) {
    if (prevProps.user !== this.props.user) {
      console.log(this.props.user, prevProps.user);
      this.props.history.push("/");
    }
  }

  //   ----------------------------
  render() {
    return (
      <>
        <div
          className="container whyus-bg  d-none d-md-block "
          style={{
            backgroundImage: `url(${bgimage})`,
          }}
        >
          <div className="row">
            <div className="col-md-10">
              <div className="row">
                <div className="col-xl-2 col-lg-2 col-md-2 pos-1">
                  <Link
                    className="btn  btn-circle btn-xl"
                    to="/support"
                    rel="noreferrer"
                  ></Link>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-2 pos-2">
                  <Link
                    className="btn  btn-circle btn-xl"
                    to="/agentdashboard"
                    rel="noreferrer"
                  ></Link>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-2 pos-3">
                  <a
                    className="btn  btn-circle btn-xl"
                    target="_blank"
                    href="https://app.powerbi.com/links/ylTEID5w1j?ctid=05bb063b-04ef-43d6-87ce-ae94b28b181e&pbi_source=linkShare"
                    rel="noreferrer"
                  ></a>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-2 pos-4">
                  <Link
                    className="btn  btn-circle btn-xl"
                    to="/employees"
                    rel="noreferrer"
                  ></Link>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-2 pos-5">
                  <Link
                    className="btn  btn-circle btn-xl"
                    to="/salesdashboard"
                    rel="noreferrer"
                  ></Link>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-2 pos-6">
                  <Link
                    className="btn  btn-circle btn-xl"
                    to="/inventorydashboard"
                    rel="noreferrer"
                  ></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user, userinfo: state.userinfo };
}

export default connect(mapStateToProps, {})(Dashboard);
