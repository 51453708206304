import React, { Component } from "react";
import { connect } from "react-redux";
import {
  onboarding_teelgo_report,
  searchtelgoolist,
  assigntelgooid,
  telgoo_id_pending_candidates_export,
} from "../../actions/assetAction";
import _ from "lodash";
import {
  Button,
  Table,
  Tag,
  Input,
  Form,
  Select,
  DatePicker,
  Drawer,
  Icon,
  Checkbox,
  Col,
  Row,
  message,
} from "antd";

const { Option } = Select;

class OnboardingSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onboarding: [],
      data: [],
      pagination: {},
      loading: true,
      loadingbtn: false,
      loadingEPbtn: false,
      loadingTIDASSIbtn: false,
      startdate: "",
      enddate: "",
      startValue: null,
      endValue: null,
      endOpen: false,
      selectedItems: [],
      inventorystatus: [],
      search_text: "",
      selectedfieldname: [],
      selectedfilter: [],
      selectedstatus: [],
      office: [],
      agent: [],
      detailform: {},
      statusval: [],
      visible: false,
      checkrad: false,
    };
  }

  componentWillMount() {
    this.getdesignation({
      results: 10,
      page: 1,
    });
    console.log("data", this.state.data);
  }

  getdesignation(params = {}) {
    this.setState({
      loading: true,
    });
    this.props
      .onboarding_teelgo_report(params)
      .then((res) => {
        console.log(res.data.data.data);
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.data.total;
        this.setState({
          data: res.data.data.data,
          loading: false,
          pagination,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    console.log("sorter", sorter);
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      sortField: sorter.order === undefined ? "" : sorter.order,
      sortOrder:
        sorter.order === "ascend"
          ? "ASC"
          : sorter.order === undefined
          ? ""
          : "DESC",
    });
    setTimeout(() => {
      this.searchonboardingreport();
    }, 100);
  };
  fetch = (params = {}) => {
    console.log("params:", params);
    this.getdesignation(params);
  };
  renderonboarding() {
    return _.map(this.state.onboarding, (master, index) => {
      //console.log(master.id);
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }

  onchangeRole = (selectedItems) => {
    this.setState({ selectedItems });
    console.log(this.state.selectedItems);
  };

  searchonboardingreport = (reset) => {
    var search_text = this.state.name;
    var sort = this.state.sortField;
    var sort_by = this.state.sortOrder;
    var date_range_start = this.state.startValue;
    var date_range_end = this.state.endValue;

    var page = reset ? 1 : this.state.pagination?.current ?? 1;
    this.setState({ loading: true, loadingbtn: true });

    this.props
      .searchtelgoolist({
        search_text: search_text,
        page: page,
        sort,
        sort_by,
        date_range_start,
        date_range_end,
      })
      .then((res) => {
        console.log(res);
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.data.total;
        pagination.current = res.data.data.current_page;
        console.log("after search", res.data);
        this.setState({
          data: res.data.data.data,
          loading: false,
          loadingbtn: false,
          pagination,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
          loadingbtn: false,
        });
      });
  };

  clearSearch = () => {
    this.setState({
      status: undefined,
      name: "",
      startValue: null,
      endValue: null,
    });
    this.getdesignation({
      results: 10,
      page: 1,
    });
  };
  onchangefilter = (selectedstatus) => {
    this.setState({ selectedstatus });
  };
  disabledStartDate = (startValue) => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  disabledEndDate = (endValue) => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };
  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };
  onStartChange = (value) => {
    this.onChange("startValue", value);
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };
  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };
  showDrawer = (record) => {
    this.props.form.setFieldsValue({
      name: `${record.first_name} ${record.middle_name ?? ""} ${
        record.last_name
      }`,
      candidate_id: record.id,
    });
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
    this.props.form.resetFields();
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        this.setState({ loadingTIDASSIbtn: true });
        this.props
          .assigntelgooid({
            candidate_id: values.candidate_id.toString(),
            telgoo_id: values.telgoo_id,
            telgoo_password: values.telgoo_password,
          })
          .then((res) => {
            message.success("Telgoo ID successfully mapped");
            this.setState({
              visible: false,
              loadingTIDASSIbtn: false,
            });
            this.getdesignation({
              results: 10,
              page: 1,
            });
          })
          .catch((err) => {
            message.error(err);
            this.setState({ loadingTIDASSIbtn: false });
          });
      }
    });
  };
  getexportbtn = () => {
    var search_text = this.state.name;
    var date_range_start = this.state.startValue;
    var date_range_end = this.state.endValue;
    this.setState({ loading: true, loadingEPbtn: true });
    this.props
      .telgoo_id_pending_candidates_export({
        search_text,
        date_range_start,
        date_range_end,
      })
      .then((res) => {
        this.setState({ loading: false, loadingEPbtn: false });
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        this.setState({ loading: false, loadingEPbtn: false });
      });
  };
  handleChange = (e) => {
    console.log(e.target.checked);
    this.setState({
      checkrad: e.target.checked,
    });
  };
  // ======================================================

  render() {
    const { endOpen, startValue, endValue } = this.state;
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: "Telgoo ID",
        dataIndex: "telgoo_id",
        key: "telgoo_id",
      },
      {
        title: "RAD ID",
        dataIndex: "rad_id",
        key: "rad_id",
      },
      {
        title: "Office",
        dataIndex: "office_name",
        key: "office_name",
      },
      {
        title: "First Name",
        dataIndex: "first_name",
        key: "first_name",
        sorter: true,
      },
      {
        title: "Middle Name",
        dataIndex: "middle_name",
        key: "middle_name",
        sorter: true,
      },
      {
        title: "Last Name",
        dataIndex: "last_name",
        key: "last_name",
        sorter: true,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: true,
      },
      {
        title: "Mobile Number",
        dataIndex: "mobile_number",
        key: "mobile_number",
        sorter: true,
      },

      {
        title: "Created Date",
        dataIndex: "created_at",
        key: "created_at",
        sorter: true,
      },
      {
        title: "Status",
        dataIndex: "status_name",
        key: "status_name",

        fixed: "right",
        width: 100,
        render: (text, record) => (
          <span>
            <Tag color="orange">{record.status_name}</Tag>
          </span>
        ),
      },
      {
        title: "Action",
        key: "action",
        width: 180,
        fixed: "right",
        render: (text, record) => (
          <span>
            <Button onClick={() => this.showDrawer(record)} type="primary">
              Assign Telgoo ID
            </Button>
          </span>
        ),
      },
    ];

    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            <div className="col-sm-4">
              <h4>
                Telgoo ID Mapping{" "}
                {this.state.pagination.total &&
                  `(${this.state.pagination.total})`}
              </h4>
            </div>
            <div className="col-sm-8 text-right">
              {/* {this.rendercsvformat(data)} */}
              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingEPbtn}
                onClick={() => this.getexportbtn()}
              >
                <i className="uil uil-export" /> Export Report
              </Button>
            </div>
          </div>
          <div className="row padd20 bg-grey">
            <div className="col">
              <label for="exampleFormControlInput1">Start</label>
              <DatePicker
                value={startValue}
                disabledDate={this.disabledStartDate}
                format="MM-DD-YYYY"
                placeholder="Select Date"
                onChange={this.onStartChange}
                onOpenChange={this.handleStartOpenChange}
              />
            </div>
            <div className="col">
              <label for="exampleFormControlInput1">End</label>
              <DatePicker
                value={endValue}
                disabledDate={this.disabledEndDate}
                format="MM-DD-YYYY"
                placeholder="Select Date"
                onChange={this.onEndChange}
                open={endOpen}
                onOpenChange={this.handleEndOpenChange}
              />
            </div>
            <div className="col">
              <label for="exampleFormControlInput1">Search By keyword</label>
              <Input
                placeholder="Type keyword"
                value={this.state.name}
                // ref={(node) => {
                //   this.searchInput = node;
                // }}
                onChange={(e) =>
                  this.setState({
                    name: e.target.value,
                  })
                }
                style={{ width: "100%", display: "block" }}
              />
            </div>
            <div className="col mt-6">
              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingbtn}
                onClick={() => this.searchonboardingreport("reset")}
              >
                Search
              </Button>
              <Button type="default" onClick={() => this.clearSearch()}>
                Clear
              </Button>
            </div>
          </div>
          <div className="bg-white padd0 mt-3">
            <Table
              columns={columns}
              dataSource={this.state.data}
              scroll={{ x: true }}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              // size="small"
            />
          </div>
          <Drawer
            title="Telgoo ID Mapping"
            width={520}
            onClose={this.onClose}
            visible={this.state.visible}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Form onSubmit={this.handleSubmit} layout="vertical">
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Candidate Name">
                    {getFieldDecorator("name", {
                      rules: [
                        { required: true, message: "Please enter user name" },
                      ],
                    })(<Input disabled />)}
                  </Form.Item>
                </Col>
                {/* <Col span={24}>
                  <Form.Item>
                    {getFieldDecorator("agreement", {
                      valuePropName: "checked",
                      rules: [
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject(
                                  new Error("Please check option")
                                ),
                        },
                      ],
                    })(
                      <Checkbox onChange={this.handleChange}>
                        Is RAD ID Linked?
                      </Checkbox>
                    )}
                  </Form.Item>
                </Col> */}

                <Col span={24}>
                  <Form.Item label="Telgoo ID">
                    {getFieldDecorator("telgoo_id", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter valid Telgoo ID",
                        },
                      ],
                    })(<Input placeholder="Please enter Telgoo ID" />)}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Telgoo Password">
                    {getFieldDecorator("telgoo_password", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter Telgoo password",
                        },
                      ],
                    })(<Input placeholder="Please enter Telgoo Password" />)}
                  </Form.Item>
                </Col>

                <Col span={24} style={{ visibility: "hidden" }}>
                  <Form.Item label="Telgoo ID">
                    {getFieldDecorator("candidate_id", {
                      rules: [
                        { required: true, message: "Please enter user name" },
                      ],
                    })(<Input placeholder="Please enter Telgoo ID" />)}
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Button
                    type="default"
                    onClick={this.onClose}
                    style={{ marginRight: 8 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={this.state.loadingTIDASSIbtn}
                  >
                    Assign
                  </Button>
                </Col>
              </Row>
            </Form>
          </Drawer>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    data: state.data,
  };
}

const WrappedAdvancedSearchForm = Form.create({ name: "advanced_search" })(
  OnboardingSummary
);

export default connect(mapStateToProps, {
  onboarding_teelgo_report,
  telgoo_id_pending_candidates_export,
  searchtelgoolist,
  assigntelgooid,
})(WrappedAdvancedSearchForm);
