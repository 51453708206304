import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Select,
  Button,
  Input,
  Form,
  message,
  InputNumber,
  Radio,
  Icon,
  Upload,
  Col,
  Row,
  Spin,
  DatePicker,
} from "antd";
import _ from "lodash";
import moment from "moment";
import {
  getRolelist,
  getMarketerslist,
  getGenderlist,
  editEmployee,
} from "../../actions/employeeAction";
import {
  getretailers,
  getdistributers,
  getmaster,
  getibomarketlist,
  getofficelistname,
  updatecandidateuser,
} from "../../actions/assetAction";

const { Option } = Select;
const { Dragger } = Upload;

class EditAgent extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      showcase: false,
      fileList: [],
      fileList2: [],
    };
  }

  onChange(value) {
    console.log(`selected ${value}`);
  }

  componentWillMount() {
    if (this.props.location.agent_info === undefined) {
      this.props.history.push("/OnboardingSummaryReport");
    } else {
      console.log(this.props.location.agent_info);
    }
  }

  getGenderlist() {
    // nprogress.start();
    if (this.state.gender === undefined || this.state.gender.length <= 0) {
      this.props
        .getGenderlist()
        .then((res) => {
          console.log(res);
          this.setState({
            gender: res.data,
          });
          // nprogress.done();
        })
        .catch((err) => {
          this.setState({
            gender: [],
          });
          // nprogress.done();
        });
    }
  }

  getMarketerslist(role) {
    // nprogress.start();
    this.props
      .getMarketerslist(
        role === 4 ? "M" : "IM",
        this.props.location.employee.id
      )
      .then((res) => {
        console.log("marketers", res);
        this.setState({
          marketers: res,
        });
        // nprogress.done();
      })
      .catch((err) => {
        this.setState({
          marketers: [],
          showcase: false,
        });
        // nprogress.done();
      });
  }

  getMarketerslistoff() {
    this.setState({
      marketers: [],
    });
    this.props
      .getibomarketlist()
      .then((res) => {
        this.props.form.setFields({
          ibo_marketer_id: [],
        });
        console.log("marketers", res);
        this.setState({
          marketers: res.data,
        });
        // nprogress.done();
      })
      .catch((err) => {
        this.setState({
          marketers: [],
        });
        // nprogress.done();
      });
  }

  getOfficelist() {
    if (this.state.offices === undefined || this.state.offices.length <= 0) {
      // nprogress.start();
      this.props
        .getofficelistname()
        .then((res) => {
          console.log(res);
          this.setState({
            offices: res.data,
          });
          // nprogress.done();
        })
        .catch((err) => {
          this.setState({
            offices: [],
          });
          // nprogress.done();
        });
    }
  }

  getRolelist() {
    if (this.state.roles === undefined || this.state.roles.length <= 0) {
      // nprogress.start();
      this.props
        .getRolelist()
        .then((res) => {
          // console.log('getRolelist', res);
          this.setState({
            roles: res.data.items,
          });
          // nprogress.done();
        })
        .catch((err) => {
          this.setState({
            roles: [],
          });
          // nprogress.done();
        });
    }
  }

  renderOffice() {
    return _.map(this.state.offices, (dep, index) => {
      return (
        <Option value={dep.id} key={index}>
          {dep.name}
        </Option>
      );
    });
  }

  renderGender() {
    // console.log(`this.state.gender`, this.state.gender)
    return _.map(this.state.gender, (dep, index) => {
      // console.log(`dep`, dep)
      // dep.map((genderData) => {
      return (
        <Option value={dep.id} key={index}>
          {dep.name}
        </Option>
      );
      // });
    });
  }

  renderRole() {
    // console.log('this.state.roles', this.state.roles)
    return _.map(this.state.roles, (dep, index) => {
      if (this.state.superadmin === true) {
        return (
          <Col span={8}>
            <Radio value={dep.id}>
              {dep.name}
              {/* Market Supervisor */}
            </Radio>
          </Col>
        );
      }
      if (this.state.superrole === true) {
        if (dep.id === 5 || dep.id === 6 || dep.id === 7) {
          const value = dep.id === 6 ? true : dep.id === 7 ? true : false;
          return (
            <Col span={24}>
              <Radio disabled={value} value={dep.id}>
                {dep.name}
                {/* Market Supervisor */}
              </Radio>
            </Col>
          );
        }
      }
      if (this.state.marketrole === true) {
        if (dep.id === 3 || dep.id === 9) {
          return (
            <Col span={24}>
              <Radio value={dep.id}>
                {dep.name}
                {/* Market Supervisor */}
              </Radio>
            </Col>
          );
        }
      }
      if (this.state.retailerrole === true) {
        if (
          dep.id === 3 ||
          dep.id === 9 ||
          dep.id === 4 ||
          dep.id === 8 ||
          dep.id === 11 ||
          dep.id === 12
        ) {
          return (
            <Col span={8}>
              <Radio
                value={dep.id}
                // disabled={
                //   dep.id === 3 || dep.id === 9 || dep.id === 4 || dep.id === 8
                //     ? false
                //     : true
                // }
              >
                {dep.name}
                {/* Market Supervisor */}
              </Radio>
            </Col>
          );
        }
      }
    });
  }
  renderMarketers() {
    return _.map(this.state.marketers, (dep, index) => {
      return (
        <Option value={dep.id} key={index}>
          {dep.name}
        </Option>
      );
    });
  }

  onChangeRole = (e) => {
    var checkedValues = [e.target.value];
    console.log("checked = ", checkedValues);
    if (e.target.value === 3 || e.target.value === 9 || e.target.value === 8) {
      this.setState({ off_disable: true });
      this.getMarketerslistoff();
    } else {
      this.getMarketerslist(e.target.value);
    }
    var masterIndex = this.state.roles.findIndex(
      (role) => role.name === "Master Agent"
    );
    var DistributorIndex = this.state.roles.findIndex(
      (role) => role.name === "Distributor"
    );
    var RetailerIndex = this.state.roles.findIndex(
      (role) => role.name === "Retailer"
    );
    var OffManagerIndex = this.state.roles.findIndex(
      (role) => role.name === "Office Manager"
    );

    var IBOManagerIndex = this.state.roles.findIndex(
      (role) => role.name === "IBO Manager"
    );
    var IBOSuperIndex = this.state.roles.findIndex(
      (role) => role.name === "IBO Add-on Manager"
    );
    var IBOInventIndex = this.state.roles.findIndex(
      (role) => role.name === "IBO Inventory Manager"
    );
    var masterusers = checkedValues.filter(
      (obj) => obj === this.state.roles[masterIndex].id
    );
    var distributeusers = checkedValues.filter(
      (obj) => obj === this.state.roles[DistributorIndex].id
    );
    var retaileusers = checkedValues.filter(
      (obj) =>
        obj === this.state.roles[RetailerIndex].id ||
        obj === this.state.roles[IBOManagerIndex].id ||
        obj === this.state.roles[IBOSuperIndex].id ||
        obj === this.state.roles[IBOInventIndex].id ||
        obj === this.state.roles[OffManagerIndex].id
    );

    console.log(retaileusers);

    if (masterusers.length !== 0) {
      this.setState({
        masterview: true,
      });
    } else {
      this.setState({
        masterview: false,
      });
    }
    if (distributeusers.length !== 0) {
      this.setState({
        distributorview: true,
      });
    } else {
      this.setState({
        distributorview: false,
      });
    }
    if (retaileusers.length !== 0) {
      this.setState({
        retailerview: true,
      });
    } else {
      this.setState({
        retailerview: false,
      });
    }
  };

  officeOnChange = (e) => {
    var fdesgnd = _.filter(this.state.offices, ["Offices", e]);
    this.setState({
      filteredoffices: fdesgnd,
    });
    console.log(e, fdesgnd);
    // this.props.form.setFields({
    //   offices: {
    //     value: "",
    //   },
    // });
  };

  roleOnChange = (e) => {
    var fdesgnd = _.filter(this.state.roles, ["Role", e]);
    this.setState({
      filteredroles: fdesgnd,
    });
    console.log(e, fdesgnd);
  };

  marketerOnChange = (e) => {
    var fdesgnd = _.filter(this.state.marketers, ["Marketers", e]);
    this.setState({
      filteredmarketers: fdesgnd,
    });
    console.log(e, fdesgnd);
  };

  designationOnChange = (e) => {
    console.log(e);
    if (e === 10) this.setState({ isFieldChampion: true });
    else this.setState({ isFieldChampion: false });
  };

  disabledDOB(current) {
    return moment().add(0, "days") <= current;
  }
  disabledDOJ(current) {
    return moment().add(-1, "days") >= current;
  }

  mobilevalidation = (value) => {
    console.log(value);
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    if (value.test(phoneno)) {
      console.log(value.match(phoneno));
      this.props.form.setFieldsValue({
        MobileNo: value,
      });
    }
  };
  // handleSubmit = (e) => {
  //   e.preventDefault();
  //   this.props.form.validateFields((err, values) => {
  //     if (!err) {
  //       this.props.form.validateFields((err, values) => {
  //         console.log(`values`, values);
  //         if (!err) {
  //           this.setState({
  //             loading: true,
  //           });
  //           var employee = {
  //             _method: "PUT",
  //             first_name: values.first_name,
  //             middle_name: values.middle_name,
  //             last_name: values.last_name,
  //             rad: values.rad,
  //             promo_code: values.promo_code,
  //             excess_code: values.excess_code,
  //             mobile_number:
  //               values.mobile_number !== undefined ||
  //               values.mobile_number !== null
  //                 ? values.mobile_number.toString()
  //                 : "",
  //             gender_id: values.gender_id, //this.state.filteredgender.id,
  //             office_id: values.office_id ?? "",
  //             ibo_marketer_id:
  //               values.ibo_marketer_id !== null &&
  //               values.ibo_marketer_id !== undefined
  //                 ? values.ibo_marketer_id
  //                 : "",
  //             dob: moment(this.state.dob).format("YYYY-MM-DD"),
  //             application_date: moment(this.state.application_date).format(
  //               "YYYY-MM-DD"
  //             ),
  //             address:
  //               values.address !== undefined || values.address !== null
  //                 ? values.address
  //                 : "",
  //             city:
  //               values.city !== undefined || values.city !== null
  //                 ? values.city
  //                 : "",
  //             state:
  //               values.state !== undefined || values.state !== null
  //                 ? values.state
  //                 : "",
  //             zip:
  //               values.zip === undefined || values.zip === null
  //                 ? ""
  //                 : values.zip.toString(),
  //             email: values.email,
  //             role_id: [values.role_id], //[9, 2],
  //             retailer_id:
  //               values.RetailerName !== undefined ? values.RetailerName : "",
  //             distributor_id:
  //               values.DistributorName !== undefined
  //                 ? values.DistributorName
  //                 : "",
  //             master_agent_id: values.Master !== undefined ? values.Master : "",
  //           };
  //           console.log(`employee`, employee);

  //           this.props
  //             .editEmployee(employee, this.props.location.employee.id)
  //             .then((res) => {
  //               message.success("User updated successfully");
  //               this.props.form.resetFields();
  //               this.setState({
  //                 // fileList: [],
  //                 loading: false,
  //                 // pwdstatus: "",
  //               });
  //               this.props.history.push("/employees");
  //             })
  //             .catch((err) => {
  //               message.error(err);
  //               console.log(err);
  //               this.setState({
  //                 loading: false,
  //               });
  //             });
  //         }
  //       });
  //     }
  //   });
  // };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      console.log(values);
      if (!err) {
        this.setState({ loading: true, loadingSBTbtn: true });

        const { fileList, fileList2 } = this.state;

        const formData = new FormData();

        fileList?.forEach((fileList) => {
          formData.append("photo_copy", fileList ?? []);
        });
        fileList2?.forEach((fileList) => {
          formData.append("government_document", fileList ?? []);
        });
        formData.append("first_name", values?.first_name ?? "");
        formData.append("last_name", values?.last_name ?? "");
        formData.append("middle_name", values?.middle_name ?? "");
        formData.append("street_address1", values?.address ?? "");
        formData.append("street_address2", values?.street_address2 ?? "");
        formData.append("city", values?.city ?? "");
        formData.append("zip", values?.zip ?? "");
        formData.append("state", values?.state ?? "");
        formData.append("email", values.email);
        formData.append("dob", moment(values?.dob ?? "").format("MM-DD-YYYY"));
        formData.append("ssn_number", values.ssn_number);
        formData.append("rad_id", values?.rad_id ?? "");
        formData.append("shirt_size", values?.shirt_size ?? "");
        formData.append("mobile_number", values.mobile_number.toString());

        // var obj_arr = {
        //   first_name: values.first_name,
        //   last_name: values.last_name,
        //   middle_name: values.middle_name,
        //   street_address1: values.address,
        //   street_address2:"",
        //   city: values.city,
        //   state: values.state,
        //   zip: values.zip,
        //   email: values.email,
        //   photo_copy_url:this.state.fileList.file,
        //   government_document_url:this.state.fileList2.file,
        //   mobile_number:
        //       values.mobile_number !== undefined ||
        //       values.mobile_number !== null
        //         ? values.mobile_number.toString()
        //         : "",

        // };
        var agent_id = this.props.location.agent_info.id;
        console.log("agent_id><>>>", agent_id);
        this.props
          .updatecandidateuser(formData, agent_id)
          .then((res) => {
            console.log("res", res.data.message);
            message.success(res.data.message);
            this.setState({
              // visible1: false,
              loading: false,
              // loadingSBTbtn: false,
              // visibleedit: false,
              // activeedit: "",
            });
            // this.props.form.resetFields();
            this.props.history.push("/OnboardingSummaryReport");
            // this.getdesignation({
            //   results: 10,
            //   page: 1,
            // });
          })
          .catch((error) => {
            message.error(error);
            console.log(error);
            this.setState({ loading: false, loadingSBTbtn: false });
          });
      }
    });
  };
  renderMaster() {
    return _.map(this.state.master, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderDistributor() {
    return _.map(this.state.distributor, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderRetailer() {
    return _.map(this.state.retailer, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  render() {
    const { fileList, fileList2, uploading, superrole } = this.state;
    const propsthumb = {
      multiple: false,

      accept: ".jpg,.jpeg",
      showUploadList: { showPreviewIcon: false, showRemoveIcon: true },
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
        // console.log(this.state.fileList);
      },
      beforeUpload: (file) => {
        console.log(file);
        this.setState((state) => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };
    const propsthumb2 = {
      multiple: false,
      fileList: fileList2,
      accept: ".jpg,.jpeg",
      showUploadList: { showPreviewIcon: false, showRemoveIcon: true },
      onRemove: (file2) => {
        this.setState((state) => {
          const index = state.fileList2.indexOf(file2);
          const newFileList = state.fileList2.slice();
          newFileList.splice(index, 1);
          return {
            fileList2: newFileList,
          };
        });
        console.log(this.state.fileList2);
      },
      beforeUpload: (file2) => {
        console.log(file2);
        this.setState((state) => ({
          fileList2: [...state.fileList2, file2],
        }));
        return false;
      },
      fileList2,
    };
    // eslint-disable-next-line
    var mailformat =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    const { getFieldDecorator } = this.props.form;
    const { loading, showcase } = this.state;

    return (
      <Spin spinning={showcase}>
        <div className="container-fluid">
          <div class="row justify-content-between padd20 bg-grey">
            <div class="col-4">
              <h4 className="mt-1">Edit Candidate</h4>
            </div>
            <div class="col-4">
              <Link to="/OnboardingSummaryReport" className="float-right">
                <Button type="default">Back</Button>
              </Link>
            </div>
          </div>

          <div className=" bg-white padd15">
            <Form onSubmit={this.handleSubmit} className="addbookform row">
              <Form.Item
                label="First Name"
                className="col-md-4 col-sm-6 col-xs-12"
              >
                {getFieldDecorator("first_name", {
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : this.props.location.agent_info.first_name,
                  rules: [
                    {
                      required: true,
                      pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                      message: "Please input first name",
                    },
                  ],
                })(<Input placeholder="Enter First Name" />)}
              </Form.Item>
              <Form.Item
                label="Middle Name"
                className="col-md-4 col-sm-6 col-xs-12"
              >
                {getFieldDecorator("middle_name", {
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : this.props.location.agent_info.middle_name,
                  rules: [
                    {
                      required: false,
                      pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                      message: "Please input middle name",
                    },
                  ],
                })(<Input placeholder="Enter Middle Name" />)}
              </Form.Item>
              <Form.Item
                label="Last Name"
                className="col-md-4 col-sm-6 col-xs-12"
              >
                {getFieldDecorator("last_name", {
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : this.props.location.agent_info.last_name,
                  rules: [
                    {
                      required: true,
                      pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                      message: "Please input last name",
                    },
                  ],
                })(<Input placeholder="Enter Last Name" />)}
              </Form.Item>
              <Form.Item
                label="Email"
                className="col-md-4 col-sm-6 col-xs-12 mt-4 "
              >
                {getFieldDecorator("email", {
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : this.props.location.agent_info.email,
                  rules: [
                    {
                      required: true,
                      pattern: new RegExp(mailformat),
                      message: "Enter valid email address",
                    },
                  ],
                })(
                  <Input
                    type="email"
                    placeholder="Enter Email Address"
                    autoComplete="new-password"
                    // onBlur={(e) => this.checkEmail(e.target.value)}
                  />
                )}
              </Form.Item>
              <Form.Item
                label="Mobile Number"
                className="col-md-4 col-sm-6 col-xs-12 mt-4"
              >
                {getFieldDecorator("mobile_number", {
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : this.props.location.agent_info.mobile_number,
                  rules: [
                    {
                      required: true,
                      pattern: new RegExp(/^[0-9]{10,10}$/),
                      message: "Please input mobile number",
                    },
                  ],
                })(
                  <InputNumber
                    placeholder="Enter Mobile Number"
                    minLength={10}
                    maxLength={10}
                    min={0}
                    step={0}
                  />
                )}
              </Form.Item>
              <Form.Item
                label="Date of birth"
                className="col-md-4 col-sm-6 col-xs-12 mt-4"
              >
                {getFieldDecorator("dob", {
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : moment(this.props.location?.agent_info?.dob ?? ""),
                  rules: [
                    {
                      required: true,
                      message: "Please input date of birth",
                    },
                  ],
                })(
                  <DatePicker
                    style={{ width: "100%" }}
                    format={"MM-DD-YYYY"}
                    placeholder="Date of Birth"
                    disabledDate={(current) =>
                      current.isAfter(moment().subtract(18, "year"))
                    }
                    showToday={false}
                  />
                )}
              </Form.Item>
              <Form.Item
                label="SSN Number"
                className="col-md-4 col-sm-6 col-xs-12 mt-4 "
              >
                {getFieldDecorator("ssn_number", {
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : this.props.location.agent_info.ssn_number,
                  rules: [
                    {
                      required: true,
                      pattern: new RegExp(
                        /^(?!(000|666|9))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$|^(?!(000|666|9))\d{3}(?!00)\d{2}(?!0000)\d{4}$/
                      ),
                      message: "Please input valid SSN Number!",
                    },
                  ],
                })(<Input placeholder="Enter SSN number" />)}
              </Form.Item>
              <Form.Item
                label="RAD ID"
                className="col-md-4 col-sm-6 col-xs-12 mt-4 "
              >
                {getFieldDecorator("rad_id", {
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : this.props.location.agent_info.rad_id,
                  rules: [
                    {
                      pattern: new RegExp("^[a-zA-Z0-9]{0,9}$"),
                      required: false,
                      message: "Please enter Valid RAD ID",
                    },
                    // {
                    //   pattern: /^[A-Za-z0-9]+$/,
                    //   message: "Please enter Valid RAD ID",
                    // },
                  ],
                })(<Input maxLength={9} placeholder="Enter RAD ID" />)}
              </Form.Item>
              <Form.Item
                label="Shirt Size"
                className="col-md-4 col-sm-6 col-xs-12 mt-4"
              >
                {getFieldDecorator("shirt_size", {
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : this.props.location.agent_info.shirt_size,
                  rules: [
                    { required: false, message: "Please select shirt size" },
                  ],
                })(
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select option"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="XS">XS</Option>
                    <Option value="S">S</Option>
                    <Option value="M">M</Option>
                    <Option value="L">L</Option>
                    <Option value="XL">XL</Option>
                    <Option value="XXL">XXL</Option>
                    <Option value="XXXL">XXXL</Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item
                label="Street Address"
                className="col-md-4 col-sm-6 col-xs-12 mt-4"
              >
                {getFieldDecorator("address", {
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : this.props.location.agent_info.street_address1,
                  rules: [{ required: false, message: "Please input 	Address" }],
                })(<Input placeholder="Address" />)}
              </Form.Item>
              <Form.Item
                label="Street Address2"
                className="col-md-4 col-sm-6 col-xs-12 mt-4"
              >
                {getFieldDecorator("street_address2", {
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : this.props.location.agent_info.street_address2,
                  rules: [{ required: false, message: "Please input 	Address" }],
                })(<Input placeholder="Address" />)}
              </Form.Item>
              <Form.Item
                label="City"
                className="col-md-4 col-sm-6 col-xs-12 mt-4"
              >
                {getFieldDecorator("city", {
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : this.props.location.agent_info.city,
                  rules: [{ required: false, message: "Please input 	City" }],
                })(<Input placeholder="City" />)}
              </Form.Item>
              <Form.Item
                label="State"
                className="col-md-4 col-sm-6 col-xs-12 mt-4"
              >
                {getFieldDecorator("state", {
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : this.props.location.agent_info.state,
                  rules: [{ required: false, message: "Please input 	State" }],
                })(
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select State"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="AL">Alabama</Option>
                    <Option value="AK">Alaska</Option>
                    <Option value="AZ">Arizona</Option>
                    <Option value="AR">Arkansas</Option>
                    <Option value="CA">California</Option>
                    <Option value="CO">Colorado</Option>
                    <Option value="CT">Connecticut</Option>
                    <Option value="DE">Delaware</Option>
                    <Option value="DC">District Of Columbia</Option>
                    <Option value="FL">Florida</Option>
                    <Option value="GA">Georgia</Option>
                    <Option value="HI">Hawaii</Option>
                    <Option value="ID">Idaho</Option>
                    <Option value="IL">Illinois</Option>
                    <Option value="IN">Indiana</Option>
                    <Option value="IA">Iowa</Option>
                    <Option value="KS">Kansas</Option>
                    <Option value="KY">Kentucky</Option>
                    <Option value="LA">Louisiana</Option>
                    <Option value="ME">Maine</Option>
                    <Option value="MD">Maryland</Option>
                    <Option value="MA">Massachusetts</Option>
                    <Option value="MI">Michigan</Option>
                    <Option value="MN">Minnesota</Option>
                    <Option value="MS">Mississippi</Option>
                    <Option value="MO">Missouri</Option>
                    <Option value="MT">Montana</Option>
                    <Option value="NE">Nebraska</Option>
                    <Option value="NV">Nevada</Option>
                    <Option value="NH">New Hampshire</Option>
                    <Option value="NJ">New Jersey</Option>
                    <Option value="NM">New Mexico</Option>
                    <Option value="NY">New York</Option>
                    <Option value="NC">North Carolina</Option>
                    <Option value="ND">North Dakota</Option>
                    <Option value="OH">Ohio</Option>
                    <Option value="OK">Oklahoma</Option>
                    <Option value="OR">Oregon</Option>
                    <Option value="PA">Pennsylvania</Option>
                    <Option value="RI">Rhode Island</Option>
                    <Option value="SC">South Carolina</Option>
                    <Option value="SD">South Dakota</Option>
                    <Option value="TN">Tennessee</Option>
                    <Option value="TX">Texas</Option>
                    <Option value="UT">Utah</Option>
                    <Option value="VT">Vermont</Option>
                    <Option value="VA">Virginia</Option>
                    <Option value="WA">Washington</Option>
                    <Option value="WV">West Virginia</Option>
                    <Option value="WI">Wisconsin</Option>
                    <Option value="WY">Wyoming</Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item
                label="ZIP"
                className="col-md-4 col-sm-6 col-xs-12 mt-4"
              >
                {getFieldDecorator("zip", {
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : this.props.location.agent_info.zip,
                  rules: [
                    {
                      required: false,
                      pattern: new RegExp(/^[0-9]+$/),
                      message: "Please input 	ZIP",
                    },
                  ],
                })(<Input maxLength={5} placeholder="ZIP" />)}
              </Form.Item>

              <Form.Item
                label="Profile Picture"
                className="col-md-6 col-sm-12 col-xs-12 paddlr15 mt-4"
              >
                {getFieldDecorator("Documents1", {
                  getValueFromEvent: this.normFile,
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : this.props.location.agent_info.photo_copy,
                  rules: [
                    {
                      required: false,
                      message: "Please upload required documents!",
                    },
                  ],
                })(
                  <Dragger {...propsthumb}>
                    <p className="ant-upload-drag-icon">
                      <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                      Support for a single or bulk upload. Strictly prohibit
                      from uploading company data or other band files
                    </p>
                  </Dragger>
                )}
              </Form.Item>
              <Form.Item
                label="ID Picture"
                className="col-md-6 col-sm-12 col-xs-12 paddlr15 mt-4"
              >
                {getFieldDecorator("Documents2", {
                  getValueFromEvent: this.normFile,
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : this.props.location.agent_info.government_document,
                  rules: [
                    {
                      required: false,
                      message: "Please upload required documents!",
                    },
                  ],
                })(
                  <Dragger {...propsthumb2}>
                    <p className="ant-upload-drag-icon">
                      <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                      Support for a single or bulk upload. Strictly prohibit
                      from uploading company data or other band files
                    </p>
                  </Dragger>
                )}
              </Form.Item>
              <br />
              <br />
              <div className="col-md-12 col-sm-12 col-xs-12 padd15">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={loading}
                >
                  Update User
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Spin>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user, data: state.data };
}
const WrappedEditEmployee = Form.create({ name: "EditEmployee" })(EditAgent);
export default connect(mapStateToProps, {
  getRolelist,
  getMarketerslist,
  getofficelistname,
  getGenderlist,
  editEmployee,
  getretailers,
  getdistributers,
  getmaster,
  getibomarketlist,
  updatecandidateuser,
})(WrappedEditEmployee);
