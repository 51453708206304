import React, { Component } from "react";
import { connect } from "react-redux";
import { logout } from "../actions/userAction";
import { cleardatadb } from "../actions/assetAction";
import { Avatar, Icon, Dropdown, message,Tag } from "antd";
import { Link } from "react-router-dom";
import FreshChat, { widget } from "react-freshchat";

class ProDropdown extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      visible: false,
    };
  }

  handleclick = () => {
    this.props
      .cleardatadb()
      .then((res) => {
        message.success("Data Cleared Successfully!");
      })
      .catch((err) => {
        message.error("Something Went Wrong!");
      });
  };

  handlelogout = () => {
    this.props.logout();
    widget().user.clear();
    widget().destroy();
  };

  render() {
    return (
      <Dropdown
        overlay={
          <ul className="profilemenulist">
            {/* <li>
              <Link to="/profile">
                <span>Welcome {this.props.user.name}</span>
              </Link>
            </li> */}
            {this.props.user?.retailer_name && !this.props.user?.ibo_marketer_name && (
            <li>
              <Tag color="#108ee9">
                {this.props.user?.retailer_name}
              </Tag> 
            </li>
            )}
            {this.props.user?.ibo_marketer_name && (
            <li>
              <Tag  color="#108ee9">
                {this.props.user?.ibo_marketer_name}
              </Tag>
            </li>
            )}
            <li>
              <Link to="/profile">
                <Icon type="user" /> <span>Profile </span>
              </Link>
            </li>
            {/* <li>
              <p onClick={() => this.handleclick()}>
                <Icon type="database" />
                <span className="nav-text"> Clear Data</span>
              </p>
            </li> */}
            <li>
              <p onClick={() => this.handlelogout()}>
                <Icon type="logout" />
                <span className="nav-text"> Logout</span>
              </p>
            </li>
          </ul>
        }
        placement="bottomRight"
        trigger={["click"]}
        className="proicon"
      >
        <span>
          <Avatar src="https://149368894.v2.pressablecdn.com/wp-content/uploads/2019/09/iStock-1018999828.jpg" />
          Welcome {this.props.user.name}
          
        </span>
      </Dropdown>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    userinfo: state.userinfo,
  };
}

export default connect(mapStateToProps, { logout, cleardatadb })(ProDropdown);
