import React, { Component } from "react";
import { connect } from "react-redux";
// with withRouter You can get access to the history objectâ€™s properties
import { withRouter } from "react-router-dom";
import ChangePassword from "./settings/changepassword";

class AuthenticatedComponent extends Component {
  componentDidUpdate() {
    // make sure the loading is done then if no user
    // then push them to login page
    const { userLoading, user } = this.props;
    // console.log(user);
    if (userLoading === false && !user) {
      this.props.history.push("/dashboard");
    }
  }

  componentDidMount() {
    const { userLoading, user } = this.props;
    console.log(user);
    if (userLoading === false && !user) {
      this.props.history.push("/dashboard");
    }
  }
  render() {
    const { user, userLoading, children } = this.props;
    return userLoading === false && user ? (
      <div>
        <ChangePassword child={children} />
      </div>
    ) : null;
  }
}

function mapStateToProps(state) {
  return {
    userLoading: state.loading.user,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps)(AuthenticatedComponent));
