import React, { Component } from "react";
import { connect } from "react-redux";
import {
  DetailInventoryView,
  getinventorystatus,
  getdevicelogstatus,
} from "../../actions/inventoryAction";
import { detailed_inventory_export } from "../../actions/exportAction";
import {
  getofficelistname,
  getagentlistname,
  searchdetailinventorylist,
  getretailers,
} from "../../actions/assetAction";
import _ from "lodash";
import {
  Button,
  Table,
  Tag,
  Input,
  Form,
  Select,
  Col,
  DatePicker,
  Drawer,
  List,
  message,
  Spin,
  Empty,
} from "antd";
import axiosInstance from "../../actions/AxiosInstance";
import moment from "moment";
import debounce from "lodash/debounce";

const { Option } = Select;

class DetailInventory extends Component {
  constructor(props) {
    super(props);
    this.lastFetchId = 0;
    this.fetchUser = debounce(this.fetchUser, 1000);
    this.state = {
      marketer: [],
      data: [],
      pagination: {},
      loading: true,
      loadingbtn: false,
      loadingEPbtn: false,
      retailer: [],
      selectedItems: [],
      selectedItemsAgent: [],
      inventorystatus: [],
      search_text: "",
      datefilter: [],
      startdate: "",
      enddate: "",
      startValue: null,
      endValue: null,
      endOpen: false,
      office: [],
      agent: [],
      detailform: {},
      statusval: [],
      visible: false,
      logdata: [],
      superrole: false,
      retailview: false,
    };
  }

  componentWillMount() {
    const masteruser = this.props.user.role_id.filter((word) => word === 1);
    if (masteruser.length !== 0) {
      this.setState({
        superrole: true,
      });
    }
    const urlString = this.props.location.urlpath;
    const urlParams = urlString
      ?.split("?")[1]
      ?.split("&")
      ?.map((v) => v?.split("="));
    const paramsobj = {};
    urlParams?.forEach((element) => {
      paramsobj[element[0]] = element[1];
    });
    this.setState({
      statusval: paramsobj["status"] ?? "",
    });

    // this.props
    //   .getofficelistname()
    //   .then((res) => {
    //     console.log(res.data);
    //     this.setState({
    //       office: res.data,
    //     });
    //   })
    //   .catch((err) => {});

    // this.props
    //   .getagentlistname()
    //   .then((res) => {
    //     console.log(res.data);
    //     this.setState({
    //       agent: res.data,
    //     });
    //   })
    //   .catch((err) => {});
    this.props
      .getinventorystatus()
      .then((res) => {
        console.log(res);
        this.setState({
          inventorystatus: res.data,
        });
      })
      .catch((err) => {});
    this.getdesignation({
      url: this.props.location.urlpath ?? "",
      results: 10,
      page: 1,
    });
    const Retailer =
      this.props?.user?.role_id?.filter((word) => word === 5) ?? [];
    if (Retailer?.length !== 0) {
      this.setState({
        retailview: true,
      });
    }
  }

  componentDidMount() {
    console.log(this.state.statusval);
    this.props.form.setFieldsValue({
      status: this.state.statusval === "" ? [] : parseInt(this.state.statusval),
    });
  }

  getdesignation(params = {}) {
    this.setState({
      loading: true,
    });
    this.props
      .DetailInventoryView(params)
      .then((res) => {
        console.log(res);
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.items.total;
        this.setState({
          data: res.data.items.data,
          loading: false,
          pagination,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }
  handleTableChange = (pagination, filter, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      sortField: sorter.order === undefined ? "" : sorter.field,
      sortOrder:
        sorter.order === "ascend"
          ? "ASC"
          : sorter.order === undefined
          ? ""
          : "DESC",
    });
    setTimeout(() => {
      this.searchDetailInventory();
    }, 100);
  };

  renderStatus() {
    return _.map(this.state.inventorystatus, (master, index) => {
      // console.log(master.id);
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderOffice() {
    return _.map(this.state.office, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderagent() {
    return _.map(this.state.agent, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }

  // onchangeRole = (selectedItems) => {
  //   this.setState({ selectedItems });
  //   console.log(this.state.selectedItems);
  // };

  // onchangeRoleAgent = (selectedItemsAgent) => {
  //   this.setState({ selectedItemsAgent });
  //   console.log(this.state.selectedItemsAgent);
  // };

  disabledStartDate = (startValue) => {
    // const { endValue } = this.state;
    // if (!startValue || !endValue) {
    //   return false;
    // }
    return startValue && startValue > moment().endOf("day");
  };

  disabledEndDate = (endValue) => {
    // const { startValue } = this.state;
    // if (!endValue || !startValue) {
    //   return false;
    // }
    return endValue && endValue > moment().endOf("day");
  };
  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };
  onStartChange = (value) => {
    this.onChange("startValue", value);
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };
  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };

  handleReset = () => {
    this.props.form.resetFields();
    this.setState({
      detailform: {},
      name: "",
      selectedItems: [],
      selectedItemsAgent: [],
      fdata: [],
      fAdata: [],
    });
    this.getdesignation({
      results: 10,
      page: 1,
    });
  };

  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          loading: true,
          loadingbtn: true,
        });
        console.log("Received values of form: ", values);
        var page = 1;
        this.setState({
          detailform: values,
        });
        console.log(page);
        this.props
          .searchdetailinventorylist(values, page, "", "")
          .then((res) => {
            const pagination = { ...this.state.pagination };
            pagination.total = res.data.items.total;
            pagination.current = res.data.items.current_page;
            this.setState({
              data: res.data.items.data,
              loading: false,
              loadingbtn: false,
              pagination,
            });
          })
          .catch((err) => {
            this.setState({
              loading: false,
              loadingbtn: false,
            });
          });
      }
    });
  };

  searchDetailInventory = (reset) => {
    this.setState({
      loading: true,
    });
    var page = reset ? 1 : this.state.pagination?.current ?? 1;
    var sort = this.state.sortField;
    var sort_by = this.state.sortOrder;

    this.props
      .searchdetailinventorylist(this.state.detailform, page, sort, sort_by)
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.items.total;
        pagination.current = res.data.items.current_page;
        this.setState({
          data: res.data.items.data,
          loading: false,
          pagination,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  };
  showDrawer = (record) => {
    this.props
      .getdevicelogstatus(record.id)
      .then((res) => {
        console.log(res);
        this.setState({
          visible: true,
          logdata: res.data.items,
        });
      })
      .catch((err) => {});
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
    this.props.form.resetFields();
  };
  getexportbtn = () => {
    this.setState({ loading: true, loadingEPbtn: true });
    console.log("query param", this.state.detailform);
    var sort = this.state.sortField;
    var sort_by = this.state.sortOrder;
    var formitem = this.state.detailform;

    this.props
      .detailed_inventory_export(formitem, sort, sort_by)
      .then((res) => {
        this.setState({ loading: false, loadingEPbtn: false });
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        this.setState({ loading: false, loadingEPbtn: false });
      });
  };

  fetchUser = (value) => {
    console.log("fetching user", value);
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    if (value.length > 1) {
      this.setState({ fdata: [], fetching: true });
      console.log(this);
      axiosInstance
        .get("/api/v1/reference/offices?term=" + value)
        .then((response) => {
          if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return;
          }
          console.log(response.data);
          const fdata = response.data.map((user) => ({
            text: user.name,
            value: user.id,
          }));
          console.log(this);
          this.setState({ fdata, fetching: false });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  fetchAgentUser = (value) => {
    console.log("Afetching user", value);
    if (value.length > 1) {
      this.setState({ fAdata: [], Afetching: true });
      console.log(this);
      axiosInstance
        .get("/api/v1/userlist_based_on_role/agent?term=" + value)
        .then((response) => {
          console.log(response.data);
          const fAdata = response.data.map((user) => ({
            text: `${user.first_name} ${user.last_name}`,
            value: user.id,
          }));
          console.log(this);
          this.setState({ fAdata, Afetching: false });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  // ======================================================

  render() {
    const { endOpen, fetching, fdata, fAdata, Afetching } = this.state;
    const { getFieldDecorator } = this.props.form;
    const columns = [
      {
        title: "Retailer",
        dataIndex: "retailer_name",
        key: "retailer_name",
        sorter: true,
      },
      {
        title: "IBO / Markets ",
        dataIndex: "ibo_name",
        key: "ibo_name",
        sorter: true,
      },
      {
        title: "Office ",
        dataIndex: "office_name",
        key: "office_name",
        sorter: true,
      },
      {
        title: "Enrollment Number",
        dataIndex: "enroll_id",
        key: "enroll_id",
      },
      {
        title: "ETC Code",
        dataIndex: "excess_code",
        key: "excess_code",
        sorter: true,
        // hidden: !retailview,
      },
      {
        title: "Agent Name",
        dataIndex: "agent_name",
        key: "agent_name",
        sorter: true,
      },

      {
        title: "Manufacturer",
        dataIndex: "make",
        key: "make",
        sorter: true,
      },
      {
        title: "Model",
        dataIndex: "model",
        key: "model",
        sorter: true,
      },
      {
        title: "Pallet",
        dataIndex: "pallet",
        key: "pallet",
        sorter: true,
      },
      {
        title: "Carton",
        dataIndex: "carton",
        key: "carton",
        sorter: true,
      },
      {
        title: "IMEI",
        dataIndex: "imei",
        key: "imei",
        sorter: true,
      },
      {
        title: "Device Type",
        dataIndex: "device_type",
        key: "device_type",
      },
      {
        title: "Status",
        dataIndex: "status_name",
        key: "status_name",
        render: (text, record) => (
          <span>
            <Tag color="orange">{record.status_name}</Tag>
          </span>
        ),
      },
      // {
      //   title: "Received Date",
      //   dataIndex: "received_date",
      //   key: "received_date",
      //   sorter: true,
      //   hidden: superrole === true ? false : true,
      // },
      {
        title: "Allocation Date",
        dataIndex: "allocation_date",
        key: "allocation_date",
        sorter: true,
      },
      {
        title: "Action",
        key: "action",
        width: 180,
        fixed: "right",
        render: (text, record) => (
          <span>
            <Button onClick={() => this.showDrawer(record)} type="primary">
              View Device Log
            </Button>
          </span>
        ),
      },
    ].filter((item) => !item.hidden);

    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            <div className="col-sm-4">
              <h4>
                Detailed Report{" "}
                {this.state.pagination.total &&
                  `(${this.state.pagination.total})`}
              </h4>
            </div>
            <div className="col-sm-8 text-right">
              {/* {this.rendercsvformat(data)} */}
              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingEPbtn}
                onClick={() => this.getexportbtn()}
              >
                <i className="uil uil-export" /> Export Report
              </Button>
            </div>
          </div>
          <Form
            className="ant-advanced-search-form"
            onSubmit={this.handleSearch}
          >
            <div className="row">
              <Col span={6}>
                <Form.Item>
                  <label for="exampleFormControlInput1">Select Office</label>
                  {getFieldDecorator(`office_id`, {
                    rules: [
                      {
                        required: false,
                        message: "Input something!",
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      labelInValue
                      placeholder="Type 2 characters to search..!"
                      notFoundContent={
                        fetching ? <Spin size="small" /> : <Empty />
                      }
                      filterOption={false}
                      onSearch={this.fetchUser}
                      defaultActiveFirstOption={false}
                    >
                      {fdata?.map((d) => (
                        <Option key={d.value}>{d.text}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item>
                  <label for="exampleFormControlInput1">Select Agent</label>
                  {getFieldDecorator(`agent`, {
                    rules: [
                      {
                        required: false,
                        message: "Input something!",
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      labelInValue
                      placeholder="Type 2 characters to search..!"
                      notFoundContent={
                        Afetching ? <Spin size="small" /> : <Empty />
                      }
                      filterOption={false}
                      onSearch={this.fetchAgentUser}
                      defaultActiveFirstOption={false}
                    >
                      {fAdata?.map((d) => (
                        <Option key={d.value}>{d.text}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item>
                  <label for="exampleFormControlInput1">Select Status</label>
                  {getFieldDecorator(`status`, {
                    rules: [
                      {
                        required: false,
                        message: "Input something!",
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select Option"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.renderStatus()}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item>
                  <label for="exampleFormControlInput1">Keyword</label>
                  {getFieldDecorator(`keyword`, {
                    rules: [
                      {
                        required: false,
                        message: "Input something!",
                      },
                    ],
                  })(
                    <Input
                      style={{ width: "100%" }}
                      placeholder="Type Keyword"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item>
                  <label for="exampleFormControlInput1">Start</label>
                  {getFieldDecorator(`date_range_start`, {
                    rules: [
                      {
                        required: false,
                        message: "Input something!",
                      },
                    ],
                  })(
                    <DatePicker
                      disabledDate={this.disabledStartDate}
                      format="MM-DD-YYYY"
                      placeholder="Select Date"
                      onChange={this.onStartChange}
                      onOpenChange={this.handleStartOpenChange}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item>
                  <label for="exampleFormControlInput1">End</label>
                  {getFieldDecorator(`date_range_end`, {
                    rules: [
                      {
                        required: false,
                        message: "Input something!",
                      },
                    ],
                  })(
                    <DatePicker
                      disabledDate={this.disabledEndDate}
                      format="MM-DD-YYYY"
                      placeholder="Select Date"
                      onChange={this.onEndChange}
                      open={endOpen}
                      onOpenChange={this.handleEndOpenChange}
                    />
                  )}
                </Form.Item>
              </Col>
            </div>
            <div className="row">
              <div className="col mt-6">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loadingbtn}
                >
                  Search
                </Button>
                <Button
                  type="default"
                  style={{ marginLeft: 8 }}
                  onClick={this.handleReset}
                >
                  Clear
                </Button>
              </div>
            </div>
          </Form>
          <div className="bg-white padd0 mt-4">
            <Table
              columns={columns}
              dataSource={this.state.data}
              scroll={{ x: true }}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              // size="small"
            />
          </div>
          <Drawer
            title={`Device Log`}
            width={520}
            onClose={this.onClose}
            visible={this.state.visible}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <List
              itemLayout="horizontal"
              dataSource={this.state.logdata}
              renderItem={(item) => (
                <List.Item>
                  <div className="row">
                    <div className="col-12">
                      <List.Item.Meta
                        title={`IMEI Number : ${item.imei}`}
                        description={item.short_description}
                      />
                    </div>
                    <div className="col-4 mt-2y">
                      <Tag>{item.created_at}</Tag>
                    </div>
                  </div>
                </List.Item>
              )}
            />
          </Drawer>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    data: state.data,
  };
}

const WrappedAdvancedSearchForm = Form.create({ name: "advanced_search" })(
  DetailInventory
);

export default connect(mapStateToProps, {
  DetailInventoryView,
  getinventorystatus,
  getofficelistname,
  getagentlistname,
  searchdetailinventorylist,
  getdevicelogstatus,
  detailed_inventory_export,
  getretailers,
})(WrappedAdvancedSearchForm);
