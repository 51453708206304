import React, { Component } from "react";
import IdleTimer from "react-idle-timer";
import { message } from "antd";
import { logout } from "../actions/userAction";
import { connect } from "react-redux";

class IdleAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTimedOut: false,
      settings: {
        LockTime: 60,
      },
    };
    // this.idleTimer = React.createRef();
    this.idleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
  }

  componentDidMount() {
    // this.props.getpasswordsettings().then((res) => {
    //   // console.log(res);
    //   this.setState({
    //     settings: res,
    //   });
    // });
  }

  handleOnAction(event) {
    // console.log('user did something', event)
  }

  handleOnActive(event) {
    console.log("user is active", event);
    console.log("time remaining", this.idleTimer.getRemainingTime());
  }

  handleOnIdle(event) {
    message.warning("Session Timed Out. Please Login to continue!");
    this.props.logout();
    console.log("user is idle", event);
    console.log("last active", this.idleTimer.getLastActiveTime());
  }

  render() {
    const { settings } = this.state;
    // console.log(settings.LockTime * 60 * 1000)
    return (
      <React.Fragment>
        {/* <Tag>{isTimedOut ? 'ACTIVE' : 'Time to logout'}</Tag> */}
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          timeout={1000 * 60 * settings.LockTime}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    userinfo: state.userinfo,
  };
}

export default connect(mapStateToProps, { logout })(IdleAuth);
