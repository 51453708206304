import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getmasterlist,
  searchmasterlist,
  getsortlistmaster,
} from "../../actions/assetAction";
import { master_export } from "../../actions/exportAction";
import _ from "lodash";
import {
  Button,
  Table,
  Tag,
  Drawer,
  Form,
  Row,
  Col,
  Select,
  Input,
  message,
} from "antd";
import { master_agent } from "../../actions/userListAction";

const { Option } = Select;

class Master extends Component {
  constructor(props) {
    super(props);

    this.state = {
      master: [],
      masterlist: [],
      pagination: {},
      loading: false,
      loadingbtn: false,
      loadingEPbtn: false,
      visible: false,
      selectedfieldname: [],
      selectedfilter: [],
      sort_list: [],
    };
  }

  componentWillMount() {
    this.getdesignation({
      results: 10,
      page: 1,
    });
    this.props
      .getsortlistmaster()
      .then((res) => {
        console.log(res.data);
        this.setState({
          sort_list: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          sort_list: [],
        });
      });
    this.props
      .master_agent()
      .then((res) => {
        console.log(res.data.items.data);
        this.setState({
          masterlist: res.data.items.data,
        });
      })
      .catch((err) => {});
  }

  getdesignation(params = {}) {
    this.setState({ loading: true });
    this.props.getmasterlist(params).then((res) => {
      console.log(res);
      const pagination = { ...this.state.pagination };
      pagination.total = res.data.items.total;
      pagination.current = res.data.items.current_page;
      this.setState({
        master: res.data.items.data,
        loading: false,
        pagination,
      });
    });
  }
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    var status = filters?.status ?? [];
    this.setState({
      pagination: pager,
      selectedstatus: status.toString(),
      sortField: sorter.order === undefined ? "" : sorter.field,
      sortOrder:
        sorter.order === "ascend"
          ? "ASC"
          : sorter.order === undefined
          ? ""
          : "DESC",
    });
    setTimeout(() => {
      this.searchEmployee();
    }, 100);
  };
  fetch = (params = {}) => {
    console.log("params:", params);
    this.getdesignation(params);
  };
  showDrawer = (res) => {
    console.log(res);
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  searchEmployee = (reset) => {
    this.setState({
      loading: true,
      loadingbtn: true,
    });
    var status = this.state.selectedstatus;
    var search_text = this.state.name;
    var sort = this.state.sortField;
    var sort_by = this.state.sortOrder;

    var page = reset ? 1 : this.state.pagination?.current ?? 1;
    this.setState({ loading: true });
    console.log(sort_by);
    this.props
      .searchmasterlist({
        status: status,
        search_text: search_text,
        page: page,
        sort,
        sort_by,
      })
      .then((res) => {
        console.log(res);
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.items.total;
        pagination.current = res.data.items.current_page;
        this.setState({
          master: res.data.items.data,
          loading: false,
          loadingbtn: false,
          pagination,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          loadingbtn: false,
        });
      });
  };

  clearSearch = () => {
    this.setState({
      status: undefined,
      name: "",
      selectedfieldname: [],
      selectedfilter: [],
    });
    this.getdesignation({
      results: 10,
      page: 1,
    });
  };
  getrefreshbtn = () => {
    this.clearSearch();
    this.getdesignation({
      results: 10,
      page: 1,
    });
  };
  // ======================================================
  renderMaster() {
    return _.map(this.state.masterlist, (master, index) => {
      console.log(`index `, index);
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  onchangefieldname = (selectedfieldname) => {
    this.setState({ selectedfieldname });
    console.log(this.state.selectedfieldname);
  };
  onchangefilter = (selectedfilter) => {
    this.setState({ selectedfilter });
    console.log(this.state.selectedfilter);
  };
  renderSorter() {
    return _.map(this.state.sort_list, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }

  getexportbtn = () => {
    var search_text = this.state.name;
    this.setState({
      loading: true,
      loadingEPbtn: true,
    });
    this.props
      .master_export({ search_text: search_text })
      .then((res) => {
        this.setState({
          loading: false,
          loadingEPbtn: false,
        });
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
          loadingEPbtn: false,
        });
      });
  };

  render() {
    const { master } = this.state;

    const columns = [
      {
        title: "Master",
        dataIndex: "name",
        key: "name",
        sorter: true,
      },
      {
        title: "User",
        dataIndex: "get_manager_info",
        key: "get_manager_info",

        render: (tags) => (
          <span>
            {tags.map((tag) => {
              return (
                <Tag color="volcano" key={tag}>
                  {tag.name}
                </Tag>
              );
            })}
          </span>
        ),
      },

      {
        title: "Created Date",
        dataIndex: "created_at",
        key: "created_at",
        sorter: true,
      },
      {
        title: "Status",
        key: "status",
        filters: [
          { text: "Active", value: 1 },
          { text: "In-Active", value: 0 },
        ],
        dataIndex: "status",
        render: (tag) => (
          <span>
            <Tag color={tag === 1 ? "green" : "orange"}>
              {tag ? "Active" : "In-Active"}
            </Tag>
          </span>
        ),
      },
      // {
      //   title: "Action",
      //   key: "action",
      //   width: 100,
      //   render: (text, record) => (
      //     <span>
      //       <Button type="link" onClick={() => this.showDrawer(record)}>
      //         Edit
      //       </Button>
      //     </span>
      //   ),
      // },
    ];
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            <div className="col-sm-4">
              <h4>
                Masters{" "}
                {this.state.pagination.total &&
                  `(${this.state.pagination.total})`}
              </h4>
            </div>
            <div className="col-sm-8 text-right">
              {/* {this.rendercsvformat(data)} */}
              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingEPbtn}
                onClick={() => this.getexportbtn()}
              >
                <i className="uil uil-export" /> Export Report
              </Button>
              <Button
                type="default"
                className="mr5"
                onClick={() => this.getrefreshbtn()}
              >
                <i className="uil uil-sync" /> Refresh
              </Button>
            </div>
          </div>
          <div className="row padd20 bg-grey">
            {/* <div className="col">
              <Select
                placeholder="Select Agent Status"
                style={{ width: "100%" }}
                value={
                  this.state.status !== undefined && this.state.status !== null
                    ? this.state.status === 1
                      ? "Active"
                      : "Inactive"
                    : undefined
                }
                onChange={(value) =>
                  this.setState({
                    status: value === "Active" ? 1 : 0,
                  })
                }
              >
                <Option value="Active">Active</Option>
                <Option value="Inactive">In-active</Option>
              </Select>
            </div> */}

            {/* <div class="col">
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select field name"
                value={this.state.selectedfieldname}
                onChange={this.onchangefieldname}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.renderSorter()}
              </Select>
            </div>
            <div class="col">
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select filter order"
                value={this.state.selectedfilter}
                onChange={this.onchangefilter}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="ASC">ASC</Option>
                <Option value="DESC">DESC</Option>
              </Select>
            </div> */}
            <div className="col-4">
              <label for="exampleFormControlInput1">Search By Keyword</label>
              <Input
                placeholder="Type Keyword"
                value={this.state.name}
                // ref={(node) => {
                //   this.searchInput = node;
                // }}
                onChange={(e) =>
                  this.setState({
                    name: e.target.value,
                  })
                }
                style={{ width: "100%", display: "block" }}
              />
            </div>
            <div className="col mt-6">
              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingbtn}
                onClick={() => this.searchEmployee("reset")}
              >
                Search
              </Button>
              <Button type="default" onClick={() => this.clearSearch()}>
                Clear
              </Button>
            </div>
          </div>
          <div className="bg-white padd0">
            <Table
              columns={columns}
              dataSource={master}
              scroll={{ x: true }}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              // size="small"
            />
          </div>
        </div>
        <Drawer
          title="Assign User"
          width={320}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form layout="vertical" hideRequiredMark>
            <Row>
              <Col span={24}>
                <Form.Item label="Manager">
                  {getFieldDecorator("manager", {
                    rules: [
                      { required: true, message: "Please select manager" },
                    ],
                  })(
                    <Select
                      // showSearch
                      style={{ width: "100%" }}
                      placeholder="Select manager"
                      onChange={this.genderOnChange}
                    >
                      {this.renderMaster()}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right",
            }}
          >
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={this.onClose} type="primary">
              Submit
            </Button>
          </div>
        </Drawer>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    userinfo: state.userinfo,
    allhubs: state.allhubs,
  };
}

const App = Form.create()(Master);

export default connect(mapStateToProps, {
  getmasterlist,
  master_agent,
  searchmasterlist,
  getsortlistmaster,
  master_export,
})(App);
