import { GET_INVENTORY_ORDER } from "../actionTypes";

const initialState = {
  inventoryorder: [],
};

export default function dataReducer(state = initialState, actions) {
  if (actions.type === GET_INVENTORY_ORDER) {
    return {
      ...state,
      inventoryorder: actions.payload,
    };
  }

  return state;
}
