import React, { Component } from "react";
import { connect } from "react-redux";
import { inventory_dashboard } from "../../actions/salesAction";
import _ from "lodash";
import { Spin, Typography } from "antd";
import { FunnelChart } from "react-funnel-pipeline";
import Chart from "react-apexcharts";
// import { Chart } from "react-google-charts";
import "react-funnel-pipeline/dist/index.css";
const { Text } = Typography;

//const rolepath = getApiPath();
class AgentDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onboarding: [],
      agent_label: [],
      agent_value: [],
      loading: true,
    };
  }

  componentWillMount() {
    this.getdesignation();
    //console.log(rolepath);
  }

  getdesignation() {
    this.props
      .inventory_dashboard()
      .then((res) => {
        this.setState({
          onboarding: res.data.items.onboarding_summary,
          agent_label: res.data.items.agent_label,
          agent_value: res.data.items.agent_value,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }

  // ======================================================

  render() {
    const options = {
      labels: this.state.agent_label,
      legend: {
        position: "top",
      },
      chart: {
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const indexid = config.w.config.labels[config.dataPointIndex];
            if (indexid === "Dynamic Agent") {
              this.props.history.push({
                // no need
                pathname: "/agentsummaryreport",
                state: {
                  urlpath:
                    "/api/v1/agent_summary_report?redirect_key=active_agent",
                },
              });
            }
            if (indexid === "Passive Agent") {
              this.props.history.push({
                // no need
                pathname: "/agentsummaryreport",
                state: {
                  urlpath:
                    "/api/v1/agent_summary_report?redirect_key=passive_agent",
                },
              });
            }
            if (indexid === "Dormant Agent") {
              this.props.history.push({
                // no need
                pathname: "/agentsummaryreport",
                state: {
                  urlpath:
                    "/api/v1/agent_summary_report?redirect_key=inactive_agent",
                },
              });
            }
            if (indexid === "Onboarded Agent") {
              this.props.history.push({
                // no need
                pathname: "/agentsummaryreport",
                state: {
                  urlpath:
                    "/api/v1/agent_summary_report?redirect_key=onboarded",
                },
              });
            }
          },
        },
      },
    };
    const series = this.state.agent_value;

    return (
      <div>
        <Spin spinning={this.state.loading}>
          <div className="container-fluid">
            <div className="row padd20">
              <div className="col-sm-4">
                <h4>Dashboard</h4>
              </div>
            </div>

            <div className="padd0">
              <div className="container-fluid">
                <div className="row">
                  <div
                    className="col-6"
                    style={{ backgroundColor: "white", padding: "20px" }}
                  >
                    <h4 class="card-title">
                      <strong>Onboarding Summary </strong>
                    </h4>

                    <hr className="mb-5"></hr>
                    <FunnelChart
                      data={this.state.onboarding}
                      getToolTip={(row) => {
                        return row.name;
                      }}
                      onRowClick={(row) => {
                        console.log(`row`, row);
                        console.log(
                          `this.state.onboarding`,
                          this.state.onboarding
                        );
                        this.props.history.push({
                          pathname: `/OnboardingSummaryReport`,
                          state: {
                            urlpath: `/api/v1/onboarding_summary_report?redirect_key=${
                              row.name === "Invite Sent"
                                ? "1"
                                : row.name === "Profile"
                                ? "2"
                                : row.name === "Training"
                                ? "9"
                                : row.name === "BGC"
                                ? "5"
                                : row.name === "RAD ID"
                                ? "11"
                                : row.name === "Telgoo ID"
                                ? "12"
                                : row.name === "Denied"
                                ? "22"
                                : row.name === "Expired"
                                ? "20"
                                : ""
                            }`,
                          },
                        });
                      }}
                    />
                  </div>
                  <div
                    className="col-6"
                    style={{ backgroundColor: "white", padding: "20px" }}
                  >
                    <h4 class="card-title">
                      <strong>Agents Summary </strong>
                    </h4>

                    <hr className="mb-5"></hr>
                    <div id="chart" style={{ cursor: "pointer" }}>
                      <Chart
                        options={options}
                        series={series}
                        type="pie"
                        width="500"
                      />
                    </div>
                    <div className="text-center">
                      <Text code>Dynamic Agent (1-14 Days)</Text>

                      <Text code>Passive Agent (15-30 Days)</Text>

                      <Text code>Dormant Agent (31-60 Days)</Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    ibomarket: state.data,
    data: state.data,
  };
}

export default connect(mapStateToProps, {
  inventory_dashboard,
})(AgentDashboard);
