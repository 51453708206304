import {
  GET_DISTRIBUTOR,
  GET_MASTER,
  GET_RETAILER,
  GET_IBO_MARKET,
  GET_OFFICE,
  GET_DOCUMENTS
} from "../actionTypes";

const initialState = {
  distributor: [],
  master: [],
  retailer: [],
  ibomarket: [],
  Office: [],
  documents: [],
};

export default function dataReducer(state = initialState, actions) {
  if (actions.type === GET_DISTRIBUTOR) {
    return {
      ...state,
      distributor: actions.payload,
    };
  }
  if (actions.type === GET_MASTER) {
    return {
      ...state,
      master: actions.payload,
    };
  }
  if (actions.type === GET_RETAILER) {
    return {
      ...state,
      retailer: actions.payload,
    };
  }
  if (actions.type === GET_IBO_MARKET) {
    return {
      ...state,
      ibomarket: actions.payload,
    };
  }
  if (actions.type === GET_OFFICE) {
    return {
      ...state,
      Office: actions.payload,
    };
  }

  if (actions.type === GET_DOCUMENTS) {
    const documents = [...state.documents];
    return {
      ...state,
      documents,
    };
  }

  return state;
}
