import React, { Component } from "react";
// FAQ DATA
import FAQ_data from "./faqs.json";
// ANTD
import { Input } from "antd";

const { Search } = Input;

export default class FAQ extends Component {
  constructor(props) {
    super(props);
    // State
    this.state = {
      faqs: FAQ_data,
      search_text: "",
    };
  }

  // Search module
  handleSearch = (e) => {
    var value = e.target.value;
    this.setState({ search_text: value });
    console.log(value);
    const reg = new RegExp(value, "gi");
    const filteredFaq = FAQ_data.filter((record) => {
      const questionMatch = reg.test(record.question);
      const answerMatch = reg.test(record.answer);
      return questionMatch || answerMatch;
    });
    this.setState({ faqs: value ? filteredFaq : FAQ_data });
  };

  // Expand FAQ based on ID
  toggleFAQ = (index) => {
    this.setState({
      faqs: this.state.faqs.map((faq, i) => {
        if (i === index) {
          // Toggle the 'isCollapsed' property for the clicked FAQ
          return { ...faq, isCollapsed: !faq.isCollapsed };
        } else {
          // Close other FAQs
          return { ...faq, isCollapsed: true };
        }
      }),
    });
  };

  render() {
    const { faqs, search_text } = this.state;
    return (
      <div>
        <section className="section mb-5 pt-4 pb-4 bg-dark support_banner_faq">
          <div className="container">
            <div className="row justify-content-center">
              {/* <div className="col-lg-8 col-xl-7 text-center desc">
                <div
                  className=" mb-1 text-white"
                  style={{ fontSize: "1.5rem" }}
                >
                  Frequently Asked Questions
                </div>
              </div> */}
              <Search
                placeholder="What we can help you with?"
                value={search_text}
                onChange={(value) => this.handleSearch(value)}
                style={{ height: 42, fontSize: 18 }}
                className="col-lg-8 col-xl-7 text-center"
                size="large"
              />
            </div>
          </div>
        </section>
        <div className="container faqs">
          {/* Map Questions and Answers */}
          {faqs.map((faq, index) => (
            <div
              className={"faq " + (faq.isCollapsed === false ? "open" : "")}
              key={index}
              onClick={() => this.toggleFAQ(index)}
            >
              <section
                className="font-weight-bold faq-question"
                style={{ fontSize: "1.4rem" }}
              >
                <i className="fa fa-file-text-o"></i>&nbsp;
                {faq.question}
              </section>
              {faq.question === "How do I login to the WiES Portal?" ? (
                <section
                  className="mt-2 faq-answer"
                  style={{ fontSize: "1.4rem" }}
                >
                  <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                </section>
              ) : (
                <section
                  className="mt-2 faq-answer"
                  style={{ fontSize: "1.4rem" }}
                >
                  {faq.answer}
                </section>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
