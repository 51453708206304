import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Input, message, Button } from "antd";
import { updatepassword, logout } from "../../actions/userAction";
import Loginimg from "../../styles/img/forgotpassword.svg";
import Logo from "../../styles/img/wies-logo.svg";
import _ from "lodash";
import { Link } from "react-router-dom";

class changepassword extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      loading: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    const { user } = this.props;
    console.log(user);
    if (!user) {
      this.props.history.push("/");
    }
  }
  componentWillReceiveProps(prevProps, nextProps) {
    if (prevProps.user !== this.props.user) {
      console.log(this.props.user, prevProps.user);
      this.props.history.push("/");
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values, this.state.userinfo);
        this.setState({ loading: true });
        this.props
          .updatepassword(values)
          .then((res) => {
            console.log(res);
            this.props.form.resetFields();
            message.success("Password updated successfully!");
            this.setState({ loading: false, IsPwdUpdated: true, email: "" });
            this.props.logout();
          })
          .catch((err) => {
            console.log(err);
            message.error(
              "Password must satisfy all the mentioned conditions!"
            );
            this.setState({ loading: false });
          });
      }
    });
  };

  render() {
    const { loading } = this.state;
    const { getFieldDecorator } = this.props.form;
    var mailformat =
      // eslint-disable-next-line no-control-regex
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    return (
      <div className="cover-full">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7 hidden-sm hidden-xs padd0">
              <div className="img-flex bg-red">
                <img src={Loginimg} className="loginimg" alt="WiESSystem" />
              </div>
            </div>
            <div className="col-md-5 col-sm-12 col-xs-12 bg-white">
              <div className="panel-flex">
                <div className="panel panel-default loginpanel">
                  <div className="panel-body">
                    <img
                      src={Logo}
                      className="logologin mb20"
                      alt="WiESSystem"
                    />
                    <h4 className="text-left">Change Password</h4>
                    {/* <p>
                      Enter your email linked with account to send password
                      reset link
                    </p> */}
                    <Form onSubmit={this.handleSubmit} className="">
                      <Form.Item label="Old Password">
                        {getFieldDecorator("oldpassword", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter old password",
                            },
                          ],
                        })(
                          <Input.Password
                            placeholder="Enter Password"
                            minLength="6"
                            autoComplete="new-password"
                          />
                        )}
                      </Form.Item>
                      <Form.Item label="New Password">
                        {getFieldDecorator("newpassword", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter new password",
                            },
                          ],
                        })(
                          <Input.Password
                            placeholder="Enter Password"
                            minLength="6"
                            autoComplete="new-password"
                          />
                        )}
                      </Form.Item>
                      <Form.Item>
                        <Button
                          htmlType="submit"
                          type="primary"
                          loading={this.state.iconLoading}
                          block
                          className="mb20"
                        >
                          Change Password
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user };
}
const WrappedForm = Form.create()(changepassword);

export default connect(mapStateToProps, { updatepassword, logout })(
  WrappedForm
);
