import { GET_RMA_PENDING, GET_RMA_REPORT } from "../actionTypes";

const initialState = {
  rmapending: [],
  rmareport: [],
};

export default function dataReducer(state = initialState, actions) {
  if (actions.type === GET_RMA_PENDING) {
    return {
      ...state,
      rmapending: actions.payload,
    };
  }
  if (actions.type === GET_RMA_REPORT) {
    return {
      ...state,
      rmareport: actions.payload,
    };
  }
  return state;
}
