/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import FreshChat from "react-freshchat";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Tag } from "antd";
import { get_app_version_info } from "../actions/userAction";
import phoneImg from "../styles/img/help_call.png";
import emailImg from "../styles/img/email_us.png";
import trainingImg from "../styles/img/training_documents.png";
import trainingVid from "../styles/img/training_videos.png";
import faq from "../styles/img/FAQ.png";

class Support extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      loading: false,
      appversion: "",
    };
  }
  componentWillMount() {
    this.props
      .get_app_version_info()
      .then((res) => {
        console.log(res.data[0]);
        this.setState({ appversion: res.data[0] });
      })
      .catch((err) => {});
  }
  render() {
    return (
      <div>
        <section className="section mb-5 pt-5 pb-5 bg-dark support_banner">
          <div className="container ">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-xl-7 text-center desc">
                <h2 className="h1 mb-3 text-white">We are here to help!</h2>
                <p className="mx-lg-8"></p>
              </div>
            </div>
          </div>
        </section>
        <section className="section pt-0">
          {/*  Call us */}
          <div className="container">
            <div className="row gy-4 justify-content-center">
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="card align-items-center animate_card">
                  <a
                    onClick={() => this.handledocument(false)}
                    href="tel:+14322495151"
                    className=" text-reset"
                    style={{ color: "white" }}
                  >
                    <div className="card-body text-center">
                      <div className="icon-lg bg-none rounded-3 text-white pt-3">
                        <img
                          src={phoneImg}
                          alt="call_us"
                          style={{ width: 80, height: 90 }}
                        />
                      </div>
                      <div className="ps-3 col">
                        <h5 className="h6 mb-2 stretched-link">
                          Call Us : 432-249-5151
                        </h5>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3">
                {/* Email */}
                <div className="card animate_card">
                  <a
                    className=" text-reset"
                    href="mailto:support@wiessolution.com"
                    alt=""
                  >
                    <div className="card-body text-center">
                      <div className="icon-lg bg-none rounded-3 text-white pt-3">
                        <img
                          src={emailImg}
                          alt="email_us"
                          style={{ width: 80, height: 90 }}
                        />
                      </div>
                      <div className="ps-3 col">
                        <h5 className="h6 mb-2 stretched-link">Email Us</h5>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div className="col-sm-6 col-md-6 col-lg-3">
                {/* Support Document */}
                <div className="card animate_card">
                  <div className="card-body text-center">
                    <Link
                      to="/trainingdocs"
                      rel="noreferrer"
                      className="stretched-link text-reset"
                    >
                      <div className="icon-lg bg-none rounded-3 text-white pt-3">
                        <img
                          src={trainingImg}
                          alt="training_document"
                          style={{ width: 100, height: 90 }}
                        />
                      </div>
                      <div className="ps-3 col">
                        <h5 className="h6 mb-2 ">Training Documents</h5>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              {/* Training Videos*/}
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="card animate_card">
                  <div className="card-body text-center">
                    <Link
                      to="/trainingvideos"
                      rel="noreferrer"
                      className="stretched-link text-reset"
                    >
                      <div className="icon-lg bg-none rounded-3 text-white pt-3">
                        <img
                          src={trainingVid}
                          alt="training_document"
                          style={{ width: 100, height: 80 }}
                        />
                      </div>
                      <div className="ps-3 col">
                        <h5 className="h6 mb-2 ">Training Videos</h5>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              {/* FAQs */}
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="card animate_card">
                  <div className="card-body text-center">
                    <Link
                      to="/faq"
                      rel="noreferrer"
                      className="stretched-link text-reset"
                    >
                      <div className="icon-lg bg-none rounded-3 text-white pt-3">
                        <img
                          src={faq}
                          alt="faq"
                          style={{ width: 100, height: 100 }}
                        />
                      </div>
                      <div className="ps-3 col">
                        <h5 className="h6 mb-2 ">FAQ's</h5>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center">
            <Tag color="green">
              App Version - {this.state.appversion.application_version}
            </Tag>
            <Tag color="green">
              API Version - {this.state.appversion.api_version}
            </Tag>
          </div>
        </section>
        <div style={{ zIndex: 9999 }}>
          <FreshChat
            token="5f89464e-8221-4b4f-b50d-26c1311f3bfd"
            host="https://infinitelinkedsolutions-team-8427c39a44bdd8816882133.freshchat.com"
            email="lavanyarao@infinitelinked.com"
            first_name="Lavanya"
            open="true"
            onInit={(widget) => {
              widget.user.setProperties({
                email: this.props?.user?.name,
                first_name: this.props?.user?.name,
                // last_name: "user.lastName",
                // phone: "user.phoneNumber",
              });
            }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user };
}

export default connect(mapStateToProps, { get_app_version_info })(Support);
