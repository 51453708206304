import React, { Component } from "react";
import { connect } from "react-redux";
// with withRouter You can get access to the history object’s properties
import { withRouter } from "react-router-dom";
import { logout } from "../../actions/userAction";
import { Form, Input, message, Button } from "antd";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      loading: false,
      email: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate() {
    // make sure the loading is done then if no user
    // then push them to login page
    const { userLoading, user } = this.props;
    // console.log(user);
    if (userLoading === false && !user) {
      this.props.history.push("/dashboard");
    }
  }

  componentWillMount() {
    const { userLoading, user } = this.props;
    // console.log(user, userinfo);
    this.setState({
      IsPwdUpdated: true,
    });

    if (userLoading === false && !user) {
      this.props.history.push("/");
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values, this.state.userinfo);
        this.setState({ loading: true });
        this.props
          .updatePassword(values.password, this.state.userinfo)
          .then((res) => {
            // console.log(res);
            this.props.form.resetFields();
            message.success("Password updated successfully!");
            this.setState({ loading: false, IsPwdUpdated: true, email: "" });
          })
          .catch((err) => {
            console.log(err);
            message.error(err.message);
            this.setState({ loading: false });
          });
      }
    });
  };

  render() {
    const { child } = this.props;
    const { IsPwdUpdated, loading } = this.state;
    const { getFieldDecorator } = this.props.form;

    return IsPwdUpdated === true ? (
      <div>{child}</div>
    ) : (
      <div className="container-fluid padd15">
        <div className="resetpasswordwrapper bg-white padd15">
          <div className="accounttitle ">
            <h4>Change Password</h4>
            <p>Please update your password to continue..</p>
          </div>
          <div className="editProfileDiv padd15">
            <Form onSubmit={this.handleSubmit} className="row">
              <Form.Item label="New Password">
                {getFieldDecorator("password", {
                  rules: [
                    {
                      required: true,
                      message: "Please input Password!",
                    },
                  ],
                })(
                  <Input.Password
                    placeholder="Enter Password"
                    minLength="6"
                    autoComplete="new-password"
                  />
                )}
              </Form.Item>
              <div className="col-12 text-left">
                <Button
                  type="primary"
                  loading={loading}
                  className="btn mb-2 btn-danger btn-block"
                  htmlType="submit"
                >
                  Update password
                </Button>
                <br />
                <br />
                <Button onClick={() => this.props.logout()}>Logout</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userLoading: state.loading.user,
    user: state.user,
    userinfo: state.userinfo,
  };
}
const WrappedForm = Form.create()(ChangePassword);

export default withRouter(connect(mapStateToProps, {})(WrappedForm));
