import React, { Component } from "react";
import { connect } from "react-redux";
import {
  sales_dashboard_data,
  sales_dashboard_agents,
  sales_dashboard_office,
  sales_dashboard_orders,
} from "../../actions/salesAction";
import _ from "lodash";
import { Form, Select, Spin, Radio, Icon } from "antd";
import Chart from "react-apexcharts";
import { master_agent } from "../../actions/userListAction";
import { Line } from "peity-react";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
const { Option } = Select;

class RetailDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      master: [],
      dashdata: "",
      pagination: {},
      loading: true,
      loading1: true,
      visible: false,
      top_agent: [],
      orders: [],
      top_offices: [],
      top_agent_label: [],
      top_offices_label: [],
      value: 30,
      value1: 30,
    };
  }

  componentWillMount() {
    console.log(
      moment()
        .isoWeekday(1)
        .startOf("isoWeek")
        .tz("America/New_York")
        .format("MM-DD-YYYY")
    );

    this.setState({
      startweek: moment()
        .isoWeekday(1)
        .startOf("isoWeek")
        .tz("America/New_York")
        .format("MM-DD-YYYY"),
      startmonth: moment()
        .startOf("month")
        .tz("America/New_York")
        .format("MM-DD-YYYY"),
      endmonth: moment()
        .endOf("month")
        .tz("America/New_York")
        .format("MM-DD-YYYY"),
      todaydate: moment().tz("America/New_York").format("MM-DD-YYYY"),
      startdate: moment()
        .subtract(1, "days")
        .tz("America/New_York")
        .format("MM-DD-YYYY"),
      end7date: moment()
        .subtract(7, "days")
        .tz("America/New_York")
        .format("MM-DD-YYYY"),
      end30date: moment()
        .subtract(30, "days")
        .tz("America/New_York")
        .format("MM-DD-YYYY"),
    });
    this.props
      .sales_dashboard_data()
      .then((res) => {
        console.log(res.data.items.top_agents);
        console.log(res.data.items);
        this.setState({
          dashdata: res.data.items,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
    // this.props
    //   .sales_dashboard_orders()
    //   .then((res) => {
    //     console.log(res.data.items);
    //     this.setState({
    //       orders: res.data.items.orders_count,
    //     });
    //   })
    //   .catch((err) => {
    //     this.setState({
    //       loading: false,
    //     });
    //   });

    this.props
      .sales_dashboard_agents(this.state.value1)
      .then((res) => {
        console.log(res.data.items.top_agents);
        this.setState({
          top_agent: res.data.items.top_agents_values,
          top_agent_label: res.data.items.top_agents_labels,
        });
      })
      .catch((err) => {
        this.setState({
          loading1: false,
        });
      });

    this.props
      .sales_dashboard_office(this.state.value)
      .then((res) => {
        console.log(res.data.items.top_agents);
        this.setState({
          top_offices: res.data.items.top_offices_values,
          top_offices_label: res.data.items.top_offices_labels,
          loading1: false,
        });
      })
      .catch((err) => {
        this.setState({
          loading1: false,
        });
      });
  }

  percentage = (partialValue, totalValue) => {
    console.log(partialValue, totalValue);
    return (100 * partialValue) / totalValue + "%";
  };
  onChange = (e) => {
    console.log("Top 10 Offices", e.target.value);
    this.setState({
      value: e.target.value,
      loading1: true,
    });
    this.props
      .sales_dashboard_office(e.target.value)
      .then((res) => {
        console.log(res.data.items.top_agents);
        this.setState({
          top_offices: res.data.items.top_offices_values,
          top_offices_label: res.data.items.top_offices_labels,
          loading1: false,
        });
      })
      .catch((err) => {
        this.setState({
          loading1: false,
        });
      });
  };
  onChange1 = (e) => {
    console.log(" Top 10 Agents", e.target.value);
    this.setState({
      value1: e.target.value,
      loading1: true,
    });
    this.props
      .sales_dashboard_agents(e.target.value)
      .then((res) => {
        console.log(res.data.items.top_agents);
        this.setState({
          top_agent: res.data.items.top_agents_values,
          top_agent_label: res.data.items.top_agents_labels,
          loading1: false,
        });
      })
      .catch((err) => {
        this.setState({
          loading1: false,
        });
      });
  };
  // ======================================================

  render() {
    const {
      dashdata,
      top_offices,
      top_agent,
      top_agent_label,
      top_offices_label,
      orders,
    } = this.state;
    console.log(dashdata);
    const series = [
      {
        data: top_offices,
      },
    ];
    const options = {
      chart: {
        type: "bar",
        height: 50,
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: false,
      },
      legend: {
        show: false,
      },
      plotOptions: {
        bar: {
          barHeight: "80%",
          distributed: true,
          horizontal: true,
          borderRadius: 4,
        },
      },
      colors: [
        "#33b2df",
        "#546E7A",
        "#d4526e",
        "#13d8aa",
        "#A5978B",
        "#2b908f",
        "#f9a3a4",
        "#90ee7e",
        "#f48024",
        "#69d2e7",
      ],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: top_offices_label,
      },
      yaxis: {
        labels: {
          show: true,
        },
      },
      tooltip: {
        theme: "dark",
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return "";
            },
          },
        },
      },
    };
    const series1 = [
      {
        data: top_agent,
      },
    ];
    const options1 = {
      chart: {
        type: "bar",
        height: 50,
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: false,
      },
      legend: {
        show: false,
      },
      plotOptions: {
        bar: {
          barHeight: "80%",
          distributed: true,
          horizontal: true,
          borderRadius: 4,
        },
      },
      colors: [
        "#33b2df",
        "#546E7A",
        "#d4526e",
        "#13d8aa",
        "#A5978B",
        "#2b908f",
        "#f9a3a4",
        "#90ee7e",
        "#f48024",
        "#69d2e7",
      ],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: top_agent_label,
      },
      yaxis: {
        labels: {
          show: true,
        },
      },
      tooltip: {
        theme: "dark",
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return "";
            },
          },
        },
      },
    };
    return (
      <div>
        <div className="container-fluid mt15">
          <div className="row">
            <div className="col-md-6">
              <h4 className="mb-4">Dashboard</h4>
            </div>
            <div className="col-md-6">
              <h6 className="mb-4 text-right" style={{ fontSize: 13 }}>
                <Icon type="clock-circle" /> Last Updated at:{" "}
                {dashdata?.activation_count?.last_run_date ?? ""}
              </h6>
            </div>
          </div>

          <Spin spinning={this.state.loading}>
            <div className="row">
              <div className="col">
                <Link
                  to={{
                    pathname: "/salessummaryreport",
                    urlpath: `/api/v1/detailed_sales_report?date_range_start=${this.state.todaydate}&date_range_end=${this.state.todaydate}`,
                  }}
                >
                  <div className="p-2 bg-white border mb-2">
                    <p className="mb-1 text-bold">Daily Activations</p>
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <h4 className="mb-0">
                        {dashdata?.activation_count?.daily ?? 0}
                        {/* {new Intl.NumberFormat().format(
                        dashdata?.activation_count?.daily ?? 0
                      )} */}
                      </h4>
                      <Line height={14} values={[5, 10]} />
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col">
                <Link
                  to={{
                    pathname: "/salessummaryreport",
                    urlpath: `/api/v1/detailed_sales_report?date_range_start=${this.state.startweek}&date_range_end=${this.state.todaydate}`,
                  }}
                >
                  <div className="p-2 bg-white border mb-2">
                    <p className="mb-1 text-bold">WTD Activations</p>
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <h4 className="mb-0">
                        {dashdata?.activation_count?.WTD ?? 0}
                        {/* {new Intl.NumberFormat().format(
                        dashdata?.activation_count?.WTD ?? 0
                      )} */}
                      </h4>
                      <Line height={14} values={[5, 3, 9, 6, 5, 9, 15]} />
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col">
                <Link
                  to={{
                    pathname: "/salessummaryreport",
                    urlpath: `/api/v1/detailed_sales_report?date_range_start=${this.state.startmonth}&date_range_end=${this.state.endmonth}`,
                  }}
                >
                  <div className="p-2 bg-white border mb-2">
                    <p className="mb-1 text-bold">MTD Activations</p>
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <h4 className="mb-0">
                        {dashdata?.activation_count?.MTD ?? 0}
                        {/* {new Intl.NumberFormat().format(
                        dashdata?.activation_count?.MTD ?? 0
                      )} */}
                      </h4>
                      <Line
                        height={14}
                        values={[5, 3, 9, 6, 5, 9, 7, 3, 5, 2]}
                      />
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col">
                <Link
                  to={{
                    pathname: "/salessummaryreport",
                    urlpath: `/api/v1/detailed_sales_report?date_range_start=${this.state.end7date}&date_range_end=${this.state.startdate}`,
                  }}
                >
                  <div className="p-2 bg-white border mb-2">
                    <p className="mb-1 text-bold">7 Days Activations</p>
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <h4 className="mb-0">
                        {dashdata?.activation_count?.Last_7_Days ?? 0}
                        {/* {new Intl.NumberFormat().format(
                        dashdata?.activation_count?.Last_7_Days ?? 0
                      )} */}
                      </h4>
                      <Line
                        height={14}
                        values={[9, 6, 5, 9, 5, 3, 2]}
                        fill={"#fff"}
                        stroke={"#ff000d"}
                      />
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col">
                <Link
                  to={{
                    pathname: "/salessummaryreport",
                    urlpath: `/api/v1/detailed_sales_report?date_range_start=${this.state.end30date}&date_range_end=${this.state.startdate}`,
                  }}
                >
                  <div className="p-2 bg-white border mb-2">
                    <p className="mb-1 text-bold">30 Days Activations</p>
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <h4 className="mb-0">
                        {dashdata?.activation_count?.Last_30_Days ?? 0}
                        {/* {new Intl.NumberFormat().format(
                        dashdata?.activation_count?.Last_30_Days ?? 0
                      )} */}
                      </h4>
                      <Line
                        height={14}
                        values={[9, 6, 5, 9, 5, 3, 2]}
                        fill={"#fff"}
                        stroke={"#ff000d"}
                      />
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Spin>
          {/* <div className="row mt-4 align-items-stretch"> */}
          {/* <div className="c-dashboardInfo col-lg-4 col-md-6">
                <div className="border">
                  <div
                    style={{ height: 35 }}
                    className="dash-orders bg-primary1 p-2"
                  >
                    <h4 className="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">
                      Today's Orders
                    </h4>
                  </div>
                  <div className="wrap">
                    <span className="hind-font caption-12 c-dashboardInfo__count">
                      {orders?.todays_orders ?? 0}
                    </span>
                  </div>
                </div>
              </div> */}

          {/* <div className="c-dashboardInfo col-lg-4 col-md-6">
                <div className="border">
                  <div
                    style={{ height: 35 }}
                    className="dash-pending-orders bg-primary1 p-2"
                  >
                    <h4 className="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">
                      7 Days Pending Orders
                    </h4>
                  </div>
                  <div className="wrap">
                    <span className="hind-font caption-12 c-dashboardInfo__count">
                      {orders?.todays_orders ?? 0}
                    </span>
                  </div>
                </div> */}

          {/* <div className="wrap">
                  <h4 className="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">
                    7 Days Pending Order
                  </h4>
                  <span className="hind-font caption-12 c-dashboardInfo__count">
                    {orders?.last_week_pending_orders ?? 0}
                  </span>
                </div> */}

          {/* </div> */}

          {/* <div className="c-dashboardInfo col-lg-4 col-md-6"> */}
          {/* <div className="border">
                  <div
                    style={{ height: 35 }}
                    className="dash-Previous-orders bg-primary1 p-2"
                  >
                    <h4 className="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">
                      Previous Pending Orders
                    </h4>
                  </div>
                  <div className="wrap">
                    <span className="hind-font caption-12 c-dashboardInfo__count">
                      {orders?.todays_orders ?? 0}
                    </span>
                  </div>
                </div> */}

          {/* <div className="wrap">
                  <h4 className="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">
                    Previous Pending Orders
                  </h4>
                  <span className="hind-font caption-12 c-dashboardInfo__count">
                    {orders?.previous_pending_orders ?? 0}
                  </span>
                </div> */}
          {/* </div> */}

          {/* <div className="c-dashboardInfo col-lg-3 col-md-6">
                <div className="wrap">
                  <h4 className="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">
                    Rental return
                  </h4>
                  <span className="hind-font caption-12 c-dashboardInfo__count">
                    6,40%
                  </span>
                </div>
              </div> */}
          {/* </div> */}
          <Spin spinning={this.state.loading1}>
            <div className="row mt-2">
              <div className="col-sm-12 col-md-6">
                <div className="border">
                  <div style={{ height: 35 }} className="bg-primary1 p-2">
                    <h4 className="mb-0 text-white float-left">
                      Top 10 Offices
                    </h4>
                    <span className="float-right">
                      <Radio.Group
                        onChange={this.onChange}
                        value={this.state.value}
                      >
                        <Radio value={7}>Last 7 Days</Radio>
                        <Radio value={30}>Last 30 Days</Radio>
                      </Radio.Group>
                    </span>
                  </div>
                  <div className="p-2 bg-white">
                    <div id="chart">
                      <Chart
                        options={options}
                        series={series}
                        type="bar"
                        height={350}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="border">
                  <div style={{ height: 35 }} className="bg-primary1 p-2">
                    <h4 className="mb-0 text-white float-left">
                      Top 10 Agents
                    </h4>
                    <span className="float-right">
                      <Radio.Group
                        onChange={this.onChange1}
                        value={this.state.value1}
                      >
                        <Radio value={7}>Last 7 Days</Radio>
                        <Radio value={30}>Last 30 Days</Radio>
                      </Radio.Group>
                    </span>
                  </div>
                  <div className="p-2 bg-white">
                    <Chart
                      options={options1}
                      series={series1}
                      type="bar"
                      height={350}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
  };
}

const App = Form.create()(RetailDashboard);

export default connect(mapStateToProps, {
  sales_dashboard_data,
  sales_dashboard_agents,
  sales_dashboard_office,
  sales_dashboard_orders,
})(App);
