import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Select,
  Button,
  Input,
  Form,
  message,
  Icon,
  Spin,
  Radio,
  Empty,
} from "antd";
import _ from "lodash";
import { getofficelistname, sendinviteuser } from "../../actions/assetAction";
import axiosInstance from "../../actions/AxiosInstance";
import debounce from "lodash/debounce";
const { Option } = Select;
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

class AddMarkter extends Component {
  constructor(props) {
    super(props);
    this.lastFetchId = 0;
    this.fetchUser = debounce(this.fetchUser, 1000);
    // state
    this.state = {
      loading: false,
      loadingbtn: false,
      spinloader: false,
      office: [],
      resvisbible: false,
      optionvisbile: "",
      singleoffice: [],
      fdata: [],
      selectedItems: [],
      searchOffData: [],
    };
  }

  // componentWillMount() {
  //   this.props
  //     .getofficelistname()
  //     .then((res) => {
  //       console.log(res.data);
  //       this.setState({
  //         spinloader: false,
  //         office: res.data,
  //       });
  //     })
  //     .catch((err) => {
  //       this.setState({
  //         spinloader: false,
  //       });
  //     });
  // }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log(values);
        var obj_arr = {
          office_id: values.office_id.key,
          email: values.email,
          first_name: values.first_name,
          //middle_name: values?.middle_name ?? "",
          middle_name: values.middle_name,
          last_name: values.last_name,
          mobile_number: values.mobile_number,
        };

        this.setState({
          loading: true,
          loadingbtn: true,
        });
        this.props
          .sendinviteuser(obj_arr)
          .then((res) => {
            message.success("Candidate invite sent successfully");
            this.props.form.resetFields();
            this.setState({
              loading: false,
              loadingbtn: false,
              optionvisbile: "",
              resvisbible: false,
              fdata: [],
            });
          })
          .catch((err) => {
            this.setState({
              loading: false,
              loadingbtn: false,
            });
            message.error(err);
          });
      }
    });
  };

  renderOffice() {
    return _.map(this.state.office, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  OnchangeOption = (e) => {
    console.log(e.target.value);
    this.setState({
      optionvisbile: e.target.value,
    });
  };
  onchangeoffice = (e) => {
    console.log(e);
    const results = this.state.searchOffData.filter((element) => {
      return element.id === parseInt(e.key);
    });
    console.log(results);
    this.setState({
      singleoffice: results,
      resvisbible: true,
    });
  };
  onchangeRole = (selectedItems) => {
    console.log(selectedItems);
    this.setState({
      resvisbible: true,
    });
    this.setState({ selectedItems });
  };
  fetchUser = (value) => {
    console.log("fetching user", value);
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    if (value.length > 1) {
      this.setState({ fdata: [], fetching: true });
      console.log(this);
      axiosInstance
        .get("/api/v1/reference/offices?term=" + value)
        .then((response) => {
          if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return;
          }
          console.log(response.data);
          this.setState({ searchOffData: response.data });
          const fdata = response.data.map((user) => ({
            text: user.name,
            value: user.id,
          }));
          console.log(this);
          this.setState({ fdata, fetching: false });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { spinloader, fdata, fetching } = this.state;

    return (
      <div className="container-fluid">
        <div class="row justify-content-between padd20 bg-grey">
          <div class="col-4">
            <h4 className="mt-1">Send Invite</h4>
          </div>
          {/* <div class="col-4">
            <Link to="/marketer" className="float-right">
              <Button type="default">Back</Button>
            </Link>
          </div> */}
        </div>
        <Spin indicator={antIcon} spinning={spinloader}>
          <div className=" bg-white">
            <Form onSubmit={this.handleSubmit} className="addbookform padd25">
              <div class="row justify-content-start">
                <Form.Item label="Select Office" className="col-4">
                  {getFieldDecorator("office_id", {
                    rules: [
                      { required: true, message: "Please Select Office" },
                    ],
                  })(
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      labelInValue
                      placeholder="Type 2 characters to search..!"
                      notFoundContent={
                        fetching ? <Spin size="small" /> : <Empty />
                      }
                      filterOption={false}
                      onSearch={this.fetchUser}
                      defaultActiveFirstOption={false}
                      onChange={this.onchangeoffice}
                    >
                      {fdata.map((d) => (
                        <Option key={d.value}>{d.text}</Option>
                      ))}
                    </Select>
                    // <Select
                    //   showSearch
                    //   style={{ width: "100%" }}
                    //   placeholder="Select Option"
                    //   filterOption={(input, option) =>
                    //     option.props.children
                    //       .toLowerCase()
                    //       .indexOf(input.toLowerCase()) >= 0
                    //   }
                    //   onChange={(e) => this.onchangeoffice(e)}
                    // >
                    //   {this.renderOffice()}
                    // </Select>
                  )}
                </Form.Item>
                <div
                  className={
                    this.state.resvisbible === true ? "col-4 mt-7" : "d-none" 
                  }
                >
                  <Button
                    type="primary"
                    icon="qrcode"
                    className="mr5 "
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      window.open(
                        this.state.singleoffice[0].qr_code_url,
                        "_blank"
                      );
                    }}
                  >
                    Download QR Code
                  </Button>
                  <Button
                    type="primary"
                    className="mr5"
                    icon="link"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigator.clipboard
                        .writeText(this.state.singleoffice[0].onboarding_link)
                        .then(() => {
                          message.success("COPIED");
                        })
                        .catch((err) => {
                          console.log(err.message);
                        });
                    }}
                  >
                    Copy Link
                  </Button>
                </div>
              </div>
              <div class="row">
                <Form.Item label="First Name" className="col-4 mt-2">
                  {getFieldDecorator("first_name", {
                    rules: [
                      {
                        required: false,
                        pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                        message: "Please input first name",
                      },
                    ],
                  })(<Input placeholder="Enter First Name" />)}
                </Form.Item>
                <Form.Item label="Middle Name" className="col-4 mt-2">
                  {getFieldDecorator("middle_name", {
                    rules: [
                      {
                        required: false,
                        pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                        message: "Please input middle name",
                      },
                    ],
                  })(<Input placeholder="Enter Middle Name" />)}
                </Form.Item>
                <Form.Item label="Last Name" className="col-4 mt-2">
                  {getFieldDecorator("last_name", {
                    rules: [
                      {
                        required: false,
                        pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                        message: "Please input last name",
                      },
                    ],
                  })(<Input placeholder="Enter Last Name" />)}
                </Form.Item>
                <Form.Item label="Select Option" className="col-3">
                  {getFieldDecorator("comoption", {
                    rules: [
                      {
                        required: true,
                        message: "Please select option",
                      },
                    ],
                  })(
                    <Radio.Group onChange={(e) => this.OnchangeOption(e)}>
                      <Radio value={1}>Email</Radio>
                      <Radio value={2}>Phone</Radio>
                    </Radio.Group>
                  )}
                </Form.Item>
                {this.state.optionvisbile === 1 ? (
                  <Form.Item label="Email" className="col-4">
                    {getFieldDecorator("email", {
                      rules: [
                        {
                          required: true,
                          pattern: new RegExp(
                            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                          ),
                          message: "Please input valid email",
                        },
                      ],
                    })(<Input placeholder="Enter Email Address" />)}
                  </Form.Item>
                ) : null}

                {this.state.optionvisbile === 2 ? (
                  <Form.Item label="Mobile number" className="col-4">
                    {getFieldDecorator("mobile_number", {
                      rules: [
                        {
                          required: true,
                          pattern: new RegExp(/^[0-9]{10,10}$/),
                          message: "Please input valid mobile number",
                        },
                      ],
                    })(<Input placeholder="Enter Mobile Number" />)}
                  </Form.Item>
                ) : null}

                <br />
                <br />
                <div className="col-12 mt-4">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={this.state.loadingbtn}
                  >
                    Send Invite
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </Spin>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user, data: state.data };
}
const WrappedAddEmployee = Form.create({ name: "Send Invites" })(AddMarkter);
export default connect(mapStateToProps, {
  getofficelistname,
  sendinviteuser,
})(WrappedAddEmployee);
