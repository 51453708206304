import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Tree, Input, Icon } from "antd";
// import Data from "./trainingDocument.json";
const { TreeNode } = Tree;
const { Search } = Input;

function TrainingVideo() {
  const user = useSelector((state) => state.user);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [superadmin, setSuperadmin] = useState();
  const [masteruser, setMasterUser] = useState();
  const [retailer, setRetailer] = useState();
  const [ibo, setIbo] = useState([]);
  const [iframeVisible, setIframeVisible] = useState(false);
  const [iframeData, setIframeData] = useState([]);
  const treeRef = useRef(null);
  const treeNodesRef = useRef([]);
  const Data = [
    {
      category: "Agent",
      data: [
        {
          id: 1,
          isCollapsed: true,
          title: "Dashboard",
          content: "Dashboard Training Video",
          link: "https://wieserp.s3.amazonaws.com/Training_videos/Agent/Dashboard.mp4",
        },
        {
          id: 2,
          isCollapsed: true,
          title: "Inventory Management",
          content: "Inventory Management Training Video",
          link: "https://wieserp.s3.amazonaws.com/Training_videos/Agent/Inventory+Management.mp4",
        },
        {
          id: 3,
          isCollapsed: true,
          title: "Sales Reporting",
          content: "Sales Reporting Training Video",
          link: "https://wieserp.s3.amazonaws.com/Training_videos/Agent/Sales+Reporting.mp4",
        },
        {
          id: 4,
          isCollapsed: true,
          title: "Agent All Modules",
          content: "Agent All Modules Training Video",
          link: "https://wieserp.s3.amazonaws.com/Training_videos/Agent/Agent_WiES_All_Modules.mp4",
        },
      ],
    },

    // {
    //   category: "IBO Inventory Manager",
    //   data: [
    //     {
    //       id: 4,
    //       isCollapsed: true,
    //       title: "Dashboard",
    //       content: "IBO Inventory Manager Dashboard Training Module",
    //       link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/IBO+Inventory+Manager/IBO+Inventory+Manager+Dashboard.pdf",
    //     },
    //     {
    //       id: 5,
    //       isCollapsed: true,
    //       title: "Inventory",
    //       content: "IBO Inventory Manager Inventory Training Module",
    //       link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/IBO+Inventory+Manager/IBO+Inventory+Manager+Inventory.pdf",
    //     },
    //     {
    //       id: 6,
    //       isCollapsed: true,
    //       title: "Manage Agents",
    //       content: "IBO Inventory Manager Manage Agents Training Module",
    //       link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/IBO+Inventory+Manager/IBO+Inventory+Manager+Manage+Agents.pdf",
    //     },
    //     {
    //       id: 7,
    //       isCollapsed: true,
    //       title: "RMA Orders",
    //       content: "IBO Inventory Manager RMA Orders Training Module",
    //       link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/IBO+Inventory+Manager/IBO+Inventory+Manager+RMA+_+Orders.pdf",
    //     },
    //     {
    //       id: 8,
    //       isCollapsed: true,
    //       title: "Sale Report",
    //       content: "IBO Inventory Manager Sale Report Training Module",
    //       link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/IBO+Inventory+Manager/IBO+Inventory+Manager+Sale+Report.pdf",
    //     },
    //   ],
    // },
    {
      category: "IBO Manager",
      data:
        retailer === 5
          ? []
          : [
              {
                id: 9,
                isCollapsed: true,
                title: "Administration",
                content: "Administration Training Video",
                link: "https://wieserp.s3.amazonaws.com/Training_videos/IBO+Manager/Administration.mp4",
              },
              // {
              //   id: 10,
              //   isCollapsed: true,
              //   title: "Dashboard",
              //   content: "IBO Manager Dashboard Training Module",
              //   link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/IBO+Manager/IBO+Manager+Dashboard.pdf",
              // },
              // {
              //   id: 11,
              //   isCollapsed: true,
              //   title: "Inventory",
              //   content: "IBO Manager Inventory Training Module",
              //   link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/IBO+Manager/IBO+Manager+Inventory.pdf",
              // },
              // {
              //   id: 12,
              //   isCollapsed: true,
              //   title: "Onboarding",
              //   content: "IBO Manager Onboarding Training Module",
              //   link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/IBO+Manager/IBO+Manager+Onboarding.pdf",
              // },
              // {
              //   id: 13,
              //   isCollapsed: true,
              //   title: "Sales Report",
              //   content: "IBO Manager Sales Report Training Module",
              //   link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/IBO+Manager/IBO+Manager+Sale+Report.pdf",
              // },
            ],
    },
    {
      category: "Office Manager",
      data: [
        {
          id: 14,
          isCollapsed: true,
          title: "Dashboard and Onboarding",
          content: "Dashboard and Onboarding Agents Training Video",
          link: "https://wieserp.s3.amazonaws.com/Training_videos/Manager/Dashboard+_+Onboarding.mp4",
        },
        {
          id: 15,
          isCollapsed: true,
          title: "Sales and Inventory Reports",
          content: "Sales and Inventory Training Video",
          link: "https://wieserp.s3.amazonaws.com/Training_videos/Manager/Sales+_+Inventory+Reporting.mp4",
        },
        {
          id: 16,
          isCollapsed: true,
          title: "RMA Orders",
          content: "RMA Orders Training Video",
          link: "https://wieserp.s3.amazonaws.com/Training_videos/Manager/RMA+_+Orders.mp4",
        },
        {
          id: 17,
          isCollapsed: true,
          title: "Inventory Management",
          content: "Inventory Management Training Video",
          link: "https://wieserp.s3.amazonaws.com/Training_videos/Manager/Inventory+Management.mp4",
        },
      ],
    },
    // ibo[0] === 4 || ibo[0] === 8
    //   ? {}
    //   : {
    //       category: "Retailer Inventory Manager",
    //       data: [
    //         {
    //           id: 21,
    //           isCollapsed: true,
    //           title: "Dashboard",
    //           content: "Retailer Inventory Manager Dashboard Training Module",
    //           link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/Retailer+Inventory+Manager/Retailer+Inventory+Manager+Dashboard.pdf",
    //         },
    //         {
    //           id: 22,
    //           isCollapsed: true,
    //           title: "Inventory",
    //           content: "Retailer Inventory Manager Inventory Training Module",
    //           link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/Retailer+Inventory+Manager/Retailer+Inventory+Manager+Inventory.pdf",
    //         },
    //         {
    //           id: 23,
    //           isCollapsed: true,
    //           title: "Manage Agents",
    //           content:
    //             "Retailer Inventory Manager Manage Agents Training Module",
    //           link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/Retailer+Inventory+Manager/Retailer+Inventory+Manager+Manage+Agents.pdf",
    //         },
    //         {
    //           id: 24,
    //           isCollapsed: true,
    //           title: "RMA Orders",
    //           content: "Retailer Inventory Manager RMA Orders Training Module",
    //           link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/Retailer+Inventory+Manager/Retailer+Inventory+Manager+RMA+_+Orders.pdf",
    //         },
    //         {
    //           id: 25,
    //           isCollapsed: true,
    //           title: "Sale Report",
    //           content: "Retailer Inventory Manager Sale Report Training Module",
    //           link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/Retailer+Inventory+Manager/Retailer+Inventory+Manager+Sale+Report.pdf",
    //         },
    //       ],
    //     },
    // ibo[0] === 4 || ibo[0] === 8
    //   ? {}
    //   : {
    //       category: "Retailer",
    //       data: [
    //         {
    //           id: 19,
    //           isCollapsed: true,
    //           title: "Retailer Administration",
    //           content: "Retailer Administration Training Module",
    //           link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/Retailer/Retailer+Administration.pdf",
    //         },
    //         {
    //           id: 20,
    //           isCollapsed: true,
    //           title: "Retailer Dashboard",
    //           content: "Retailer Dashboard Training Module",
    //           link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/Retailer/Retailer+Dashboard.pdf",
    //         },
    //         {
    //           id: 26,
    //           isCollapsed: true,
    //           title: "Retailer Inventory",
    //           content: "Retailer Inventory Training Module",
    //           link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/Retailer/Retailer+Inventory.pdf",
    //         },
    //         {
    //           id: 27,
    //           isCollapsed: true,
    //           title: "Retailer Onboarding",
    //           content: "Retailer Onboarding Training Module",
    //           link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/Retailer/Retailer+Onboarding.pdf",
    //         },
    //         {
    //           id: 28,
    //           isCollapsed: true,
    //           title: "Retailer Sales Report",
    //           content: "Retailer Sales Report Training Module",
    //           link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/Retailer/Retailer+Sales+Report.pdf",
    //         },
    //       ],
    //     },
  ];

  const filter = Data.filter((item) => Object.keys(item).length !== 0);

  const onChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    setAutoExpandParent(true);

    const filtered = filter.filter(
      (item) =>
        item.category.toLowerCase().includes(value.toLowerCase()) ||
        item.data.some(
          (subItem) =>
            subItem.title.toLowerCase().includes(value.toLowerCase()) ||
            subItem.content.toLowerCase().includes(value.toLowerCase())
        )
    );
    setFilteredData(filtered);
  };

  useEffect(() => {
    setSuperadmin(user?.role_id?.filter((role) => role === 10) ?? []);
    setMasterUser(user?.role_id?.filter((role) => role === 1) ?? []);
    setRetailer(user?.role_id?.filter((role) => role === 5) ?? []);
    setIbo(user?.role_id?.filter((role) => role === 4 || role === 8) ?? []);
  }, []);
  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  const handleOpenIframe = (value) => {
    setIframeVisible(true);
    setIframeData(value);
  };

  const handleCloseIframe = (value) => {
    setIframeVisible(false);
  };

  const renderTreeNodes = (data) =>
    data.map((item) => {
      if (item.data) {
        return (
          <TreeNode
            className="align-items-center"
            style={{ fontSize: "1.4rem", marginLeft: "25%" }}
            title={item.category}
            key={item.category}
            ref={(node) => treeNodesRef.current.push(node)}
          >
            {item.data.map((subItem) => (
              <TreeNode
                className="mx-3"
                key={subItem.id}
                title={
                  <div>
                    <a href={subItem.link} rel="noopener noreferrer">
                      <p
                        style={{ margin: 0, padding: 5 }}
                        // onClick={() => {
                        //   const newWindow = window.open(
                        //     subItem.link,
                        //     "_blank",
                        //     "noopener,noreferrer"
                        //   );
                        //   if (newWindow) newWindow.opener = null;
                        // }}
                        onClick={() => handleOpenIframe(subItem)}
                      >
                        <i className="fa fa-file-video-o"></i> &nbsp;
                        {subItem.title}
                      </p>
                    </a>
                  </div>
                }
              />
            ))}
          </TreeNode>
        );
      }
      return <TreeNode title={item.title} key={item.id} />;
    });

  useEffect(() => {
    const expandedKeys = filter.reduce((keys, item) => {
      if (
        item.category.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.data.some(
          (subItem) =>
            subItem.title.toLowerCase().includes(searchValue.toLowerCase()) ||
            subItem.content.toLowerCase().includes(searchValue.toLowerCase())
        )
      ) {
        return [...keys, item.category];
      }
      return keys;
    }, []);
    setExpandedKeys(expandedKeys);
    scrollToClosestMatch();
  }, [searchValue]);

  const scrollToClosestMatch = () => {
    if (treeRef.current) {
      const nodes = treeNodesRef.current;
      let minDistance = Number.MAX_VALUE;
      let closestNode = null;
      nodes.forEach((node) => {
        const text =
          node.props.title.props.children[0].props.children.toLowerCase();
        const distance = text.includes(searchValue.toLowerCase())
          ? text.indexOf(searchValue.toLowerCase())
          : Number.MAX_VALUE;
        if (distance < minDistance) {
          minDistance = distance;
          closestNode = node;
        }
      });

      if (closestNode && closestNode.dom) {
        closestNode.dom.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

  return (
    <div>
      <section className="section mb-5 pt-5 pb-5 bg-dark support_banner">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xl-7 text-center desc">
              <div className=" mb-1 text-white">
                <Search
                  placeholder="Search"
                  onChange={onChange}
                  onPressEnter={(e) => e.preventDefault()}
                  size="large"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="d-flex">
        <Tree
          ref={treeRef}
          switcherIcon={<Icon style={{ fontSize: "1.5rem" }} type="down" />}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          onExpand={onExpand}
        >
          {renderTreeNodes(filteredData.length > 0 ? filteredData : filter)}
        </Tree>
        {iframeVisible && (
          <div
            className="position-relative"
            style={{
              position: "fixed",
              top: 0,
              zIndex: 999,
              left: "5%",
            }}
          >
            {iframeData.content}
            <br />
            <iframe
              width={720}
              height={408}
              src={iframeData.link}
              title={iframeData.content}
              allowfullscreen
            />
            <i
              onClick={() => handleCloseIframe()}
              className="fa fa-times-circle-o position-absolute image-size"
            ></i>
          </div>
        )}
      </div>
    </div>
  );
}

export default TrainingVideo;
