import axiosInstance from "./AxiosInstance";
import { GET_RMA_PENDING, GET_RMA_REPORT } from "../actionTypes";
export function getrmareport(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/rma_report?page=" + params.page)
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_RMA_REPORT,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getrmarequestaccept(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = `/api/v1/get_rma_orders_list?status=${params.status ?? ""
        }&ibo_marketer_id=${params.ibo_marketer_id ?? ""}&retailer_id=${params.retailer_id ?? ""
        }&filter=${params.sort ?? ""
        }&sort_by=${params.sort_by ?? ""}&sort=${params.sort ?? ""
        }&keyword=${params.search_text ?? ""
        }&page=${params.page}`;
      console.log(`url`, url)
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_RMA_PENDING,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Update RMA approval status
export function updatermastatus(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(`updatermastatus`, params)
      axiosInstance.post(`/api/v1/rma_order_accept_reject`, params)
        .then(response => {
          console.log(`response`, response)
          resolve(response)
        }).catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  }
}

export function exportrmarequestaccept(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = `/api/v1/get_rma_orders_export?status=${params.status ?? ""
        }&ibo_marketer_id=${params.ibo_marketer_id ?? ""}&retailer_id=${params.retailer_id ?? ""
        }&filter=${params.sort ?? ""
        }&sort_by=${params.sort_by ?? ""}&sort=${params.sort ?? ""
        }&keyword=${params.search_text ?? ""
        }`;
      console.log(`url`, url)
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_RMA_PENDING,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
