import React, { Component } from "react";
import { connect } from "react-redux";
import {
  rad_issues_status,
  searchonboardingreport,
  rad_link_issues,
  searchradreportlist,
  resendinviteuser,
  resendinviteusernew,
  getofficelistname,
  onboardinglog,
  resendreminderuser,
  exportradreportlist,
} from "../../actions/assetAction";
import _ from "lodash";
import {
  Button,
  Table,
  Tag,
  Input,
  Form,
  Select,
  DatePicker,
  message,
  Tooltip,
  Drawer,
  Divider,
  List,
  Empty,
  Spin
} from "antd";
import axiosInstance from "../../actions/AxiosInstance";

const { Option } = Select;

class RadLinkIssues extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onboarding: [],
      data: [],
      pagination: {},
      loading: true,
      loadingbtn: false,
      startdate: "",
      enddate: "",
      startValue: null,
      endValue: null,
      endOpen: false,
      selectedItems: [],
      inventorystatus: [],
      search_text: "",
      selectedfieldname: [],
      selectedfilter: [],
      selectedstatus: [],
      selectedoffice: [],
      office: [],
      agent: [],
      detailform: {},
      statusval: [],
      visible: false,
      loadingEPbtn: false,
      fdata: [],
      selectedItems: [],
    };
  }

  componentWillMount() {
    console.log(`this.props`, this.props);
    const urlString = this.props.location?.state?.urlpath ?? "";

    this.props.rad_issues_status().then((res) => {
      console.log(res.data);
      this.setState({
        onboarding: res.data,
      });
    });

    this.props
      .getofficelistname()
      .then((res) => {
        console.log(res.data);
        this.setState({
          office: res.data,
        });
      })
      .catch((err) => {});
    this.getdesignation({
      url: urlString ?? "",
      results: 10,
      page: 1,
    });
  }

  getdesignation(params = {}) {
    this.setState({
      loading: true,
    });
    this.props
      .rad_link_issues(params)
      .then((res) => {
        console.log(res.data.data.data);
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.data.total;
        this.setState({
          data: res.data.data.data,
          loading: false,
          pagination,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };

    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      sortField: sorter.order === undefined ? "" : sorter.field,
      sortOrder:
        sorter.order === "ascend"
          ? "ASC"
          : sorter.order === undefined
          ? ""
          : "DESC",
    });
    setTimeout(() => {
      this.searchonboardingreport();
    }, 100);
  };
  fetch = (params = {}) => {
    console.log("params:", params);
    this.getdesignation(params);
  };
  renderonboarding() {
    return _.map(this.state.onboarding, (master, index) => {
      //console.log(master.id);
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }

  onchangeRole = (selectedItems) => {
    this.setState({ selectedItems });
    console.log(this.state.selectedItems);
  };
  getrefreshbtn = () => {
    this.getdesignation({
      results: 10,
      page: 1,
    });
    this.setState({
      status: undefined,
      name: "",
      startValue: null,
      endValue: null,
      selectedoffice: [],
      selectedstatus: [],
    });
  };
  searchonboardingreport = (reset) => {
    var status = this.state.selectedstatus;
    var search_text = this.state.name;
    var sort = this.state.sortField;
    var sort_by = this.state.sortOrder;
    var date_range_start = this.state.startValue;
    var date_range_end = this.state.endValue;
    var office_id = this.state.selectedItems;

    var page = reset ? 1 : this.state.pagination?.current ?? 1;
    this.setState({ loading: true, loadingbtn: true });

    this.props
      .searchradreportlist({
        status: status,
        search_text: search_text,
        page: page,
        sort,
        sort_by,
        date_range_start,
        date_range_end,
        office_id,
      })
      .then((res) => {
        console.log(res);
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.data.total;
        pagination.current = res.data.data.current_page;
        this.setState({
          data: res.data.data.data,
          loading: false,
          loadingbtn: false,
          pagination,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
          loadingbtn: false,
        });
      });
  };

  clearSearch = () => {
    this.setState({
      status: undefined,
      name: "",
      startValue: null,
      endValue: null,
      selectedoffice: [],
      selectedstatus: [],
      selectedItems: [],
      fdata:[],
    });
    this.getdesignation({
      results: 10,
      page: 1,
    });
  };
  onchangefilter = (selectedstatus) => {
    this.setState({ selectedstatus });
  };
  onchangeoffice = (selectedoffice) => {
    this.setState({ selectedoffice });
  };
  disabledStartDate = (startValue) => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  disabledEndDate = (endValue) => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };
  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };
  onStartChange = (value) => {
    this.onChange("startValue", value);
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };
  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };
  resendoption = (params) => {
    this.setState({ loading: true });
    this.props
      .resendinviteuser(params)
      .then((res) => {
        console.log(res);
        message.success(res.data.message);
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        message.error(err.data.message);
        this.setState({ loading: false });
      });
  };
  resendoptionnew = (params) => {
    this.setState({ loading: true });
    this.props
      .resendinviteusernew(params)
      .then((res) => {
        console.log(res);
        message.success(res.data.message);
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        message.error(err.data.message);
        this.setState({ loading: false });
      });
  };
  resendreminder = (params) => {
    this.setState({ loading: true });
    this.props
      .resendreminderuser(params)
      .then((res) => {
        console.log(res);
        message.success(res.data.message);
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        message.error(err.data.message);
        this.setState({ loading: false });
      });
  };
  renderOffice() {
    return _.map(this.state.office, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  rendercanlog = (params) => {
    this.setState({ loading: true });
    this.props
      .onboardinglog(params)
      .then((res) => {
        console.log(res.data.items);
        this.setState({ Listdata: res.data.items });
        message.success(res.data.message);
        this.setState({ loading: false, visible: true });
      })
      .catch((err) => {
        console.log(err);
        message.error(err.data.message);
        this.setState({ loading: false });
      });
  };
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  getexportbtn = () => {
    var status = this.state.selectedstatus;
    var search_text = this.state.name;
    var sort = this.state.sortField;
    var sort_by = this.state.sortOrder;
    var date_range_start = this.state.startValue;
    var date_range_end = this.state.endValue;
    var office_id = this.state.selectedoffice;
    this.setState({ loading: true, loadingEPbtn: true });
    this.props
      .exportradreportlist({
        status: status,
        search_text: search_text,
        sort,
        sort_by,
        date_range_start,
        date_range_end,
        office_id,
      })
      .then((res) => {
        this.setState({ loading: false, loadingEPbtn: false });
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        this.setState({ loading: false, loadingEPbtn: false });
      });
  };
  // ======================================================
  onchangeRole = (selectedItems) => {
    console.log(selectedItems);
    this.setState({ selectedItems });
  };
  fetchUser = (value) => {
    console.log("fetching user", value);
    if (value.length > 1) {
      this.setState({ fdata: [], fetching: true });
      console.log(this);
      axiosInstance
        .get("/api/v1/reference/offices?term=" + value)
        .then((response) => {
          console.log(response.data);
          this.setState({searchOffData : response.data})
          const fdata = response.data.map((user) => ({
            text: user.name,
            value: user.id,
          }));  
          console.log(this);
          this.setState({ fdata, fetching: false });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  render() {
    const { endOpen, startValue, endValue, visible } = this.state;
    const columns = [
      {
        title: "Office",
        dataIndex: "office_name",
        key: "office_name",
      },
      {
        title: "First Name",
        dataIndex: "first_name",
        key: "first_name",
        sorter: true,
      },
      {
        title: "Middle Name",
        dataIndex: "middle_name",
        key: "middle_name",
        sorter: true,
      },
      {
        title: "Last Name",
        dataIndex: "last_name",
        key: "last_name",
        sorter: true,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: true,
      },
      {
        title: "Mobile Number",
        dataIndex: "mobile_number",
        key: "mobile_number",
        sorter: true,
      },
      {
        title: "Address1",
        dataIndex: "street_address1",
        key: "street_address1",
      },
      {
        title: "Address2",
        dataIndex: "street_address2",
        key: "street_address2",
      },
      {
        title: "City",
        dataIndex: "city",
        key: "city",
      },
      {
        title: "State",
        dataIndex: "state",
        key: "state",
      },
      {
        title: "Zip",
        dataIndex: "zip",
        key: "zip",
      },
      {
        title: "BGC Description",
        dataIndex: "bgc_status",
        key: "bgc_status",
      },
      {
        title: "BGC File",
        dataIndex: "bgc_file",
        key: "bgc_file",
      },

      {
        title: "Created Date",
        dataIndex: "created_at",
        key: "created_at",
        sorter: true,
      },
      {
        title: "Status",
        dataIndex: "status_name",
        key: "status_name",
        render: (text, record) => (
          <span>
            <Tag color="orange">{record.status_name}</Tag>
          </span>
        ),
      },
      {
        title: "Action",
        dataIndex: "Action",
        key: "Action",

        fixed: "right",
        width: 150,
        render: (_, record) => (
          <span>
            {record.status <= 1 ? (
              <Tooltip title="Resend Invite">
                <Button
                  shape="circle"
                  icon="mail"
                  onClick={() => this.resendoption(record)}
                  type="primary"
                ></Button>
              </Tooltip>
            ) : (
              <Tooltip title="Email Reminder">
                <Button
                  shape="circle"
                  icon="mail"
                  onClick={() => this.resendoptionnew(record)}
                  type="primary"
                ></Button>
              </Tooltip>
            )}
            <Divider type="vertical" />
            {record.status !== 13 ? (
              <Tooltip title="SMS Reminder">
                <Button
                  shape="circle"
                  icon="mobile"
                  onClick={() => this.resendreminder(record)}
                  type="primary"
                ></Button>
              </Tooltip>
            ) : (
              <Tooltip title="SMS Reminder">
                <Button
                  shape="circle"
                  icon="mobile"
                  disabled
                  type="primary"
                ></Button>
              </Tooltip>
            )}
            <Divider type="vertical" />
            <Tooltip title="View log">
              <Button
                shape="circle"
                icon="history"
                onClick={() => this.rendercanlog(record)}
                type="primary"
              ></Button>
            </Tooltip>
          </span>
        ),
      },
    ];
    console.log(`this.state.onboarding`, this.state.onboarding);
    
    const { fdata,fetching, } = this.state;

    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            <div className="col-sm-4">
              <h4>
                RAD Linking Failures{" "}
                {this.state.pagination.total &&
                  `(${this.state.pagination.total})`}
              </h4>
            </div>
            <div className="col-sm-8 text-right">
              <Button
                type="default"
                className="mr5"
                onClick={() => this.getrefreshbtn()}
              >
                <i className="uil uil-sync" /> Refresh
              </Button>

              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingEPbtn}
                onClick={() => this.getexportbtn()}
              >
                <i className="uil uil-export" /> Export Report
              </Button>
            </div>
          </div>
          <div className="row padd20 bg-grey">
            <div className="col-3 mt-1">
              <label for="exampleFormControlInput1">Select Office</label>
              <Select
                    showSearch
                    style={{ width: "100%" }}
                    labelInValue
                    placeholder="Type 2 characters to search..!"
                    notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
                    filterOption={false}
                    onSearch={this.fetchUser}
                    defaultActiveFirstOption={false}
                    value={this.state.selectedItems}
                    onChange={this.onchangeRole}
                  >
                    {fdata.map((d) => (
                      <Option key={d.value}>{d.text}</Option>
                    ))}
                  </Select>
              {/* <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select Option"
                value={this.state.selectedoffice}
                onChange={this.onchangeoffice}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="0">Any</Option>
                {this.renderOffice()}
              </Select> */}
            </div>
            <div className="col-3  mt-1">
              <label for="exampleFormControlInput1">
                Select RAD Link Issues
              </label>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select Option"
                value={this.state.selectedstatus}
                onChange={this.onchangefilter}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.renderonboarding()}
              </Select>
            </div>
            <div className="col-3 mt-1">
              <label for="exampleFormControlInput1">Start</label>
              <DatePicker
                value={startValue}
                disabledDate={this.disabledStartDate}
                format="MM-DD-YYYY"
                placeholder="Select Date"
                onChange={this.onStartChange}
                onOpenChange={this.handleStartOpenChange}
              />
            </div>
            <div className="col-3 mt-1">
              <label for="exampleFormControlInput1">End</label>
              <DatePicker
                value={endValue}
                disabledDate={this.disabledEndDate}
                format="MM-DD-YYYY"
                placeholder="Select Date"
                onChange={this.onEndChange}
                open={endOpen}
                onOpenChange={this.handleEndOpenChange}
              />
            </div>

            <div className="col-3 mt-1">
              <label for="exampleFormControlInput1">Search By keyword</label>
              <Input
                placeholder="Type keyword"
                value={this.state.name}
                // ref={(node) => {
                //   this.searchInput = node;
                // }}
                onChange={(e) =>
                  this.setState({
                    name: e.target.value,
                  })
                }
                style={{ width: "100%", display: "block" }}
              />
            </div>
            <div className="col-3 mt-6">
              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingbtn}
                onClick={() => this.searchonboardingreport("reset")}
              >
                Search
              </Button>
              <Button type="default" onClick={() => this.clearSearch()}>
                Clear
              </Button>
            </div>
          </div>
          <div className="bg-white padd0 mt-3">
            <Table
              columns={columns}
              dataSource={this.state.data}
              scroll={{ x: true }}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              // size="small"
            />
          </div>
          <Drawer
            title="View Log"
            placement="right"
            closable={true}
            width={420}
            onClose={this.onClose}
            visible={this.state.visible}
          >
            <List
              itemLayout="horizontal"
              dataSource={this.state.Listdata}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={item.created_at}
                    description={item.description}
                  />
                </List.Item>
              )}
            />
          </Drawer>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    data: state.data,
  };
}

const WrappedAdvancedSearchForm = Form.create({ name: "advanced_search" })(
  RadLinkIssues
);

export default connect(mapStateToProps, {
  rad_issues_status,
  searchonboardingreport,
  rad_link_issues,
  searchradreportlist,
  resendinviteuser,
  getofficelistname,
  onboardinglog,
  resendreminderuser,
  resendinviteusernew,
  exportradreportlist,
})(WrappedAdvancedSearchForm);
