import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getmarketerdoclist,
  searchofficelist,
  getibomarketlistnew,
  getsortlistoffice,
  officedeleteaction,
  downloadform,
} from "../../actions/assetAction";
import { office_export } from "../../actions/exportAction";
import { Button, Table, Tooltip, message } from "antd";

class AgentDocuments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documents: [],
      pagination: {},
      loading: true,
      market: [],
      superrole: "",
      retailview: false,
      downloadLoading: false,
      status: "",
      reason: null,
    };
  }

  componentWillMount() {
    const Retailer =
      this.props?.user?.role_id?.filter((word) => word === 5) ?? [];
    if (Retailer?.length !== 0) {
      this.setState({
        retailview: true,
      });
    }
    const masteruser = this.props.user.role_id.filter((word) => word === 1);
    if (masteruser.length !== 0) {
      this.setState({
        superrole: true,
      });
    }
    this.getmarketer();
    // this.getdesignation({
    //   results: 10,
    //   page: 1,
    // });
    // this.props.getmarketerdoclist(this.props.user.user_id).then((res) => {
    //   this.setState({
    //     market: this.props.data,
    //   });
    // });
  }
  getmarketer() {
    console.log(`in getmarketer`);
    this.props
      .getmarketerdoclist(this.props.user.user_id)
      .then((res) => {
        console.log(`getmarketerdoclist`, res);
        this.setState({
          documents: res.data.items,
          loading: false,
          reason: res.data.reason,
          status: res.data.status,
        });
      })
      .catch((ex) => {
        console.log(`error loading forms`, ex);
        this.setState({ loading: false });
      });
  }
  // getdesignation(params = {}) {
  //   this.setState({ loading: true });
  //   this.props.getmarketerdoclist(params).then((res) => {
  //     console.log(res);
  //     const pagination = { ...this.state.pagination };
  //     pagination.total = res.data.items.total;
  //     pagination.current = res.data.items.current_page;
  //     this.setState({
  //       office: res.data.items.data,
  //       loading: false,
  //       pagination,
  //     });
  //   });
  // }
  handleTableChange = (pagination, filter, sorter) => {
    const pager = { ...this.state.pagination };
    console.log(filter);
    var status = filter?.status ?? [];
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      selectedstatus: status.toString(),
      sortField: sorter.order === undefined ? "" : sorter.field,
      sortOrder:
        sorter.order === "ascend"
          ? "ASC"
          : sorter.order === undefined
          ? ""
          : "DESC",
    });
    setTimeout(() => {
      this.getmarketer();
    }, 100);
  };

  getrefreshbtn = () => {
    this.clearSearch();
    this.getdesignation({
      results: 10,
      page: 1,
    });
  };
  getexportbtn = () => {
    this.setState({ loading: true });
    this.props
      .office_export()
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  handleDownload = (e) => {
    e.preventDefault();
    const documents = this.state.documents;
    if (documents !== undefined && documents !== null && documents.length > 0) {
      this.setState({ downloadLoading: true });
      console.log(`Agent Documents record`, this.props.user.user_id);
      this.props
        .downloadform(this.props.user.user_id)
        .then((response) => {
          console.log("download form", response);
          // let responseHtml = response;
          // console.log(responseHtml, "Monitoring");
          //open the new window and write your HTML to it
          // var myWindow = window.open("", "response", "resizable=yes");
          // New tab
          // var myWindow =window.open("", "_blank");
          window.open(response?.data?.url ?? "", "_blank");
          // myWindow.document.write(responseHtml);
          this.setState({ downloadLoading: false });
        })
        .catch((ex) => {
          console.log(`error downloading form`, ex);
          this.setState({ downloadLoading: false });
        });
    }
  };

  // ======================================================

  render() {
    const { documents, downloadLoading, reason, status } = this.state;
    const columns = [
      {
        title: "Document Name",
        dataIndex: "form_name",
        key: "form_name",
        // sorter: true,
      },
      {
        title: "Created At",
        dataIndex: "created_at",
        key: "created_at",
        // sorter: true,
      },
      {
        title: "Updated At",
        dataIndex: "updated_at",
        key: "updated_at",
        // sorter: true,
      },
      {
        title: "Status",
        dataIndex: "status_text",
        key: "status_text",
        // sorter: true,
      },
      {
        title: "Action",
        key: "action",
        width: 150,
        fixed: "right",
        render: (text, record) => (
          <div>
            <div>
              <span>
                {this.state.superrole !== true ? (
                  <>
                    <Link
                      className="padd5 mr5"
                      style={{ marginRight: 10 }}
                      to="#"
                      onClick={() => {
                        if (
                          record.status_text.toLowerCase() === "in progress" ||
                          record.status_text.toLowerCase() === "rejected"
                        ) {
                          window.location.href = `https://services.excesswies.com
/form/${record?.form_id}/${this.props.user.token}`;
                        }
                      }}
                    >
                      <Tooltip title="Fill form">
                        <Button
                          type="primary"
                          shape="circle"
                          icon="edit"
                          disabled={
                            record.status_text.toLowerCase() !==
                              "in progress" &&
                            record.status_text.toLowerCase() !== "rejected"
                          }
                        />
                      </Tooltip>
                    </Link>
                    {/* <Divider type="vertical" /> */}
                  </>
                ) : null}
              </span>
            </div>
          </div>
        ),
      },
    ].filter((item) => !item.hidden);

    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            {/* <div className="col-sm-8">
              <h4>List Of Documents</h4>
            </div> */}
            {status?.toLowerCase() === "accepted" ||
            status?.toLowerCase() === "completed" ||
            status?.toLowerCase() === "rejected" ? (
              <div className="col-sm-4" style={{ justifyContent: "flex-end" }}>
                <Button
                  type="primary"
                  className="login-form-button"
                  loading={downloadLoading}
                  onClick={this.handleDownload}
                  disabled={
                    documents === undefined ||
                    documents === null ||
                    documents.length <= 0
                  }
                >
                  Download Documents
                </Button>
              </div>
            ) : null}
          </div>
          <div className="row bg-grey">
            {documents.length == 0 ? (
              ""
            ) : (
              <div className="col-sm-8 mb-3">
                {/* <h6> */}

                <b>Documents Status: </b>
                <span
                  className={
                    status.toLowerCase() === "in progress"
                      ? "badge badge-warning"
                      : status.toLowerCase() === "completed"
                      ? "badge badge-primary"
                      : status.toLowerCase() === "rejected"
                      ? "badge badge-danger"
                      : "badge badge-success"
                  }
                >
                  {status ?? "NA"}
                </span>
                {/* </h6> */}
              </div>
            )}
          </div>
          {status.toLowerCase() === "rejected" ? (
            <div className="row bg-grey">
              <div className="col-sm-8 mb-4">
                <b>Rejected reason: </b>
                <span>{reason ?? "NA"}</span>
              </div>
            </div>
          ) : null}
          <div className="bg-white padd0">
            <Table
              columns={columns}
              dataSource={documents}
              scroll={{ x: true }}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              // size="small"
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    userinfo: state.userinfo,
    data: state.data,
  };
}

export default connect(mapStateToProps, {
  getmarketerdoclist,
  searchofficelist,
  getibomarketlistnew,
  getsortlistoffice,
  office_export,
  officedeleteaction,
  downloadform,
})(AgentDocuments);
