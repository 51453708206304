import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { user_export } from "../../actions/exportAction";
import { getretailers, getofficelistname } from "../../actions/assetAction";
import {
  getEmployeelist,
  searchEmployeelist,
  deleteEmployee,
  getRolelist,
  Employeeaction,
  getallsearchresult,
  getsortlistuser,
  changeofficeuser,
} from "../../actions/employeeAction";
import _ from "lodash";

import {
  Button,
  Table,
  Tag,
  Modal,
  message,
  Select,
  Input,
  Divider,
  Tooltip,
  Drawer,
  Form,
  Col,
  Row,
  Icon,
} from "antd";
const { Option } = Select;

class Employees extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Employees: [],
      retailer: [],
      pagination: {},
      loading: false,
      loadingbtn: false,
      loadingEPbtn: false,
      retailerrole: false,
      marketrole: false,
      superrole: false,
      superadmin: false,
      selectedItems: [],
      selectedfilter: [],
      selectedRetail: [],
      Pageurl: "",
      is_search: false,
      results: 10,
      sort_list: [],
      offices: [],
      visible: false,
      activeuser: [],
      btnloading: false,
      dashdata: "",
    };
  }

  componentDidMount() {
    // this.getOfficelist();
    this.getdesignation({
      results: 10,
      page: 1,
    });
    const superadmin =
      this.props?.user?.role_id?.filter((word) => word === 10) ?? [];
    const masteruser =
      this.props?.user?.role_id?.filter((word) => word === 1) ?? [];
    const Retailer =
      this.props?.user?.role_id?.filter((word) => word === 5) ?? [];
    const marketer =
      this.props?.user?.role_id?.filter((word) => word === 4 || word === 8) ??
      [];
    //console.log("Retailerview", Retailer);
    //console.log("marketer", marketer);
    if (Retailer.length !== 0) {
      this.setState({
        retailerrole: true,
      });
    }
    if (marketer.length !== 0) {
      this.setState({
        marketrole: true,
      });
    }
    if (masteruser.length !== 0) {
      this.setState({
        superrole: true,
      });
    }
    if (superadmin.length !== 0) {
      this.setState({
        superadmin: true,
      });
    }
    this.getRolelist();
    this.getRetailerList();
  }

  getdesignation(params = {}) {
    this.setState({ loading: true });
    this.props
      .getEmployeelist(params)
      .then((res) => {
        console.log(res);
        console.log(res.data.last_run_date);
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.items.total;
        this.setState({
          Employees: res.data.items.data,
          pagination,
          loading: false,
          dashdata: res.data.last_run_date,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }

  getOfficelist() {
    if (this.state.offices === undefined || this.state.offices.length <= 0) {
      // nprogress.start();
      this.props
        .getofficelistname()
        .then((res) => {
          console.log(res);
          this.setState({
            offices: res.data,
          });
          // nprogress.done();
        })
        .catch((err) => {
          this.setState({
            offices: [],
          });
          // nprogress.done();
        });
    }
  }

  handleTableChange = (pagination, filter, sorter) => {
    const pager = { ...this.state.pagination };
    console.log(sorter.order);
    var status = filter?.status ?? [];
    pager.current = pagination.current;
    console.log(pagination);
    this.setState({
      pagination: pager,
      selectedstatus: status.toString(),
      sortField: sorter.order === undefined ? "" : sorter.field,
      sortOrder:
        sorter.order === "ascend"
          ? "ASC"
          : sorter.order === undefined
          ? ""
          : "DESC",
    });
    setTimeout(() => {
      this.searchUser();
    }, 100);
  };

  searchUser = (resetpage) => {
    this.setState({ loading: true, loadingbtn: true });
    var status = this.state.selectedstatus;
    var search_text = this.state.phone;
    var roleName = this.state.selectedItems;
    var sort = this.state.sortField;
    var sort_by = this.state.sortOrder;
    var page = resetpage ? 1 : this.state.pagination?.current ?? 1;
    var results = this.state.results;

    console.log("items", this.state.selectedRetail);
    var url = `/api/v1/users?status=${status ?? ""}&search_text=${
      search_text ?? ""
    }&role=${roleName?.length > 0 ? roleName : ""}&sort=${sort ?? ""}&sort_by=${
      sort_by ?? ""
    }&page=${page ?? 1}&results=${results ?? 10}`;
    this.props
      .searchEmployeelist(url)
      .then((res) => {
        console.log(res);
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.items.total;
        pagination.current = res.data.items.current_page;
        this.setState({
          Employees: res.data.items.data,
          loading: false,
          loadingbtn: false,
          pagination,
          is_search: true,
        });
      })
      .catch((err) => {
        this.setState({ loading: false, loadingbtn: false });
      });
  };

  clearSearch = () => {
    this.setState({
      status: undefined,
      name: "",
      phone: "",
      roleName: "",
      selectedItems: [],
      selectedfieldname: [],
      selectedfilter: [],
      selectedRetail: [],
      is_search: false,
    });
    this.getdesignation({
      results: 10,
      page: 1,
    });
  };
  onchangeRole = (selectedItems) => {
    this.setState({ selectedItems });
    console.log(this.state.selectedItems);
  };

  onchangeRetail = (selectedRetail) => {
    this.setState({ selectedRetail });
    console.log(this.state.selectedRetail);
  };

  getRolelist() {
    if (this.state.roles === undefined || this.state.roles.length <= 0) {
      // nprogress.start();
      this.props
        .getRolelist()
        .then((res) => {
          // console.log("getRolelist", res);
          this.setState({
            roles: res.data.items,
          });
          // nprogress.done();
        })
        .catch((err) => {
          this.setState({
            roles: [],
          });
          // nprogress.done();
        });
    }
  }
  getRetailerList() {
    this.props
      .getretailers()
      .then((res) => {
        this.setState({
          retailer: this.props.data.retailer,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  renderRole() {
    return _.map(this.state.roles, (dep, index) => {
      if (this.state.superadmin === true) {
        return (
          <Option value={dep.id} key={dep.id}>
            {dep.name}
            {/* Market Supervisor */}
          </Option>
        );
      }
      if (this.state.superrole === true) {
        if (dep.id !== 1) {
          return (
            <Option value={dep.id} key={dep.id}>
              {dep.name}
              {/* Market Supervisor */}
            </Option>
          );
        }
      }
      if (this.state.marketrole === true) {
        if (dep.id === 3 || dep.id === 9 || dep.id === 2) {
          return (
            <Option value={dep.id} key={dep.id}>
              {dep.name}
              {/* Market Supervisor */}
            </Option>
          );
        }
      }
      if (this.state.retailerrole === true) {
        if (
          dep.id === 2 ||
          dep.id === 3 ||
          dep.id === 9 ||
          dep.id === 4 ||
          dep.id === 8 ||
          dep.id === 11 ||
          dep.id === 12
        ) {
          return (
            <Option value={dep.id} key={dep.id}>
              {dep.name}
            </Option>
          );
        }
      }
    });
  }

  renderRetail() {
    return _.map(this.state.retailer, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }

  getrefreshbtn = () => {
    this.getdesignation({
      results: 10,
      page: 1,
    });
    this.setState({
      phone: "",
      selectedItems: [],
      selectedRetail: [],
    });
  };

  getexportbtn = () => {
    this.setState({ loading: true, loadingEPbtn: true });
    var keyword = this.state.phone;
    var role = this.state.selectedItems;
    var status = this.state.selectedstatus;
    var sort = this.state.sortField;
    var sort_by = this.state.sortOrder;
    this.props
      .user_export(keyword, role, status, sort, sort_by)
      .then((res) => {
        this.setState({
          loading: false,
          loadingEPbtn: false,
        });
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        this.setState({ loading: false, loadingEPbtn: false });
      });
  };

  renderOffice() {
    return _.map(this.state.offices, (dep, index) => {
      return (
        <Option value={dep.id} key={index}>
          {dep.name}
        </Option>
      );
    });
  }
  showDrawer = (e) => {
    this.setState({
      loading: true,
    });
    this.props
      .getofficelistname(e.ibo_marketer_id)
      .then((res) => {
        console.log(res);
        this.setState({
          offices: res.data,
          visible: true,
          activeuser: e,
          loading: false,
        });
        // nprogress.done();
      })
      .catch((err) => {
        this.setState({
          offices: [],
          loading: false,
        });
        // nprogress.done();
      });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
    this.props.form.resetFields();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.form.validateFields((err, values) => {
          console.log(`values`, values);
          if (!err) {
            this.setState({
              loading: true,
              btnloading: true,
            });
            Modal.confirm({
              title: "Warning",
              content:
                "Are you sure you want to transfer the agent to the new office?",
              okText: "Yes",
              cancelText: "No",
              onOk: () => {
                this.props
                  .changeofficeuser(values, this.state.activeuser)
                  .then((res) => {
                    message.success("Office Updated Successfully");
                    this.props.form.resetFields();
                    this.setState({
                      loading: false,
                      btnloading: false,
                      visible: false,
                    });
                    this.getdesignation({
                      results: 10,
                      page: 1,
                    });
                  })
                  .catch((err) => {
                    message.error(err);
                    console.log(err);
                    this.setState({
                      loading: false,
                      btnloading: false,
                    });
                  });
              },
              onCancel: () => {
                this.setState({
                  loading: false,
                  btnloading: false,
                });
              },
            });
          }
        });
      }
    });
  };
  // ======================================================

  render() {
    const { dashdata } = this.state;
    console.log(dashdata);
    const { getFieldDecorator } = this.props.form;
    const columns = [
      {
        title: "Retailer",
        dataIndex: "user_retailer_name",
        key: "user_retailer_name",
        sorter: true,
      },
      {
        title: "IBOs / Markets",
        dataIndex: "user_ibo_market_name",
        key: "user_ibo_market_name",
        sorter: true,
      },
      {
        title: "First Name",
        dataIndex: "first_name",
        key: "first_name",
        sorter: true,
      },
      {
        title: "Middle Name",
        dataIndex: "middle_name",
        key: "middle_name",
        sorter: true,
      },
      {
        title: "Last Name",
        dataIndex: "last_name",
        key: "last_name",
        sorter: true,
      },

      {
        title: "Phone Number",
        dataIndex: "mobile_number",
        key: "mobile_number",
        sorter: true,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: true,
      },

      {
        title: "Role",
        dataIndex: "role_info",
        key: "role_info",

        render: (tags) => (
          <span>
            {tags.map((tag) => {
              return (
                <Tag color="volcano" key={tag.role_id}>
                  {tag.role_name}
                </Tag>
              );
            })}
          </span>
        ),
      },
      {
        title: "Office",
        dataIndex: "office_number",
        key: "office_number",
        sorter: true,
      },
      {
        title: "ETC Code",
        dataIndex: "excess_code",
        key: "excess_code",
      },
      {
        title: "Address",
        dataIndex: "address",
        key: "address",
      },
      {
        title: "City",
        dataIndex: "city",
        key: "city",
      },
      {
        title: "State",
        dataIndex: "state",
        key: "state",
      },
      {
        title: "ZIP",
        dataIndex: "zip",
        key: "zip",
      },

      {
        title: "Status",
        key: "status",
        dataIndex: "status",

        filters: [
          { text: "Active", value: "1" },
          { text: "In-Active", value: "0" },
          { text: "Removed", value: "2" },
          { text: "Suspended", value: "4" },
          { text: "Do Not Rehire", value: "5" },
          { text: "Terminated", value: "6" },
          { text: "No office", value: "8" },
        ],
        render: (text, record) => (
          <span>
            <Tag color={record.status === 1 ? "green" : "orange"}>
              {record.status_text}
            </Tag>
          </span>
        ),
      },
      {
        title: "Created at",
        dataIndex: "created_at",
        sorter: true,
        key: "created_at",
      },
      {
        title: "Action",
        key: "action",
        width: 150,
        fixed: "right",
        render: (text, record) => (
          <span>
            {record.status === 1 && this.state.retailerrole === false ? (
              <Tooltip title="Deactivate User">
                <Button
                  type="danger"
                  shape="circle"
                  className="padd5 mr5"
                  icon="user"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    console.log(record.id);
                    Modal.confirm({
                      title: "Are you sure you want to deactivate the user?",
                      // content: ,
                      okText: "Yes",
                      cancelText: "No",
                      onOk: () => {
                        console.log(`Ok`);
                        this.props
                          .Employeeaction(record.id, "0")
                          .then((res) => {
                            this.getdesignation({
                              results: 10,
                              page: 1,
                            });
                            message.success("User inactivate successfully!");
                          })
                          .catch((err) => {
                            message.error(err);
                            console.log(err);
                          });
                      },
                    });
                  }}
                ></Button>
              </Tooltip>
            ) : record.status === 0 && this.state.retailerrole === false ? (
              <Tooltip title="Activate User">
                <Button
                  type="primary"
                  shape="circle"
                  icon="user"
                  className="padd5 mr5"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    console.log(record.id);
                    Modal.confirm({
                      title: "Are you sure you want to activate the user?",
                      // content: "Do you want to active user ?",
                      okText: "Yes",
                      cancelText: "No",
                      onOk: () => {
                        console.log(`Ok`);
                        this.props
                          .Employeeaction(record.id, "1")
                          .then((res) => {
                            this.getdesignation({
                              results: 10,
                              page: 1,
                            });
                            message.success("User Updated successfully!");
                          })
                          .catch((err) => {
                            message.error(err);
                            console.log(err);
                          });
                      },
                    });
                  }}
                ></Button>
              </Tooltip>
            ) : (
              <Button
                type="primary"
                shape="circle"
                icon="user"
                className="padd5 mr5"
                disabled
              ></Button>
            )}

            <Divider type="vertical" />
            {this.state.superrole === true ? (
              <>
                {/* {record.role_info
                  .filter((e) => e.role_id === 5)
                  .map((role) => (
                    <Link
                      style={{ marginRight: 10 }}
                      to={{ pathname: "/editemployee", employee: record }}
                    >
                      <Tooltip title="Edit User">
                        <Button type="primary" shape="circle" icon="edit" />
                      </Tooltip>
                    </Link>
                  ))} */}
              </>
            ) : (this.state.retailerrole === true ||
                this.state.marketrole === true) &&
              record.status === 1 ? (
              <Link
                style={{ marginRight: 10 }}
                to={{ pathname: "/editemployee", employee: record }}
              >
                <Tooltip title="Edit User">
                  <Button type="primary" shape="circle" icon="edit" />
                </Tooltip>
              </Link>
            ) : (
              <Link disabled style={{ marginRight: 10 }}>
                <Tooltip title="Edit User">
                  <Button type="primary" disabled shape="circle" icon="edit" />
                </Tooltip>
              </Link>
            )}
            {record.role_info
              .filter((e) => e.role_id === 2)
              .map((role) => (
                <Tooltip title="Change Office">
                  <Button
                    onClick={() => this.showDrawer(record)}
                    type="primary"
                    shape="circle"
                    icon="shop"
                  />
                </Tooltip>
              ))}
          </span>
        ),
      },
    ];

    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            <div className="col-sm-4">
              <h4>
                Users{" "}
                {this.state.pagination.total &&
                  `(${this.state.pagination.total})`}
              </h4>
            </div>

            <div className="col-md-8">
              <h6 className="mb-4 text-right" style={{ fontSize: 13 }}>
                <Icon type="clock-circle" /> Last Updated at: {dashdata ?? ""}
              </h6>
            </div>

            {/* <div className="col-sm-8 text-right"> */}
            <div className="col text-right">
              {/* {this.rendercsvformat(data)} */}
              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingEPbtn}
                onClick={() => this.getexportbtn()}
              >
                <i className="uil uil-export" /> Export Report
              </Button>
              <Button
                type="default"
                className="mr5"
                onClick={() => this.getrefreshbtn()}
              >
                <i className="uil uil-sync" /> Refresh
              </Button>

              <Link to="/addEmployee">
                <Button type="primary">Add User</Button>
              </Link>
            </div>
          </div>
          <div className="row padd20 bg-grey">
            <div class="col">
              <label>Select Role</label>
              <Select
                showSearch
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Select Role"
                value={this.state.selectedItems}
                onChange={this.onchangeRole}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.renderRole()}
              </Select>
            </div>

            <div class="col">
              <label>Search By Keyword</label>
              <Input
                placeholder="Type Keyword"
                value={this.state.phone}
                onChange={(e) =>
                  this.setState({
                    phone: e.target.value,
                  })
                }
                style={{ width: "100%", display: "block" }}
              />
            </div>
            {/* <div class="col">
              <label>Select Retailer</label>
              <Select
                showSearch
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Select Retailer"
                value={this.state.selectedRetail}
                onChange={this.onchangeRetail}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.renderRetail()}
              </Select>
            </div> */}
            <div className="col mt-6">
              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingbtn}
                onClick={() => {
                  this.searchUser("reset");
                }}
              >
                Search
              </Button>
              <Button type="default" onClick={() => this.clearSearch()}>
                Clear
              </Button>
            </div>
          </div>
          <div className="bg-white padd0">
            <Table
              columns={columns}
              dataSource={this.state.Employees}
              scroll={{ x: true }}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
            />
          </div>
          <Drawer
            title="Agent Office Transfer"
            width={500}
            onClose={this.onClose}
            visible={this.state.visible}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Form onSubmit={this.handleSubmit} layout="vertical">
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Office">
                    {getFieldDecorator("office_id", {
                      initialValue: this.state.activeuser?.office_id ?? "",

                      rules: [
                        { required: true, message: "Please select office" },
                      ],
                    })(
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select Office Number"
                        onChange={this.officeOnChange}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.renderOffice()}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <div
                style={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  width: "100%",
                  borderTop: "1px solid #e9e9e9",
                  padding: "10px 16px",
                  background: "#fff",
                  textAlign: "right",
                }}
              >
                <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                  Cancel
                </Button>
                <Button
                  loading={this.state.btnloading}
                  htmlType="submit"
                  type="primary"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </Drawer>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    userinfo: state.userinfo,
    allhubs: state.allhubs,
    data: state.data,
  };
}

const App = Form.create()(Employees);

export default connect(mapStateToProps, {
  getEmployeelist,
  searchEmployeelist,
  deleteEmployee,
  getRolelist,
  Employeeaction,
  getallsearchresult,
  getsortlistuser,
  user_export,
  getretailers,
  getofficelistname,
  changeofficeuser,
})(App);
