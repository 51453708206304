import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getagedinventorymarket,
  getagedinventorymarketsearch,
} from "../../actions/inventoryAction";
import { aged_by_market_export } from "../../actions/exportAction";
import _ from "lodash";
import { getibomarketlist } from "../../actions/assetAction";
import {
  Button,
  Table,
  Tag,
  Input,
  Select,
  Divider,
  Modal,
  Icon,
  message,
  Skeleton,
  Spin,
} from "antd";
import { getsortlistinventory } from "../../actions/assetAction";

const { Option } = Select;
class AgedInventory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      marketer: [],
      data: [],
      pagination: {},
      loading: true,
      loadingbtn:false,
      loadingEPbtn:false,
      retailview: false,
      retailer: [],
      selectedItems: [],
      selectedfieldname: [],
      selectedfilter: [],
      sort_list: [],
      market: [],
      aged_inventory: [],
    };
  }

  componentWillMount() {
    this.getdesignation({
      url: this.props.location.urlpath ?? "",
      results: 10,
      page: 1,
    });
    // this.setColumns();
    this.props.getibomarketlist().then((res) => {
      console.log(res.data);
      this.setState({
        market: res.data,
      });
    });
    const Retailer =
      this.props?.user?.role_id?.filter((word) => word === 5) ?? [];
      if (Retailer?.length !== 0) {
        this.setState({
          retailview: true,
        });
      }
  }

  getdesignation(params = {}) {
    this.props
      .getagedinventorymarket(params)
      .then((res) => {
        console.log(res);
        this.setData(res.data);
        this.setState({
          aged_inventory: res.data.grand_totals,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }
  handleTableChange = (pagination, filter, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      sortField: sorter.order === undefined ? "" : sorter.field,
      sortOrder:
        sorter.order === "ascend"
          ? "ASC"
          : sorter.order === undefined
          ? ""
          : "DESC",
    });
    setTimeout(() => {
      this.searchMarketer();
    }, 100);
  };

  onchangeRole = (selectedItems) => {
    this.setState({ selectedItems });
  };
  onchangefieldname = (selectedfieldname) => {
    this.setState({ selectedfieldname });
    console.log(this.state.selectedfieldname);
  };
  onchangefilter = (selectedfilter) => {
    this.setState({ selectedfilter });
    console.log(this.state.selectedfilter);
  };
  renderSorter() {
    return _.map(this.state.columns, (master, index) => {
      return (
        <Option value={master.key} key={index}>
          {master.title}
        </Option>
      );
    });
  }

  renderOffice() {
    return _.map(this.state.market, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  clearSearch = () => {
    this.setState({ loading: true });
    this.setState({
      selectedItems: [],
      selectedfieldname: [],
      selectedfilter: [],
    });
    this.getdesignation({
      results: 10,
      page: 1,
    });
  };

  searchMarketer = (reset) => {
    this.setState({ loading: true, loadingbtn:true });
    var market = this.state.selectedItems;
    var sort = this.state.sortField;
    var sort_by = this.state.sortOrder;

    this.props
      .getagedinventorymarketsearch({
        market: market,
        page: reset ? 1 : this.state.pagination?.current ?? 1,
        sort,
        sort_by,
      })
      .then((res) => {
        console.log(res);
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.items.total;
        pagination.current = res.data.items.current_page;
        this.setData(res.data);
        this.setState({
          aged_inventory: res.data.grand_totals,
          loadingbtn:false,
        });
      })
      .catch((err) => {});
  };

  setData = (resData) => {
    const pagination = { ...this.state.pagination };
    pagination.total = resData.items.total;
    pagination.current = resData.items.current_page;

    this.setState({
      data: resData.items.data,
      loading: false,
      loadingbtn:false,
      pagination,
    });
  };

  getexportbtn = () => {
    var market = this.state.selectedItems;
    this.setState({ loading: true, loadingEPbtn:true });
    this.props
      .aged_by_market_export({ market: market })
      .then((res) => {
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
        this.setState({ loading: false, loadingEPbtn:false });
      })
      .catch((err) => {
        this.setState({ loading: false, loadingEPbtn:false });
      });
  };
  // ======================================================

  render() {
    const { aged_inventory,retailview } = this.state;
    const columns = [
      {
        title: "IBOs / Markets",
        dataIndex: "ibo_name",
        key: "ibo_name",
        sorter: true,
        render: (ibo_name, record) => (
          <Link
            to={{
              pathname: "/agedinventoryoffice",
              urlpath: `/api/v1/aged_inventory_summary_reports/office?ibo_marketer_id=${record.ibo_marketer_id}`,
            }}
          >
            {ibo_name}
          </Link>
        ),
      },
     
      {
        dataIndex: "Product_Type",
        key: "Product_Type",
        sorter: true,
        title: "Device Type",
        // hidden: !retailview,
      },
      {
        title: "0-30 DAYS",
        dataIndex: "days1_15",
        key: "days1_15",
        sorter: true,
      },
      {
        title: "31-45 DAYS",
        dataIndex: "days16_30",
        key: "days16_30",
        sorter: true,
      },
      {
        title: "46-60 DAYS",
        dataIndex: "days31_45",
        key: "days31_45",
        sorter: true,
      },
      {
        title: "61-90 DAYS",
        dataIndex: "days46_90",
        key: "days46_90",
        sorter: true,
      },
      {
        title: ">90 DAYS",
        dataIndex: "days91",
        key: "days91",
        sorter: true,
      },
    ].filter((item) => !item.hidden);

    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            <div className="col-sm-4">
              <h4>
                Aged by IBOs/Markets{" "}
                {this.state.pagination.total &&
                  `(${this.state.pagination.total})`}
              </h4>
            </div>
            <div className="col-sm-8 text-right">
              {/* {this.rendercsvformat(data)} */}
              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingEPbtn}
                onClick={() => this.getexportbtn()}
              >
                <i className="uil uil-export" /> Export Report
              </Button>
            </div>
          </div>
          <Spin spinning={this.state.loading}>
            <div className="row mt-4">
              <div className="col">
                <div className="border shadow-sm mb-2">
                  <div className="bg-primary p-2">
                    <h5 className="mb-0 text-white text-center">0-30 days</h5>
                  </div>
                  <div className="p-3 bg-white">
                    <h4 className="text-center mb-0">
                      {aged_inventory.days1_15}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="border shadow-sm mb-2">
                  <div className="bg-primary p-2">
                    <h5 className="mb-0 text-white text-center">31-45 days</h5>
                  </div>
                  <div className="p-3 bg-white">
                    <h4 className="text-center mb-0">
                      {aged_inventory.days16_30}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="border shadow-sm mb-2">
                  <div className="bg-primary pt-2 pb-2">
                    <h5 className="mb-0 text-white text-center">46-60 days</h5>
                  </div>
                  <div className="p-3 bg-white">
                    <h4 className="text-center mb-0">
                      {aged_inventory.days31_45}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="border shadow-sm mb-2">
                  <div className="bg-primary p-2">
                    <h5 className="mb-0 text-white text-center">61-90 days</h5>
                  </div>
                  <div className="p-3 bg-white">
                    <h4 className="text-center mb-0">
                      {aged_inventory.days46_90}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="border shadow-sm mb-2">
                  <div className="bg-primary p-2">
                    <h5 className="mb-0 text-white text-center">
                      {" "}
                      &gt;90 days
                    </h5>
                  </div>
                  <div className="p-3 bg-white">
                    <h4 className="text-center mb-0">
                      {aged_inventory.days91}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
          <div className="row padd20 bg-grey">
            <div className="col-4">
              <label for="exampleFormControlInput1">
                Select IBOs / Markets
              </label>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select Option"
                value={this.state.selectedItems}
                onChange={this.onchangeRole}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.renderOffice()}
              </Select>
            </div>
            {/* <div class="col">
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select field name"
                value={this.state.selectedfieldname}
                onChange={this.onchangefieldname}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.renderSorter()}
              </Select>
            </div> */}
            {/* <div class="col">
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select filter order"
                value={this.state.selectedfilter}
                onChange={this.onchangefilter}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="ASC">ASC</Option>
                <Option value="DESC">DESC</Option>
              </Select>
            </div> */}
            <div className="col mt-6">
              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingbtn}
                onClick={() => this.searchMarketer("reset")}
              >
                Search
              </Button>
              <Button type="default" onClick={() => this.clearSearch()}>
                Clear
              </Button>
            </div>
          </div>
          <div className="bg-white padd0">
            <Table
              rowKey={Math.random().toString()}
              columns={columns}
              dataSource={this.state.data}
              scroll={{ x: true }}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    ibomarket: state.data,
    data: state.data,
  };
}

export default connect(mapStateToProps, {
  getagedinventorymarket,
  getsortlistinventory,
  getagedinventorymarketsearch,
  aged_by_market_export,
  getibomarketlist,
})(AgedInventory);
