import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {} from "../../actions/assetAction";
// import _ from "lodash";
import { Button, Form, InputNumber, Checkbox, message } from "antd";
import nProgress from "nprogress";

class PasswordSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: "",
      loading: false,
      editable: false,
    };
  }

  componentWillMount() {
    console.log(this.props.userinfo, this.props.userinfo.Permission);
    console.log(this.props.userinfo.Permission[32].Read);
    var userp = this.props.userinfo.Permission[32];
    if (!userp.Read) {
      this.props.history.push("/hubdashboard");
    } else {
      this.getsettings();
    }
  }
  getsettings = () => {
    nProgress.start();
    this.props.getpasswordsettings().then((res) => {
      // console.log(res);
      this.setState({
        settings: res,
      });
      nProgress.done();
    });
  };

  onChange(value) {
    // console.log(`selected ${value}`);
  }

  resetsettings = () => {
    this.props.form.resetFields();
    this.setState({ editable: false });
    // this.getsettings();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      // console.log("Received values of form: ", values);
      this.setState({
        loading: true,
      });
      this.props
        .passwordsettings(values)
        .then((res) => {
          message.success("Updated settings successfully!");
          this.setState({
            loading: false,
            editable: false,
          });
          this.getsettings();
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            loading: false,
            editable: false,
          });
        });
    });
  };
  // ======================================================

  render() {
    const layout = {
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
    };
    const { getFieldDecorator } = this.props.form;
    const { settings, loading, editable } = this.state;
    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            <div className="col-sm-4">
              <h4>Password Settings</h4>
            </div>
          </div>
          <div className="bg-white padd0" style={{ paddingTop: "20px" }}>
            <Form {...layout} onSubmit={this.handleSubmit} className="row">
              <div className="col-md-6">
                <Form.Item label="Minimum Number of Length">
                  {getFieldDecorator("MaxLength", {
                    initialValue: settings !== "" ? settings.MaxLength : "",
                    rules: [
                      {
                        required: true,
                        message: "Please input Minimum Number of Length!",
                      },
                    ],
                  })(
                    <InputNumber
                      placeholder="Enter minimum length"
                      readOnly={!editable}
                    />
                  )}
                </Form.Item>
                <Form.Item label="User Lock Attempts">
                  {getFieldDecorator("LockAttempts", {
                    initialValue: settings !== "" ? settings.LockAttempts : "",
                    rules: [
                      {
                        required: true,
                        message: "Please input User Lock Attempts!",
                      },
                    ],
                  })(
                    <InputNumber
                      placeholder="Enter lock attempts"
                      readOnly={!editable}
                    />
                  )}
                </Form.Item>
                <Form.Item label="User Lock Time(min)">
                  {getFieldDecorator("LockTime", {
                    initialValue: settings !== "" ? settings.LockTime : "",
                    rules: [
                      {
                        required: true,
                        message: "Please input User Lock Time!",
                      },
                    ],
                  })(
                    <InputNumber
                      placeholder="Enter lock time"
                      readOnly={!editable}
                      minValue={5}
                    />
                  )}
                </Form.Item>
                <Form.Item label="Password Reset Days">
                  {getFieldDecorator("PwdResetDays", {
                    initialValue: settings !== "" ? settings.PwdResetDays : "",
                    rules: [
                      {
                        required: true,
                        message: "Please input Password Reset Days!",
                      },
                    ],
                  })(
                    <InputNumber
                      placeholder="Enter reset days"
                      readOnly={!editable}
                    />
                  )}
                </Form.Item>
                <Form.Item label="Maximum Bag weight">
                  {getFieldDecorator("MaxBagWeight", {
                    initialValue: settings !== "" ? settings.MaxBagWeight : 0,
                    rules: [
                      {
                        required: true,
                        message: "Please input Maximum Bag weight!",
                      },
                    ],
                  })(
                    <InputNumber
                      placeholder="Enter MaxBagWeight"
                      readOnly={!editable}
                    />
                  )}
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item>
                  {getFieldDecorator("isNumAllowed", {
                    initialValue:
                      settings !== "" ? settings.isNumAllowed : false,
                    valuePropName: "checked",
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <Checkbox onChange={this.onChange} disabled={!editable}>
                      Numerics are Allowed
                    </Checkbox>
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator("isSplCharAllowed", {
                    initialValue:
                      settings !== "" ? settings.isSplCharAllowed : false,
                    valuePropName: "checked",
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <Checkbox onChange={this.onChange} disabled={!editable}>
                      Special characters are Allowed
                    </Checkbox>
                  )}
                </Form.Item>
              </div>
              <div className="padd15 Col-md-12 col-xs-12">
                {editable ? (
                  <>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="mr5"
                      loading={loading}
                    >
                      Update
                    </Button>
                    <Button
                      type="default"
                      htmlType="button"
                      onClick={() => this.resetsettings()}
                    >
                      Cancel
                    </Button>
                  </>
                ) : null}
                {!editable ? (
                  <Button
                    type="default"
                    htmlType="button"
                    onClick={() => this.setState({ editable: true })}
                  >
                    Edit Settings
                  </Button>
                ) : null}
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    userinfo: state.userinfo,
  };
}
const WrappedComponent = Form.create({})(PasswordSettings);
export default connect(mapStateToProps, {})(WrappedComponent);
