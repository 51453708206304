import React, { Component } from "react";
import { connect } from "react-redux";

import "../styles/css/App.css";
import Logo from "../styles/img/wies-logo.svg";
import Loginimg from "../styles/img/resetpassword.svg";
import { Button, message, Form, Input, Modal } from "antd";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      loading: false,
      confirmDirty: false,
      pwdsettings: "",
      pwdstatus: "",
      pwdinfo: "",
      uinfo: "",
    };
    // bind
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    var res = this.props.match.params.id;
    // console.log(res.substring(1));

    if (res !== undefined) {
      console.log(res.substring(1));
      this.props
        .getresetuserinfo(res.substring(1))
        .then((res) => {
          console.log(res);
          this.setState({
            uinfo: res,
          });
        })
        .catch((err) => {
          message.error("Invalid link!");
        });
    } else {
      this.props.history.push("/");
    }

    this.props.getpasswordsettings().then((res) => {
      console.log(res);
      this.setState({
        pwdsettings: res,
      });
      var isnum = res.isNumAllowed;
      var issplchar = res.isSplCharAllowed;
      var text = "";
      if (isnum && issplchar) {
        text = "atleast one number and one special character";
      } else if (issplchar) {
        text = "atleast one special character";
      } else if (isnum) {
        text = "atleast one number";
      }
      this.setState({
        pwdinfo: text,
      });
    });
  }

  navigate() {
    console.log(this.state.uinfo);
    // var res = this.state.uinfo;
    Modal.destroyAll();
    this.props.history.push("/");
    // if (res.designation < 10) {
    //     this.props.history.push("/");
    // } else {
    //     window.close()
    // }
  }

  pwdvalidation = (e) => {
    // console.log(e.target.value, this.state.pwdsettings);
    var value = e.target.value;
    var maxlength = this.state.pwdsettings.MaxLength;
    var isnum = this.state.pwdsettings.isNumAllowed;
    var issplchar = this.state.pwdsettings.isSplCharAllowed;
    var isvalid = "";
    if (isnum && issplchar) {
      // console.log("Cond 1")
      isvalid =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*?&.])[A-Za-z\d$@$!%*?&.]{2,}$/;
    } else if (issplchar) {
      // console.log("Cond 2 Spl char ")
      isvalid = /^(?=.*[A-Za-z])(?=.*[$@$!%*?&.])[A-Za-z\d$@$!%*?&.]{2,}$/;
    } else if (isnum) {
      // console.log("Cond 3 only num ")
      isvalid = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d$@$!%*?&.]{2,}$/;
    }

    this.setState({
      pwdstatus: "validating",
    });
    if (value.match(isvalid) && maxlength <= value.length) {
      this.props.form.setFieldsValue({
        password: value,
      });
      this.setState({
        pwdstatus: "success",
      });
    } else {
      this.setState({
        pwdstatus: "error",
      });
    }
  };

  // handle change
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  // handle submit
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log("Received values of form: ", values);
      if (!err) {
        this.setState({
          loading: true,
        });
        this.props
          .changepassword(
            values.Email,
            this.state.uinfo.authId,
            values.password
          )
          .then((res) => {
            console.log(res);
            Modal.success({
              centered: true,
              title: "Password updated successfully!",
              content: (
                <div>
                  <p>Your account password has been changed successfully.</p>
                  <Button type="primary" onClick={() => this.navigate()}>
                    Return to Login
                  </Button>
                </div>
              ),
              footer: null,
            });
            this.setState({
              loading: false,
            });
            this.props.form.resetFields();
          })
          .catch((err) => {
            console.log(err);
            message.error(err);
            this.setState({
              loading: false,
            });
          });
      }
    });
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { pwdinfo, pwdsettings, pwdstatus, uinfo } = this.state;
    return (
      <div className="cover-full">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7 hidden-sm hidden-xs padd0">
              <div className="img-flex bg-red">
                <img src={Loginimg} className="loginimg" alt="WiESSystem" />
              </div>
            </div>
            <div className="col-md-5 col-sm-12 col-xs-12 bg-white">
              <div className="panel-flex">
                <div className="panel panel-default loginpanel">
                  <div className="panel-body">
                    <img
                      src={Logo}
                      className="logologin mb0"
                      alt="WiESSystem"
                    />
                    <h4 className="text-left">Reset Password</h4>
                    <p>
                      Enter your email and new password to reset account access.
                    </p>
                    <Form onSubmit={this.handleSubmit} className="resetform">
                      <Form.Item label="E-mail">
                        {getFieldDecorator("Email", {
                          initialValue: uinfo !== "" ? uinfo.Email : "",
                          rules: [
                            {
                              type: "email",
                              message: "Enter valid E-mail!",
                            },
                            {
                              required: true,
                              message: "Please input your E-mail!",
                            },
                          ],
                        })(
                          <Input
                            placeholder="Email"
                            type="email"
                            autoComplete="new-password"
                            readOnly
                          />
                        )}
                      </Form.Item>
                      <Form.Item
                        label="Password"
                        className="passwordinput"
                        hasFeedback
                        extra={
                          "Password must contain " +
                          pwdinfo +
                          " and minimum length of " +
                          (pwdsettings !== "" ? pwdsettings.MaxLength : 6)
                        }
                        validateStatus={pwdstatus}
                      >
                        {getFieldDecorator("password", {
                          rules: [
                            {
                              required: true,
                              message: "Please input your password!",
                            },
                            {
                              validator: this.validateToNextPassword,
                            },
                          ],
                        })(
                          <Input.Password
                            type="password"
                            autoComplete="new-password"
                            placeholder="Password"
                            onChange={(e) => this.pwdvalidation(e)}
                          />
                        )}
                      </Form.Item>
                      <Form.Item
                        label="Confirm Password"
                        className="passwordinput"
                        hasFeedback
                      >
                        {getFieldDecorator("confirm", {
                          rules: [
                            {
                              required: true,
                              message: "Please confirm your password!",
                            },
                            {
                              validator: this.compareToFirstPassword,
                            },
                          ],
                        })(
                          <Input.Password
                            type="password"
                            placeholder="Confirm Password"
                            autoComplete="new-password"
                            onBlur={this.handleConfirmBlur}
                            onChange={(e) => this.pwdvalidation(e)}
                          />
                        )}
                      </Form.Item>
                      <Form.Item>
                        <Button
                          htmlType="submit"
                          type="primary"
                          loading={this.state.loading}
                          block
                        >
                          Update Password
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const WrappedResetPassword = Form.create({ name: "ResetPassword" })(
  ResetPassword
);

function mapStateToProps(state, ownProps) {
  return { user: state.user, userinfo: state.userinfo };
}

export default connect(mapStateToProps, {})(WrappedResetPassword);
