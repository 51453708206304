import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Input, message, Button } from "antd";
import { updatepassword } from "../actions/userAction";

import _ from "lodash";

class Profile extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      loading: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {}

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values, this.state.userinfo);
        this.setState({ loading: true });
        this.props
          .updatepassword(values)
          .then((res) => {
            console.log(res);
            this.props.form.resetFields();
            message.success("Password updated successfully!");
            this.setState({ loading: false, IsPwdUpdated: true, email: "" });
          })
          .catch((err) => {
            console.log(err);
            message.error(
              "Password must satisfy all the mentioned conditions!"
            );
            this.setState({ loading: false });
          });
      }
    });
  };

  render() {
    const { loading } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="container-fluid">
        <div className="row padd20 bg-grey">
          <div className="col-xs-4">
            <h4>Profile</h4>
          </div>
          <div className="col-xs-8 text-right">
            {/* <Button type="primary" className="mr5">
              <Icon type="sync" />
            </Button>
            <Button type="primary" onClick={this.addbooking}>
              Add Booking
            </Button> */}
          </div>
        </div>

        <div className="container-fluid padd15" style={{ padding: "15px 0" }}>
          <div className="resetpasswordwrapper bg-white padd15">
            <div className="accounttitle ">
              <h4>Change Password</h4>
              <p>Please Input your new password.</p>
            </div>
            <div className="editProfileDiv padd15">
              <Form onSubmit={this.handleSubmit} className="row">
                <Form.Item label="Old Password">
                  {getFieldDecorator("oldpassword", {
                    rules: [
                      {
                        required: true,
                        message: "Please input Old Password!",
                      },
                    ],
                  })(
                    <Input.Password
                      placeholder="Enter Password"
                      minLength="6"
                      autoComplete="new-password"
                    />
                  )}
                </Form.Item>
                <Form.Item label="New Password">
                  {getFieldDecorator("newpassword", {
                    rules: [
                      {
                        required: true,
                        message: "Please input New Password!",
                      },
                    ],
                  })(
                    <Input.Password
                      placeholder="Enter Password"
                      minLength="6"
                      autoComplete="new-password"
                    />
                  )}
                </Form.Item>
                <div className="col-12 text-left">
                  <Button
                    type="primary"
                    loading={loading}
                    className="btn mb-0 btn-block"
                    htmlType="submit"
                  >
                    Update password
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user };
}
const WrappedForm = Form.create()(Profile);

export default connect(mapStateToProps, { updatepassword })(WrappedForm);
