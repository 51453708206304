import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, Menu, Icon } from "antd";
import { Link } from "react-router-dom";
import createHistory from "history/createBrowserHistory";
import Logo from "../styles/img/AIlogx.png";

// import { getHub } from "../actions/assetAction";

const SubMenu = Menu.SubMenu;
const rootKeys = [
  "sub1",
  "sub2",
  "sub3",
  "sub4",
  "sub5",
  "sub6",
  "sub7",
  "sub8",
  "sub9",
  "sub10",
  "sub11",
  "sub12",
  "sub13",
  "sub14",
  "sub15",
  "sub16",
  "sub17",
  "sub18",
];
const { Sider } = Layout;

class Navi extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      current: "1",
      collapsed: false,
      loading: true,
      permission: "",
      retailview: false,
      marketview: false,
      superuser: false,
      superadmin: false,
      openKeys: [],
      defaultOpenKeys: ["sub10"],
      marketmanagerview: false,
    };
  }

  componentWillMount() {
    const history = createHistory();
    const location = history.location;

    const superadmin =
      this.props?.user?.role_id?.filter((word) => word === 10) ?? [];
    const masteruser =
      this.props?.user?.role_id?.filter((word) => word === 1) ?? [];
    const Retailer =
      this.props?.user?.role_id?.filter((word) => word === 5) ?? [];
    const marketer =
      this.props?.user?.role_id?.filter((word) => word === 4 || word === 8) ??
      [];
    const marketmanager =
      this.props?.user?.role_id?.filter((word) => word === 4) ?? [];
    console.log("Retailerview", Retailer);
    console.log("marketer", marketer);
    if (Retailer?.length !== 0) {
      this.setState({
        retailview: true,
      });
    }
    if (marketer?.length !== 0) {
      this.setState({
        marketview: true,
      });
    }
    if (masteruser?.length !== 0) {
      this.setState({
        superuser: true,
      });
    }
    if (marketmanager?.length !== 0) {
      this.setState({
        marketmanagerview: true,
      });
    }
    if (superadmin?.length !== 0) {
      this.setState({
        superadmin: true,
      });
    }

    this.setState({
      current: location.pathname,
      role_id: this.props.user.role_id,
    });
  }

  componentDidMount() {
    const history = createHistory();
    const location = history.location;
    console.log(location);
    this.setState({
      current: location.pathname,
    });
  }

  handleClick = (e) => {
    // console.log('click ', e);
    this.setState({
      current: e.key,
    });
  };
  onCollapse = (collapsed) => {
    console.log(collapsed);
    this.setState({ collapsed });
  };
  onOpenChange = (items) => {
    console.log(items);
    const latestOpenKey = items.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    );
    console.log(latestOpenKey);
    this.setState({ openKeys: [items[items.length - 1]] });
    // if (rootKeys.indexOf(latestOpenKey) === -1) {
    // } else {
    //   this.setState(
    //     latestOpenKey ? [latestOpenKey] : this.state.defaultOpenKeys
    //   );
    // }
  };
  render() {
    console.log(this.state.openKeys);
    return (
      <Sider
        width={250}
        collapsed={this.state.collapsed}
        onCollapse={this.onCollapse}
        collapsedWidth="80"
      >
        <div className="logo">
          <img src={Logo} className="img-responsive mainlogo" alt="Logo" />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          onClick={this.handleClick}
          selectedKeys={[this.state.current]}
          style={{ lineHeight: "64px" }}
          openKeys={this.state.openKeys}
          onOpenChange={this.onOpenChange}
          defaultOpenKeys={this.state.defaultOpenKeys}
        >
          {/* {this.state.role_id !== undefined && (this.state.role_id === 10
            || this.state.role_id === 1)
            ?  */}
          <Menu.Item key="/dashboard">
            <Link to="/dashboard">
              <Icon type="home" />
              <span
                style={{ textTransform: "uppercase", fontWeight: "700" }}
                className="nav-text"
              >
                Dashboard
              </span>
            </Link>
          </Menu.Item>

          {/* Sales Menu Retailer */}
          {this.state.superuser === true && (
            <SubMenu
              key="sub1"
              title={
                <span className="submenu-title-wrapper">
                  <Icon type="bar-chart" />
                  <span className="nav-text">Sales</span>
                </span>
              }
            >
              <Menu.Item key="/salesdashboard">
                <Link to="/salesdashboard">
                  <Icon type="bar-chart" />
                  <span className="nav-text">Dashboard</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/salessummaryreport">
                <Link to="/salessummaryreport">
                  <Icon type="bar-chart" />
                  <span className="nav-text">Detailed Report </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/salesRetailerReport">
                <Link to="/salesRetailerReport">
                  <Icon type="bar-chart" />
                  <span className="nav-text">By Retailers</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/salesmarketreport">
                <Link to="/salesmarketreport">
                  <Icon type="bar-chart" />
                  <span className="nav-text">By IBOs/Markets</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/salesofficereport">
                <Link to="/salesofficereport">
                  <Icon type="bar-chart" />
                  <span className="nav-text">By Offices</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/salesmanagerreport">
                <Link to="/salesmanagerreport">
                  <Icon type="bar-chart" />
                  <span className="nav-text">By Managers</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/salesagentreport">
                <Link to="/salesagentreport">
                  <Icon type="bar-chart" />
                  <span className="nav-text">By Agents</span>
                </Link>
              </Menu.Item>
            </SubMenu>
          )}

          {this.state.retailview === true && (
            <SubMenu
              key="sub2"
              title={
                <span className="submenu-title-wrapper">
                  <Icon type="bar-chart" />
                  <span className="nav-text">Sales</span>
                </span>
              }
            >
              <Menu.Item key="/salesdashboard">
                <Link to="/salesdashboard">
                  <Icon type="bar-chart" />
                  <span className="nav-text">Dashboard</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/salessummaryreport">
                <Link to="/salessummaryreport">
                  <Icon type="bar-chart" />
                  <span className="nav-text">Detailed Report</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/salesmarketreport">
                <Link to="/salesmarketreport">
                  <Icon type="bar-chart" />
                  <span className="nav-text">By IBOs/Markets</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/salesofficereport">
                <Link to="/salesofficereport">
                  <Icon type="bar-chart" />
                  <span className="nav-text">By Offices</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/salesmanagerreport">
                <Link to="/salesmanagerreport">
                  <Icon type="bar-chart" />
                  <span className="nav-text">By Managers</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/salesagentreport">
                <Link to="/salesagentreport">
                  <Icon type="bar-chart" />
                  <span className="nav-text">By Agents</span>
                </Link>
              </Menu.Item>
            </SubMenu>
          )}

          {/* Agents Menu Retailer */}
          {this.state.retailview === true && (
            <SubMenu
              key="sub3"
              title={
                <span className="submenu-title-wrapper">
                  <Icon type="bar-chart" />
                  <span className="nav-text">Onboarding</span>
                </span>
              }
            >
              <Menu.Item key="/agentdashboard">
                <Link to="/agentdashboard">
                  <Icon type="bar-chart" />
                  <span className="nav-text">Dashboard</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/sendinvites">
                <Link to="/sendinvites">
                  <Icon type="bar-chart" />
                  <span className="nav-text">Send Invite</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/OnboardingSummaryReport">
                <Link to="/OnboardingSummaryReport">
                  <Icon type="bar-chart" />
                  <span className="nav-text">Onboarding Summary</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/agentsummaryreport">
                <Link to="/agentsummaryreport">
                  <Icon type="bar-chart" />
                  <span className="nav-text">Agent Summary</span>
                </Link>
              </Menu.Item>
            </SubMenu>
          )}

          {/* Sales Menu Marketer */}

          {this.state.marketview === true && (
            <SubMenu
              key="sub5"
              title={
                <span className="submenu-title-wrapper">
                  <Icon type="bar-chart" />
                  <span className="nav-text">Sales</span>
                </span>
              }
            >
              <Menu.Item key="/salesdashboard">
                <Link to="/salesdashboard">
                  <Icon type="bar-chart" />
                  <span className="nav-text">Dashboard</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/salessummaryreport">
                <Link to="/salessummaryreport">
                  <Icon type="bar-chart" />
                  <span className="nav-text">Detailed Report</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/salesofficereport">
                <Link to="/salesofficereport">
                  <Icon type="bar-chart" />
                  <span className="nav-text"> By Offices</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/salesmanagerreport">
                <Link to="/salesmanagerreport">
                  <Icon type="bar-chart" />
                  <span className="nav-text"> By Managers</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/salesagentreport">
                <Link to="/salesagentreport">
                  <Icon type="bar-chart" />
                  <span className="nav-text"> By Agents</span>
                </Link>
              </Menu.Item>
            </SubMenu>
          )}
          {/* Agents Menu Marketer */}
          {this.state.marketview === true && (
            <SubMenu
              key="sub4"
              title={
                <span className="submenu-title-wrapper">
                  <Icon type="bar-chart" />
                  <span className="nav-text">Onboarding</span>
                </span>
              }
            >
              <Menu.Item key="/agentDashboard">
                <Link to="/agentdashboard">
                  <Icon type="bar-chart" />
                  <span className="nav-text">Dashboard</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/sendinvites">
                <Link to="/sendinvites">
                  <Icon type="bar-chart" />
                  <span className="nav-text">Send Invite</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/OnboardingSummaryReport">
                <Link to="/OnboardingSummaryReport">
                  <Icon type="bar-chart" />
                  <span className="nav-text">Onboarding Summary</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/agentsummaryreport">
                <Link to="/agentsummaryreport">
                  <Icon type="bar-chart" />
                  <span className="nav-text">Agent Summary</span>
                </Link>
              </Menu.Item>
            </SubMenu>
          )}

          {/* Inventory Menu Retailer */}

          {this.state.retailview === true && (
            <SubMenu
              key="sub6"
              title={
                <span className="submenu-title-wrapper">
                  <Icon type="tablet" />
                  <span className="nav-text">Inventory</span>
                </span>
              }
            >
              <Menu.Item key="/inventorydashboard">
                <Link to="/inventorydashboard">
                  <Icon type="tablet" />
                  <span className="nav-text">Dashboard</span>
                </Link>
              </Menu.Item>
              {/* <Menu.Item key="/inventoryorder">
                <Link to="/inventoryorder">
                  <Icon type="tablet" />
                  <span className="nav-text"> Inventory Orders</span>
                </Link>
              </Menu.Item> */}
              {/* <Menu.Item key="/newinventoryorder">
                <Link to="/newinventoryorder">
                  <Icon type="tablet" />
                  <span className="nav-text">Initiate Request Order</span>
                </Link>
              </Menu.Item> */}
              <Menu.Item key="/rmaorders">
                <Link to="/rmaorders">
                  <Icon type="tablet" />
                  <span className="nav-text">Order Management</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/rmarequestapprove">
                <Link to="/rmarequestapprove">
                  <Icon type="tablet" />
                  <span className="nav-text"> RMA Status</span>
                </Link>
              </Menu.Item>
              {/* <Menu.Item key="/rmareport">
                <Link to="/rmareport">
                  <Icon type="tablet" />
                  <span className="nav-text"> RMA Report</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/ramrequestapproval">
                <Link to="/ramrequestapproval">
                  <Icon type="tablet" />
                  <span className="nav-text">RMA Request Approval</span>
                </Link>
              </Menu.Item> */}
            </SubMenu>
          )}
          {this.state.retailview === true && (
            <SubMenu
              key="sub14"
              title={
                <span className="submenu-title-wrapper">
                  <Icon type="tablet" />
                  <span className="nav-text">Inventory Reports</span>
                </span>
              }
            >
              <Menu.Item key="/detailinventory">
                <Link to="/detailinventory">
                  <Icon type="tablet" />
                  <span className="nav-text"> Detailed Report</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="/inventorybyibo">
                <Link to="/inventorybyibo">
                  <Icon type="tablet" />
                  <span className="nav-text"> By IBOs/Markets</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/inventorybyoffice">
                <Link to="/inventorybyoffice">
                  <Icon type="tablet" />
                  <span className="nav-text"> By Offices</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/inventorymanager">
                <Link to="/inventorymanager">
                  <Icon type="tablet" />
                  <span className="nav-text">By Managers</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/inventorybyagent">
                <Link to="/inventorybyagent">
                  <Icon type="tablet" />
                  <span className="nav-text">By Agents</span>
                </Link>
              </Menu.Item>
            </SubMenu>
          )}
          {this.state.retailview === true && (
            <SubMenu
              key="sub17"
              title={
                <span className="submenu-title-wrapper">
                  <Icon type="tablet" />
                  <span className="nav-text">Aged Inventory </span>
                </span>
              }
            >
              <Menu.Item key="/agedinventorymarket">
                <Link to="/agedinventorymarket">
                  <Icon type="tablet" />
                  <span className="nav-text"> Aged by IBOs/Markets</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="/agedinventoryoffice">
                <Link to="/agedinventoryoffice">
                  <Icon type="tablet" />
                  <span className="nav-text">Aged by Offices</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/agedbymanager">
                <Link to="/agedbymanager">
                  <Icon type="tablet" />
                  <span className="nav-text">Aged by Managers</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/agedbyagent">
                <Link to="/agedbyagent">
                  <Icon type="tablet" />
                  <span className="nav-text">Aged by Agents</span>
                </Link>
              </Menu.Item>
            </SubMenu>
          )}

          {this.state.marketview === true && (
            <SubMenu
              key="sub7"
              title={
                <span className="submenu-title-wrapper">
                  <Icon type="tablet" />
                  <span className="nav-text">Inventory</span>
                </span>
              }
            >
              <Menu.Item key="/inventorydashboard">
                <Link to="/inventorydashboard">
                  <Icon type="tablet" />
                  <span className="nav-text">Dashboard</span>
                </Link>
              </Menu.Item>
              {/* <Menu.Item key="/inventoryorder">
                <Link to="/inventoryorder">
                  <Icon type="tablet" />
                  <span className="nav-text"> Inventory Orders</span>
                </Link>
              </Menu.Item> */}
              {/* <Menu.Item key="/newinventoryorder">
                <Link to="/newinventoryorder">
                  <Icon type="tablet" />
                  <span className="nav-text"> Initiate Request Order</span>
                </Link>
              </Menu.Item> */}
              <Menu.Item key="/rmaorders">
                <Link to="/rmaorders">
                  <Icon type="tablet" />
                  <span className="nav-text">Order Management</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/rmarequestapprove">
                <Link to="/rmarequestapprove">
                  <Icon type="tablet" />
                  <span className="nav-text"> RMA Status</span>
                </Link>
              </Menu.Item>
              {/* <Menu.Item key="/rmareport">
                <Link to="/rmareport">
                  <Icon type="tablet" />
                  <span className="nav-text"> RMA Report</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/ramrequestapproval">
                <Link to="/ramrequestapproval">
                  <Icon type="tablet" />
                  <span className="nav-text">RMA Request Approval</span>
                </Link>
              </Menu.Item> */}
            </SubMenu>
          )}
          {this.state.marketview === true && (
            <SubMenu
              key="sub15"
              title={
                <span className="submenu-title-wrapper">
                  <Icon type="tablet" />
                  <span className="nav-text">Inventory Reports</span>
                </span>
              }
            >
              <Menu.Item key="/detailinventory">
                <Link to="/detailinventory">
                  <Icon type="tablet" />
                  <span className="nav-text"> Detailed Report</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/inventorybyoffice">
                <Link to="/inventorybyoffice">
                  <Icon type="tablet" />
                  <span className="nav-text"> By Offices</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/inventorymanager">
                <Link to="/inventorymanager">
                  <Icon type="tablet" />
                  <span className="nav-text">By Managers</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/inventorybyagent">
                <Link to="/inventorybyagent">
                  <Icon type="tablet" />
                  <span className="nav-text">By Agents</span>
                </Link>
              </Menu.Item>
            </SubMenu>
          )}
          {this.state.marketview === true && (
            <SubMenu
              key="sub12"
              title={
                <span className="submenu-title-wrapper">
                  <Icon type="tablet" />
                  <span className="nav-text">Aged Inventory </span>
                </span>
              }
            >
              <Menu.Item key="/agedinventoryoffice">
                <Link to="/agedinventoryoffice">
                  <Icon type="tablet" />
                  <span className="nav-text">Aged by Offices</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/agedbymanager">
                <Link to="/agedbymanager">
                  <Icon type="tablet" />
                  <span className="nav-text">Aged by Managers</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/agedbyagent">
                <Link to="/agedbyagent">
                  <Icon type="tablet" />
                  <span className="nav-text">Aged by Agents</span>
                </Link>
              </Menu.Item>
            </SubMenu>
          )}
          {this.state.superuser === true && (
            <SubMenu
              key="sub8"
              title={
                <span className="submenu-title-wrapper">
                  <Icon type="team" />
                  <span className="nav-text">Onboarding</span>
                </span>
              }
            >
              <Menu.Item key="/agentdashboard">
                <Link to="/agentdashboard">
                  <Icon type="team" />
                  <span className="nav-text">Dashboard</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/sendinvites">
                <Link to="/sendinvites">
                  <Icon type="team" />
                  <span className="nav-text">Send Invite</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="/OnboardingSummaryReport">
                <Link to="/OnboardingSummaryReport">
                  <Icon type="team" />
                  <span className="nav-text">Onboarding Summary</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="/radlinkissues">
                <Link to="/radlinkissues">
                  <Icon type="bar-chart" />
                  <span className="nav-text">RAD Linking Failures</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="/agentsummaryreport">
                <Link to="/agentsummaryreport">
                  <Icon type="bar-chart" />
                  <span className="nav-text">Agent Summary</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/telgoomap">
                <Link to="/telgoomap">
                  <Icon type="team" />
                  <span className="nav-text">Telgoo ID Mapping</span>
                </Link>
              </Menu.Item>
            </SubMenu>
          )}
          {this.state.superuser === true && (
            <SubMenu
              key="sub9"
              title={
                <span className="submenu-title-wrapper">
                  <Icon type="tablet" />
                  <span className="nav-text">Inventory</span>
                </span>
              }
            >
              <Menu.Item key="/inventorydashboard">
                <Link to="/inventorydashboard">
                  <Icon type="tablet" />
                  <span className="nav-text"> Dashboard</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="/inventoryallocation">
                <Link to="/inventoryallocation">
                  <Icon type="tablet" />
                  <span className="nav-text"> Inventory Allocation</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/existinginventoryupload">
                <Link to="/existinginventoryupload">
                  <Icon type="tablet" />
                  <span className="nav-text"> Existing Inventory Upload</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/purchaseorder">
                <Link to="/purchaseorder">
                  <Icon type="tablet" />
                  <span className="nav-text">Allocation Report </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/rmaorders">
                <Link to="/rmaorders">
                  <Icon type="tablet" />
                  <span className="nav-text">Order Management</span>
                </Link>
              </Menu.Item>
              {/* <Menu.Item key="/rmareport">
                <Link to="/rmareport">
                  <Icon type="tablet" />
                  <span className="nav-text"> RMA Report</span>
                </Link>
              </Menu.Item> */}
              <Menu.Item key="/rmarequestapprove">
                <Link to="/rmarequestapprove">
                  <Icon type="tablet" />
                  <span className="nav-text"> RMA Status</span>
                </Link>
              </Menu.Item>
            </SubMenu>
          )}
          {this.state.superuser === true && (
            <SubMenu
              key="sub16"
              title={
                <span className="submenu-title-wrapper">
                  <Icon type="tablet" />
                  <span className="nav-text">Inventory Reports</span>
                </span>
              }
            >
              <Menu.Item key="/detailinventory">
                <Link to="/detailinventory">
                  <Icon type="tablet" />
                  <span className="nav-text"> Detailed Report</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="/inventorybyretailer">
                <Link to="/inventorybyretailer">
                  <Icon type="tablet" />
                  <span className="nav-text">By Retailers</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/inventorybyibo">
                <Link to="/inventorybyibo">
                  <Icon type="tablet" />
                  <span className="nav-text">By IBOs/Markets</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/inventorybyoffice">
                <Link to="/inventorybyoffice">
                  <Icon type="tablet" />
                  <span className="nav-text">By Offices</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/inventorymanager">
                <Link to="/inventorymanager">
                  <Icon type="tablet" />
                  <span className="nav-text">By Managers</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/inventorybyagent">
                <Link to="/inventorybyagent">
                  <Icon type="tablet" />
                  <span className="nav-text">By Agents</span>
                </Link>
              </Menu.Item>
            </SubMenu>
          )}
          {this.state.superuser === true && (
            <SubMenu
              key="sub13"
              title={
                <span className="submenu-title-wrapper">
                  <Icon type="tablet" />
                  <span className="nav-text">Aged Inventory </span>
                </span>
              }
            >
              <Menu.Item key="/agedbyretailer">
                <Link to="/agedbyretailer">
                  <Icon type="tablet" />
                  <span className="nav-text"> Aged by Retailer</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/agedinventorymarket">
                <Link to="/agedinventorymarket">
                  <Icon type="tablet" />
                  <span className="nav-text">Aged by IBOs/Markets</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/agedinventoryoffice">
                <Link to="/agedinventoryoffice">
                  <Icon type="tablet" />
                  <span className="nav-text">Aged by Offices</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/agedbymanager">
                <Link to="/agedbymanager">
                  <Icon type="tablet" />
                  <span className="nav-text">Aged by Managers</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/agedbyagent">
                <Link to="/agedbyagent">
                  <Icon type="tablet" />
                  <span className="nav-text">Aged by Agents</span>
                </Link>
              </Menu.Item>
            </SubMenu>
          )}
          {this.state.retailview === true && (
            <SubMenu
              key="sub18"
              title={
                <span className="submenu-title-wrapper">
                  <Icon type="credit-card" />
                  <span className="nav-text">Payroll</span>
                </span>
              }
            >
              <Menu.Item key="/retailerpayroll">
                <Link to="/retailerpayroll">
                  <Icon type="idcard" />
                  <span className="nav-text">Retailer Payroll</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/cashpayments">
                <Link to="/cashpayments">
                  <Icon type="dollar" />
                  <span className="nav-text">Cash Payments</span>
                </Link>
              </Menu.Item>
            </SubMenu>
          )}
          <SubMenu
            key="sub10"
            title={
              <span className="submenu-title-wrapper">
                <Icon type="setting" />
                <span className="nav-text">Administration</span>
              </span>
            }
          >
            {this.state.superuser === true || this.state.superadmin === true ? (
              <Menu.Item key="/master">
                <Link to="/master">
                  <Icon type="home" />
                  <span className="nav-text">Masters</span>
                </Link>
              </Menu.Item>
            ) : null}
            {this.state.superuser === true || this.state.superadmin === true ? (
              <Menu.Item key="/distributors">
                <Link to="/distributors">
                  <Icon type="home" />
                  <span className="nav-text">Distributors</span>
                </Link>
              </Menu.Item>
            ) : null}
            {this.state.superuser === true || this.state.superadmin === true ? (
              <Menu.Item key="/retailers">
                <Link to="/retailers">
                  <Icon type="home" />
                  <span className="nav-text">Retailers</span>
                </Link>
              </Menu.Item>
            ) : null}
            {this.state.retailview === true ||
            this.state.superadmin === true ||
            this.state.superuser === true ? (
              <Menu.Item key="/marketer">
                <Link to="/marketer">
                  <Icon type="bar-chart" />
                  <span className="nav-text">IBO / Markets</span>
                </Link>
              </Menu.Item>
            ) : null}

            {this.state.retailview === true ||
            this.state.marketview === true ||
            this.state.superadmin === true ||
            this.state.superuser === true ? (
              <Menu.Item key="/office">
                <Link to="/office">
                  <Icon type="shop" />
                  <span className="nav-text">Offices </span>
                </Link>
              </Menu.Item>
            ) : null}
           
            {this.state.retailview === true ||
            this.state.marketview === true ||
            this.state.superuser === true ||
            this.state.superadmin === true ? (
              <Menu.Item key="/employees">
                <Link to="/employees">
                  <Icon type="user" />
                  <span className="nav-text">Users </span>
                </Link>
              </Menu.Item>
            ) : null}
             <Menu.Item key="/announcement">
              <Link to="/announcement">
                <Icon type="home" />
                <span className="nav-text">Announcement</span>
              </Link>
            </Menu.Item>
            {/* {this.state.retailview === true ||
            this.state.marketview === true ? (
              <Menu.Item key="/messaging">
                <Link to="#">
                  <Icon type="tablet" />
                  <span className="nav-text">Messaging</span>
                </Link>
              </Menu.Item>
            ) : null} */}
            {
              //this.state.retailview === true ||
              this.state.marketmanagerview === true ? (
                <Menu.Item key="/documents">
                  <Link to="/documents">
                    <Icon type="tablet" />
                    <span className="nav-text">Documents</span>
                  </Link>
                </Menu.Item>
              ) : null
            }
          </SubMenu>
          <Menu.Item key="/support">
            <Link to="/support">
              <Icon type="customer-service" theme="filled" />
              <span
                style={{ textTransform: "uppercase", fontWeight: "700" }}
                className="nav-text"
              >
                Support
              </span>
            </Link>
          </Menu.Item>
          {this.state.superadmin && (
            <SubMenu
              key="sub11"
              title={
                <span className="submenu-title-wrapper">
                  <Icon type="setting" />
                  <span className="nav-text">Settings</span>
                </span>
              }
            >
              <Menu.Item key="/userrole">
                <Link to="/userrole">
                  <Icon type="user" />
                  <span className="nav-text">User role</span>
                </Link>
              </Menu.Item>
            </SubMenu>
          )}
        </Menu>
      </Sider>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user, userinfo: state.userinfo };
}

export default connect(mapStateToProps, {})(Navi);
