//******************** file used to store/generate sets of urls used throughout the app, some are fully static others are based on environment************************** */

export const getApiPath = () => {
  let path = "retailer";
  const user = JSON.parse(localStorage.getItem("user")) ?? "";
  console.log(user);
  const role = user ? user?.role_id[0] ?? "" : "";
  console.log(role);
  switch (role) {
    case 1:
      path = { path: "retailer", redirecturl: "/inventorybyretailer" };
      break;
    case 5:
      path = { path: "ibo_marketer", redirecturl: "/inventorybyibo" };
      break;
    case 4:
      path = { path: "office", redirecturl: "/inventorybyoffice" };
      break;
    case 8:
      path = { path: "office", redirecturl: "/inventorybyoffice" };
      break;
    default:
      path = { path: "retailer", redirecturl: "/inventorybyretailer" };
      break;
  }
  return path;
};
