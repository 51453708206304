import AddEmployee from "../component/administrator/addEmployee";
import Retaildashboard from "../component/administrator/retaildashboard";
import Orderlist from "../component/inventory/orderlist";
import Rmaorders from "../component/inventory/rmaorders";
import Rmarequestapprove from "../component/inventory/rmarequestapprove";
import Rmareport from "../component/inventory/rmareport";
import Inventorydashboard from "../component/inventory/inventorydashboard";
import PortalDashboard from "../component/dashboard/portalDashboard";
import Inventoryupload from "../component/inventory/Inventoryupload";
import Agedinventory from "../component/inventory/agedinventory";
import Agedbyagent from "../component/inventory/agedbyagent";
import Agedbymanager from "../component/inventory/agedbymanager";
import Agedbyretailer from "../component/inventory/agedbyretailer";
import Agedinventorymarket from "../component/inventory/agedinventorymarket";
import Agedinventoryoffice from "../component/inventory/agedinventoryoffice";
import InventoryByIbo from "../component/inventory/InventoryByIbo";
import InventoryByOffice from "../component/inventory/InventoryByOffice";
import Inventorybyagent from "../component/inventory/inventorybyagent";
import Inventorybymanager from "../component/inventory/inventorybymanager";
import Inventorybyretailer from "../component/inventory/inventorybyretailer";
import DetailInventory from "../component/inventory/DetailInventory";
import Purchaseorderreport from "../component/inventory/purchaseorderreport";
import Salessummaryreport from "../component/salesreport/salessummaryreport";
import SalesRetailerReport1 from "../component/salesreport/salesRetailerReport1";
import Salesmarketreport from "../component/salesreport/salesmarketreport";
import Salesofficereport from "../component/salesreport/salesofficereport";
import Salesmanagerreport from "../component/salesreport/salesmanagerreport";
import Salesagentreport from "../component/salesreport/salesagentreport";
import SendInvites from "../component/agents/SendInvites";
import Onboardingsummaryreport from "../component/agents/onboardingreport";
import Radissues from "../component/agents/radissues";
import AgentSummaryReport from "../component/agents/AgentSummaryReport";
import AgentDashboard from "../component/agents/agentDashboard";
import Telgoomap from "../component/agents/telgoomap";
import Inventoryallocation from "../component/inventory/inventoryallocation";
import AgentDocuments from "../component/administrator/AgentDocuments";
import Support from "../component/SupportChat";
import TrainingComponent from "../component/TrainingComponent";
import Employees from "../component/administrator/Employee";
import EditEmployee from "../component/administrator/editEmployee";
import Profile from "../component/profile";
import Customers from "../component/administrator/customers";
import AddCustomer from "../component/administrator/addcustomer";
import EditCustomer from "../component/administrator/editcustomer";
import RoleList from "../component/settings/rolelist";
import CreateRole from "../component/settings/createrole";
import EditRole from "../component/settings/editrole";
import PasswordSettings from "../component/settings/passwordsettings";
import AddMarketer from "../component/administrator/addMarketer";
import Marketer from "../component/administrator/Marketer";
import AddOffice from "../component/administrator/addOffice";
import Office from "../component/administrator/Office";
import Master from "../component/administrator/master";
import Distributors from "../component/administrator/distributors";
import Retailers from "../component/administrator/retailers";
import EditMarketer from "../component/administrator/editMarketer";
import EditOffice from "../component/administrator/editOffice";
import Existinventoryupload from "../component/inventory/existinventoryupload";
import TrainingVideo from "../component/TrainingVideos";
import EditAgents from "../component/agents/editAgent";
import RetailerPayroll from "../component/payroll/RetailerPayroll";
import CashPayments from "../component/payroll/CashPayments";
import FAQ from "../component/FAQ";
import Announcement from "../component/administrator/Announcement";
import Error404 from "../component/error404";
export const approutes = [
  {
    path: "/addemployee",
    module: "employee",
    component: (props) => <AddEmployee {...props} />,
    roles: [1, 4, 5, 8, 10],
  },
  {
    path: "/profile",
    module: "employee",
    component: (props) => <Profile {...props} />,
    roles: [1, 4, 5, 8, 10],
  },
  {
    path: "/dashboard",
    module: "employee",
    component: (props) => <PortalDashboard {...props} />,
    roles: [1, 4, 5, 8, 10],
  },
  {
    path: "/master",
    module: "employee",
    component: (props) => <Master {...props} />,
    roles: [1, 10],
  },
  {
    path: "/support",
    module: "employee",
    component: (props) => <Support {...props} />,
    roles: [4, 1, 5, 8, 10],
  },
  {
    path: "/sendinvites",
    module: "employee",
    component: (props) => <SendInvites {...props} />,
    roles: [4, 8, 1, 5],
  },
  {
    path: "/telgoomap",
    module: "employee",
    component: (props) => <Telgoomap {...props} />,
    roles: [1],
  },
  {
    path: "/OnboardingSummaryReport",
    module: "employee",
    component: (props) => <Onboardingsummaryreport {...props} />,
    roles: [4, 8, 1, 5],
  },
  {
    path: "/editagent",
    module: "employee",
    component: (props) => <EditAgents {...props} />,
    roles: [4, 8, 1, 5],
  },
  {
    path: "/radlinkissues",
    module: "employee",
    component: (props) => <Radissues {...props} />,
    roles: [1],
  },
  {
    path: "/agentsummaryreport",
    module: "employee",
    component: (props) => <AgentSummaryReport {...props} />,
    roles: [4, 8, 1, 5],
  },
  {
    path: "/agentdashboard",
    module: "employee",
    component: (props) => <AgentDashboard {...props} />,
    roles: [4, 8, 1, 5],
  },
  {
    path: "/inventoryorder",
    module: "employee",
    component: (props) => <Orderlist {...props} />,
    roles: [4],
  },
  {
    path: "/rmaorders",
    module: "employee",
    component: (props) => <Rmaorders {...props} />,
    roles: [5, 4, 8, 1],
  },
  {
    path: "/rmarequestapprove",
    module: "employee",
    component: (props) => <Rmarequestapprove {...props} />,
    roles: [5, 4, 8, 1],
  },
  {
    path: "/inventoryupload",
    module: "employee",
    component: (props) => <Inventoryupload {...props} />,
    roles: [1, 10],
  },
  {
    path: "/existinginventoryupload",
    module: "employee",
    component: (props) => <Existinventoryupload {...props} />,
    roles: [1, 10],
  },
  {
    path: "/inventoryallocation",
    module: "employee",
    component: (props) => <Inventoryallocation {...props} />,
    roles: [1],
  },
  {
    path: "/agedinventory",
    module: "employee",
    component: (props) => <Agedinventory {...props} />,
    roles: [4],
  },
  {
    path: "/agedinventorymarket",
    module: "employee",
    component: (props) => <Agedinventorymarket {...props} />,
    roles: [5, 1],
  },
  {
    path: "/agedinventoryoffice",
    module: "employee",
    component: (props) => <Agedinventoryoffice {...props} />,
    roles: [5, 4, 8, 1],
  },
  {
    path: "/agedbyagent",
    module: "employee",
    component: (props) => <Agedbyagent {...props} />,
    roles: [5, 4, 8, 1],
  },
  {
    path: "/agedbymanager",
    module: "employee",
    component: (props) => <Agedbymanager {...props} />,
    roles: [5, 4, 8, 1],
  },
  {
    path: "/agedbyretailer",
    module: "employee",
    component: (props) => <Agedbyretailer {...props} />,
    roles: [1],
  },
  {
    path: "/salessummaryreport",
    module: "employee",
    component: (props) => <Salessummaryreport {...props} />,
    roles: [10, 1, 5, 4, 8],
  },
  {
    path: "/salesRetailerReport",
    module: "employee",
    component: (props) => <SalesRetailerReport1 {...props} />,
    roles: [10, 1],
  },
  {
    path: "/salesmarketreport",
    module: "employee",
    component: (props) => <Salesmarketreport {...props} />,
    roles: [10, 1, 5],
  },
  {
    path: "/salesofficereport",
    module: "employee",
    component: (props) => <Salesofficereport {...props} />,
    roles: [10, 1, 5, 4, 8],
  },
  {
    path: "/salesmanagerreport",
    module: "employee",
    component: (props) => <Salesmanagerreport {...props} />,
    roles: [10, 1, 5, 4, 8],
  },
  {
    path: "/salesagentreport",
    module: "employee",
    component: (props) => <Salesagentreport {...props} />,
    roles: [10, 1, 5, 4, 8],
  },
  {
    path: "/purchaseorder",
    module: "employee",
    component: (props) => <Purchaseorderreport {...props} />,
    // roles: [5, 4, 8],
    roles: [1, 10],
  },
  {
    path: "/inventorybyibo",
    module: "employee",
    component: (props) => <InventoryByIbo {...props} />,
    roles: [5, 1],
  },
  {
    path: "/inventorybyagent",
    module: "employee",
    component: (props) => <Inventorybyagent {...props} />,
    roles: [5, 4, 8, 1],
  },
  {
    path: "/inventorymanager",
    module: "employee",
    component: (props) => <Inventorybymanager {...props} />,
    roles: [5, 4, 8, 1],
  },
  {
    path: "/inventorybyretailer",
    module: "employee",
    component: (props) => <Inventorybyretailer {...props} />,
    roles: [1],
  },
  {
    path: "/inventorybyoffice",
    module: "employee",
    component: (props) => <InventoryByOffice {...props} />,
    roles: [5, 4, 8, 1],
  },
  {
    path: "/detailinventory",
    module: "employee",
    component: (props) => <DetailInventory {...props} />,
    roles: [5, 4, 8, 1],
  },
  {
    path: "/rmareport",
    module: "employee",
    component: (props) => <Rmareport {...props} />,
    roles: [5, 4, 8],
  },
  {
    path: "/inventorydashboard",
    module: "employee",
    component: (props) => <Inventorydashboard {...props} />,
    roles: [5, 4, 8, 1],
  },
  {
    path: "/employees",
    module: "employee",
    component: (props) => <Employees {...props} />,
    roles: [1, 4, 5, 8, 10],
  },
  {
    path: "/editmarket",
    module: "employee",
    component: (props) => <EditMarketer {...props} />,
    roles: [5],
  },
  {
    path: "/editoffice",
    module: "employee",
    component: (props) => <EditOffice {...props} />,
    roles: [4, 5, 8],
  },
  {
    path: "/addmarketer",
    module: "employee",
    component: (props) => <AddMarketer {...props} />,
    roles: [5],
  },
  {
    path: "/marketer",
    module: "employee",
    component: (props) => <Marketer {...props} />,
    roles: [1, 5, 10],
  },
  {
    path: "/salesdashboard",
    module: "employee",
    component: (props) => <Retaildashboard {...props} />,
    roles: [10, 1, 5, 4, 8],
  },
  {
    path: "/retailers",
    module: "employee",
    component: (props) => <Retailers {...props} />,
    roles: [1, 10],
  },
  {
    path: "/distributors",
    module: "employee",
    component: (props) => <Distributors {...props} />,
    roles: [1, 10],
  },
  {
    path: "/addoffice",
    module: "employee",
    component: (props) => <AddOffice {...props} />,
    roles: [4, 5, 8],
  },
  {
    path: "/office",
    module: "employee",
    component: (props) => <Office {...props} />,
    roles: [1, 4, 5, 8, 10],
  },
  {
    path: "/editemployee",
    module: "employee",
    component: (props) => <EditEmployee {...props} />,
    roles: [1, 4, 5, 8, 10],
  },
  {
    path: "/customers",
    module: "employee",
    component: (props) => <Customers {...props} />,
    roles: [4],
  },
  {
    path: "/addcustomer",
    module: "employee",
    component: (props) => <AddCustomer {...props} />,
    roles: [4],
  },
  {
    path: "/editcustomer",
    module: "employee",
    component: (props) => <EditCustomer {...props} />,
    roles: [4],
  },
  {
    path: "/userrole",
    module: "employee",
    component: (props) => <RoleList {...props} />,
    roles: [10],
  },
  {
    path: "/createrole",
    module: "employee",
    component: (props) => <CreateRole {...props} />,
    roles: [10],
  },
  {
    path: "/editrole",
    module: "employee",
    component: (props) => <EditRole {...props} />,
    roles: [10],
  },
  {
    path: "/passwordsettings",
    module: "employee",
    component: (props) => <PasswordSettings {...props} />,
    roles: [10],
  },
  {
    path: "/documents",
    module: "employee",
    component: (props) => <AgentDocuments {...props} />,
    roles: [4, 8],
  },
  {
    path: "/trainingdocs",
    module: "employee",
    component: (props) => <TrainingComponent {...props} />,
    roles: [1, 4, 5, 8, 10],
  },
  {
    path: "/trainingvideos",
    module: "employee",
    component: (props) => <TrainingVideo {...props} />,
    roles: [1, 4, 5, 8, 10],
  },
  {
    path: "/retailerpayroll",
    module: "employee",
    component: (props) => <RetailerPayroll {...props} />,
    roles: [5],
  },
  {
    path: "/faq",
    module: "employee",
    component: (props) => <FAQ {...props} />,
    roles: [1, 4, 5, 8, 10],
  },
  {
    path: "/cashpayments",
    module: "employee",
    component: (props) => <CashPayments {...props} />,
    roles: [5],
  },
  {
    path: "/announcement",
    module: "employee",
    component: (props) => <Announcement {...props} />,
    roles: [1, 4, 5, 8, 10],
  },
];
