import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Select, Button, Input, Form, message, Spin, Icon } from "antd";
import _ from "lodash";
import {
  getretailers,
  getdistributers,
  getmaster,
  getibomarketlistnew,
  createoffice,
  short_us_states,
} from "../../actions/assetAction";
import { office_manager_name } from "../../actions/userListAction";

const { Option } = Select;
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

class AddOffice extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      distributor: [],
      master: [],
      retailer: [],
      market: [],
      office: [],
      office_manager: [],
      office_supervisor: [],
      spinloader: true,
      marketrole: false,
      retailerrole: false,
      shortstate: [],
    };
  }

  componentWillMount() {
    this.props.short_us_states().then((res) => {
      console.log(res);
      this.setState({
        shortstate: res.data,
      });
    });

    const Retailer = this.props.user.role_id.filter((word) => word === 5);
    const marketer = this.props.user.role_id.filter(
      (word) => word === 5 || word === 4 || word === 8
    );

    if (marketer.length !== 0) {
      this.setState({
        marketrole: true,
      });
      this.props
        .office_manager_name(0)
        .then((res) => {
          console.log(res);
          this.setState({
            office_manager: res.data.manager_info,
            office_supervisor: res.data.manager_info,
            spinloader: false,
          });
        })
        .catch((err) => {
          this.setState({
            office_manager: [],
            office_supervisor: [],
            spinloader: false,
          });
        });
    }
    if (Retailer.length !== 0) {
      this.setState({
        retailerrole: true,
      });
    }
    this.props.getretailers().then((res) => {
      this.props.getdistributers().then((res) => {
        this.props.getmaster().then((res) => {
          this.props
            .getibomarketlistnew(this.props.user.user_id)
            .then((res) => {
              this.setState({
                distributor: this.props.data.distributor,
                master: this.props.data.master,
                retailer: this.props.data.retailer,
                market: this.props.data.ibomarket,
                spinloader: false,
              });
            });
        });
      });
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        });

        this.props
          .createoffice(values)
          .then((res) => {
            console.log(res);
            message.success("Office created successfully");
            this.props.form.resetFields();
            this.setState({
              loading: false,
            });
            this.props.history.push("/office");
          })
          .catch((err) => {
            console.log(err);
            message.error(err);
            this.setState({
              loading: false,
            });
          });
      }
    });
  };
  renderMaster() {
    return _.map(this.state.master, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderDistributor() {
    return _.map(this.state.distributor, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderRetailer() {
    return _.map(this.state.retailer, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderMarketer() {
    return _.map(this.state.market, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderoffice_manager() {
    return _.map(this.state.office_manager, (master, index) => {
      return (
        <Option value={master.user_id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderoffice_supervisor() {
    return _.map(this.state.office_supervisor, (master, index) => {
      return (
        <Option value={master.user_id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderstateshort() {
    return _.map(this.state.shortstate, (master, index) => {
      return (
        <Option value={master.short_name} key={index}>
          {master.name}
        </Option>
      );
    });
  }

  handleretailer = (e) => {
    console.log(e);
    this.setState({
      spinloader: true,
    });
    this.props.form.setFieldsValue({
      manager_id: [],
      supervisor_id: [],
    });
    this.props
      .office_manager_name(e)
      .then((res) => {
        console.log(res);
        this.setState({
          office_manager: res.data.manager_info,
          office_supervisor: res.data.manager_info,
          spinloader: false,
        });
      })
      .catch((err) => {
        this.setState({
          office_manager: [],
          office_supervisor: [],
          spinloader: false,
        });
      });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loading, spinloader } = this.state;

    return (
      <div className="container-fluid">
        <div class="row justify-content-between padd20 bg-grey">
          <div class="col-4">
            <h4 className="mt-1">Add Office</h4>
          </div>
          <div class="col-4">
            <Link to="/office" className="float-right">
              <Button type="default">Back</Button>
            </Link>
          </div>
        </div>

        <Spin indicator={antIcon} spinning={spinloader}>
          <div className=" bg-white padd15">
            <Form onSubmit={this.handleSubmit} className="addbookform row">
              {this.state.marketrole === false ? (
                <Form.Item
                  label="Master"
                  className="col-md-4 col-sm-6 col-xs-12"
                >
                  {getFieldDecorator("master", {
                    rules: [
                      { required: true, message: "Please Select Master" },
                    ],
                  })(
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select Master"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }

                      // onChange={(z) => this.setState({ hubs: _.filter(this.state.allhubs, ["Zone", z]) })}
                    >
                      {this.renderMaster()}
                    </Select>
                  )}
                </Form.Item>
              ) : null}
              {this.state.marketrole === false ? (
                <Form.Item
                  label="Distributor"
                  className="col-md-4 col-sm-6 col-xs-12"
                >
                  {getFieldDecorator("distributor", {
                    rules: [
                      {
                        required: true,
                        message: "Please input Distributor",
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select Distributor"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      // onChange={(z) => this.setState({ hubs: _.filter(this.state.allhubs, ["Zone", z]) })}
                    >
                      {this.renderDistributor()}
                    </Select>
                  )}
                </Form.Item>
              ) : null}
              {this.state.marketrole === false ? (
                <Form.Item
                  label="Retailer"
                  className="col-md-4 col-sm-6 col-xs-12"
                >
                  {getFieldDecorator("retailername", {
                    rules: [
                      {
                        required: true,
                        message: "Please input Retailer",
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select Retailer"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      // onChange={(z) => this.setState({ hubs: _.filter(this.state.allhubs, ["Zone", z]) })}
                    >
                      {this.renderRetailer()}
                    </Select>
                  )}
                </Form.Item>
              ) : null}
              {this.state.retailerrole === true ? (
                <Form.Item
                  label="IBO Name"
                  className="col-md-4 col-sm-6 col-xs-12"
                >
                  {getFieldDecorator("ibo_marketer_id", {
                    rules: [
                      {
                        required: true,
                        message: "Please select",
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select IBO Name"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(e) => this.handleretailer(e)}
                    >
                      {this.renderMarketer()}
                    </Select>
                  )}
                </Form.Item>
              ) : null}
              <Form.Item label="State" className="col-md-4 col-sm-6 col-xs-12">
                {getFieldDecorator("state_short_name", {
                  rules: [
                    {
                      required: true,
                      message: "Please select option",
                    },
                  ],
                })(
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select state"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // onChange={(e) => this.handleretailer(e)}
                  >
                    {this.renderstateshort()}
                  </Select>
                )}
              </Form.Item>
              {/* <Form.Item
                label="Office Name"
                className="col-md-4 col-sm-6 col-xs-12"
              >
                {getFieldDecorator("officename", {
                  rules: [
                    {
                      required: true,
                      message: "Please input office name",
                    },
                  ],
                })(<Input placeholder="Enter Office name" />)}
              </Form.Item> */}
              <Form.Item
                label="Office Manager"
                className="col-md-4 col-sm-6 col-xs-12"
              >
                {getFieldDecorator("manager_id", {
                  rules: [
                    {
                      required: false,
                      message: "Please Select Office Manager",
                    },
                  ],
                })(
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select Office Manager"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }

                    // onChange={(z) => this.setState({ hubs: _.filter(this.state.allhubs, ["Zone", z]) })}
                  >
                    {this.renderoffice_manager()}
                  </Select>
                )}
              </Form.Item>
              <Form.Item
                label="Office Add-on Manager"
                className="col-md-4 col-sm-6 col-xs-12"
              >
                {getFieldDecorator("supervisor_id", {
                  rules: [
                    {
                      required: false,
                      message: "Please Select Office Add-on Manager",
                    },
                  ],
                })(
                  <Select
                    showSearch
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder="Select Office Add-on Manager"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(z) => console.log(z)}
                  >
                    {this.renderoffice_supervisor()}
                  </Select>
                )}
              </Form.Item>
              <br />
              <br />
              <div className="col-md-12 col-sm-12 col-xs-12 padd15">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={loading}
                >
                  Create Office
                </Button>
              </div>
            </Form>
          </div>
        </Spin>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user, data: state.data };
}
const WrappedAddEmployee = Form.create({ name: "AddOffice" })(AddOffice);
export default connect(mapStateToProps, {
  getretailers,
  getdistributers,
  getmaster,
  getibomarketlistnew,
  createoffice,
  office_manager_name,
  short_us_states,
})(WrappedAddEmployee);
