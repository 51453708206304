import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getModules, editRoleList } from "../../actions/assetAction";
import _ from "lodash";
import { Button, Table, Select, Form, Input, Switch, message } from "antd";
import nprogress from "nprogress";
const { Option } = Select;

class EditRole extends Component {
  constructor(props) {
    super(props);

    this.state = {
      desgninfo: "",
      Modules: [],
      loading: false,
      departments: [],
    };
  }

  componentWillMount() {
    console.log(this.props.location.userrole);
    var uinfo = this.props.location.userrole;
    if (uinfo !== undefined) {
      this.setState({
        desgninfo: uinfo,
      });
      this.getModules();
      console.log(uinfo);
    } else {
      this.props.history.push("/userrole");
    }
  }

  getModules() {
    nprogress.start();
    this.props
      .getModules()
      .then((res) => {
        res.forEach((element) => {
          element.is_full_access = false;
          element.is_read = false;
          element.is_create = false;
          element.is_update = false;
          element.is_delete = false;
        });
        var arr1 = res;
        var arr2 =
          this.state.desgninfo.role_permission_mapping !== undefined
            ? this.state.desgninfo.role_permission_mapping
            : [];

        console.log(arr1);

        console.log(arr2);

        var arrResult = _.map(arr1, function (obj) {
          return _.assign(
            obj,
            _.find(arr2, {
              module_id: obj.id,
            })
          );
        });
        console.log(arrResult);
        this.setState({
          Modules: arrResult,
        });
        nprogress.done();
      })
      .catch((err) => {
        this.setState({
          Modules: [],
        });
        nprogress.done();
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      var premissions = [];
      this.state.Modules.forEach((element) => {
        premissions.push({
          is_full_access: element.is_full_access === true ? "1" : "0",
          is_read: element.is_read === true ? "1" : "0",
          is_create: element.is_create === true ? "1" : "0",
          is_update: element.is_update === true ? "1" : "0",
          is_delete: element.is_delete === true ? "1" : "0",
          module_id: element.id,
        });
      });
      console.log(
        "Received values of form: ",
        values,
        premissions,
        this.state.desgninfo
      );
      this.setState({
        loading: true,
      });
      this.props
        .editRoleList(values, premissions, this.state.desgninfo)
        .then((res) => {
          message.success("Updated Successfully!");

          this.props.history.push("/userrole");
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  handleChange = (record, text, p) => {
    // console.log(record, text, p);
    var modules = this.state.Modules;
    var index = _.findIndex(modules, { id: record.id });
    modules[index].is_full_access = p === "is_full_access" ? !text : false;
    modules[index].is_read =
      p === "is_full_access"
        ? !text
        : p === "is_read"
        ? !record.is_read
        : record.is_read;
    modules[index].is_create =
      p === "is_full_access"
        ? !text
        : p === "is_create"
        ? !record.is_create
        : record.is_create;
    modules[index].is_update =
      p === "is_full_access"
        ? !text
        : p === "is_update"
        ? !record.is_update
        : record.is_update;
    modules[index].is_delete =
      p === "is_full_access"
        ? !text
        : p === "is_delete"
        ? !record.is_delete
        : record.is_delete;
    // console.log(index, modules);
    this.setState({
      Modules: modules,
    });
  };

  mergeByProperty = (target, source, prop) => {
    source.forEach((sourceElement) => {
      let targetElement = target.find((targetElement) => {
        return sourceElement[prop] === targetElement[prop];
      });
      targetElement
        ? Object.assign(targetElement, sourceElement)
        : target.push(sourceElement);
    });
  };

  renderDepartments() {
    return _.map(this.state.departments, (dep, index) => {
      return (
        <Option value={dep.id} key={index}>
          {dep.Name}
        </Option>
      );
    });
  }

  // ======================================================

  render() {
    const { getFieldDecorator } = this.props.form;
    const { desgninfo, Modules, loading } = this.state;
    const columns = [
      {
        title: "Module",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Full Access",
        dataIndex: "is_full_access",
        key: "is_full_access",
        render: (text, record) => {
          return (
            <Switch
              checked={text}
              onChange={() => this.handleChange(record, text, "is_full_access")}
            />
          );
        },
      },
      {
        title: "Read",
        dataIndex: "is_read",
        key: "is_read",
        render: (text, record) => {
          return (
            <Switch
              checked={text}
              onChange={() => this.handleChange(record, text, "is_read")}
            />
          );
        },
      },
      {
        title: "Create",
        dataIndex: "is_create",
        key: "is_create",
        render: (text, record) => {
          return (
            <Switch
              checked={text}
              onChange={() => this.handleChange(record, text, "is_create")}
            />
          );
        },
      },
      {
        title: "Update",
        dataIndex: "is_update",
        key: "is_update",
        render: (text, record) => {
          return (
            <Switch
              checked={text}
              onChange={() => this.handleChange(record, text, "is_update")}
            />
          );
        },
      },
      {
        title: "Delete",
        dataIndex: "is_delete",
        key: "is_delete",
        render: (text, record) => {
          return (
            <Switch
              checked={text}
              onChange={() => this.handleChange(record, text, "is_delete")}
            />
          );
        },
      },
    ];

    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            <div className="col-sm-4">
              <h4>Edit Role</h4>
            </div>
            <div className="col-sm-8 text-right">
              <Link to="/userrole">
                <Button type="default">Back</Button>
              </Link>
            </div>
          </div>
          <div className="bg-white padd15">
            {desgninfo !== "" ? (
              <Form
                onSubmit={this.handleSubmit}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
                className="row"
              >
                <Form.Item className="col-sm-12 col-xs-12 bg-beige padd0">
                  <h5 className="padd15">METADATA</h5>
                </Form.Item>
                <Form.Item
                  label="Role Name"
                  className="col-md-3 col-sm-4 col-xs-6 paddlr15"
                >
                  {getFieldDecorator("desgRole", {
                    initialValue: desgninfo !== "" ? desgninfo.name : "",
                    rules: [
                      { required: true, message: "Please input role name!" },
                    ],
                  })(<Input placeholder="Enter role name" />)}
                </Form.Item>

                <Form.Item
                  label="Description"
                  className="col-md-4 col-sm-4 col-xs-6 paddlr15"
                >
                  {getFieldDecorator("Description", {
                    initialValue: desgninfo !== "" ? desgninfo.desc : "",
                    rules: [
                      { required: true, message: "Please input description!" },
                    ],
                  })(<Input placeholder="Enter description" />)}
                </Form.Item>
                <Form.Item
                  label="Status"
                  className="col-md-2 col-sm-4 col-xs-6 paddlr15"
                >
                  {getFieldDecorator("IsActive", {
                    initialValue:
                      desgninfo !== ""
                        ? desgninfo.status === 1
                          ? "true"
                          : "false"
                        : "",
                    rules: [
                      { required: true, message: "Please input description!" },
                    ],
                  })(
                    <Select
                      mode="single"
                      style={{ width: "100%" }}
                      placeholder="Select Status"
                    >
                      <Option value="true">Active</Option>
                      <Option value="false">In-Active</Option>
                    </Select>
                  )}
                </Form.Item>
                <Form.Item className="col-sm-12 col-xs-12 bg-beige padd0">
                  <h5 className="padd15">PERMISSIONS</h5>
                </Form.Item>
                <Form.Item className="col-sm-12 col-xs-12">
                  <Table
                    className="components-table-demo-nested"
                    columns={columns}
                    dataSource={Modules}
                  />
                  <Button
                    className="mr5"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    Submit
                  </Button>
                  <Button
                    className="mr5"
                    type="default"
                    onClick={() => this.getModules()}
                  >
                    Reset Permissions
                  </Button>
                  <Link to="/userrole">
                    <Button className="mr5" type="link">
                      Cancel
                    </Button>
                  </Link>
                </Form.Item>
              </Form>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    userinfo: state.userinfo,
  };
}
const WrappedComponent = Form.create({})(EditRole);
export default connect(mapStateToProps, {
  getModules,
  editRoleList,
})(WrappedComponent);
