import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {} from "../../actions/assetAction";
import { Button, Table, Tag, Icon, Input } from "antd";
import Highlighter from "react-highlight-words";
import { CSVDownloader } from "react-papaparse";
import nprogress from "nprogress";
import _ from "lodash";

class Customers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: "",
      searchedColumn: "",
      Customers: [],
      Services: [],
      userpermission: {},
    };
  }

  componentWillMount() {
    // console.log(this.props.userinfo, this.props.userinfo.Permission);
    // console.log(this.props.userinfo.Permission[2].Read);
    var userp = this.props.userinfo.Permission[2];
    if (!userp.Read) {
      this.props.history.push("/hubdashboard");
    } else {
      this.getCustomer();
      this.props.getServices().then((res) => {
        // console.log(res);
        this.setState({
          Services: res,
        });
      });
      this.setState({
        userpermission: userp,
      });
    }
  }
  getCustomer() {
    nprogress.start();
    this.props.getCustomer().then((res) => {
      console.log(res);
      this.setState({
        Customers: res,
      });
      nprogress.done();
    });
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#607d8b" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  rendercsvformat(Customerslist) {
    // console.log(Customerslist);
    var csvformat = [];

    Customerslist.forEach((element) => {
      // console.log(element);
      csvformat.push({
        CustomerID: element.CustomerID,
        Name: element.CompanyName,
        Products: element.CompanyType,
        DateCreated: element.createdAt,
        LastTransaction: element.lasttransaction,
        Active: element.IsActive ? "Active" : "In-Active",
        Address: element.Address,
        CFTDivisiontype: element.CFTDivisiontype,
        CFTDivisionvalue: element.CFTDivisionvalue,
        COD: element.CODFLag,
        ClientAPI: element.ClientAPI,
        ConnoteMaxvalue: element.ConnoteMaxvalue,
        Email: element.Email,
        GSTnumber: element.GSTnumber,
        CITClient: element.IsCITClient,
        Emailalert: element.IsEmailalert,
        ReverseShipment: element.IsReverseShipment,
        SMSalert: element.IsSMSalert,
        BoxnumAutoenerate: element.IsboxnumAutoenerate,
        Locknumberentrymandatory: element.Locknumberentrymandatory,
        MobileNo: element.MobileNo,
        OTPFlag: element.OTPFlag,
        PANnumber: element.PANnumber,
        Pincode: element.Pincode,
        City: element.cityCode,
        Contactpersonemail: element.contactpersonemail,
        Contactpersonmobile: element.contactpersonmobile,
        Contactpersonname: element.contactpersonname,
        CreatedAt: element.createdAt,
        CreatedBy: element.createdBy,
        DocketRangefrom: element.docketRangefrom,
        DocketRangeto: element.docketRangeto,
        State: element.stateCode,
      });
      console.log(csvformat, Customerslist.length, csvformat.length);
    });

    return (
      <CSVDownloader
        className={
          Customerslist.length > 0
            ? "ant-btn mr5 ant-btn-default"
            : "ant-btn mr5 ant-btn-default disabled"
        }
        data={csvformat}
        filename={"AILogistix-Customerslist"}
        type={"link"}
      >
        <Icon type="download" />
      </CSVDownloader>
    );
  }

  renderclienttype = (type) => {
    var services = this.state.Services;
    var serv = _.filter(services, { serviceId: type });
    // console.log(serv, serv.length)
    if (serv.length !== 0) {
      var d = serv[0].Name;
      // console.log('if', serv[0], d, type);
      return d;
    } else {
      // console.log('else', type)
      return type;
    }
  };

  // ======================================================

  render() {
    const { Customers, userpermission } = this.state;
    Customers.sort(function (a, b) {
      return a.createdAt - b.createdAt;
    });
    const columns = [
      {
        title: "Customer ID",
        dataIndex: "CustomerID",
        key: "CustomerID",
        ...this.getColumnSearchProps("CustomerID"),
      },
      // {
      //   title: "Name",
      //   dataIndex: "Name",
      //   key: "Name",
      //   render: (text, record) => (
      //     <span>
      //       {record.Firstname} {record.Lastname}
      //     </span>
      //   ),
      // },
      // {
      //   title: "Email",
      //   dataIndex: "Email",
      //   key: "Email",
      // },
      // {
      //   title: "MobileNo",
      //   dataIndex: "MobileNo",
      //   key: "MobileNo",
      // },
      {
        title: "Customer Name",
        dataIndex: "CompanyName",
        key: "CompanyName",

        ...this.getColumnSearchProps("CompanyName"),
      },
      {
        title: "Product List",
        dataIndex: "CompanyType",
        key: "CompanyType",
        ...this.getColumnSearchProps("CompanyType"),
        render: (type) =>
          _.map(type, (p, index) => {
            // console.log(p);
            return <Tag key={index}>{this.renderclienttype(p)}</Tag>;
          }),
      },
      {
        title: "Date Created",
        dataIndex: "createdAt",
        key: "createdAt",
        ...this.getColumnSearchProps("createdAt"),
      },
      {
        title: "Last Transaction",
        dataIndex: "lasttransaction",
        key: "lasttransaction",
        ...this.getColumnSearchProps("lasttransaction"),
      },
      {
        title: "Active",
        key: "IsActive",
        dataIndex: "IsActive",
        render: (tag) => (
          <span>
            <Tag color={tag ? "green" : "volcano"}>
              {tag ? "Active" : "In-Active"}
            </Tag>
          </span>
        ),
      },
      {
        title: "Action",
        dataIndex: "action",
        render: (text, record) => (
          <span>
            {userpermission.Update ? (
              <Link to={{ pathname: "/editcustomer", customer: record }}>
                Edit
              </Link>
            ) : null}
          </span>
        ),
      },
    ];

    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            <div className="col-sm-4">
              <h4>Customer Management</h4>
            </div>
            <div className="col-sm-8 text-right">
              {this.rendercsvformat(Customers)}
              <Button
                type="default"
                className="mr5"
                onClick={() => this.getCustomer()}
              >
                <i className="uil uil-sync" />
              </Button>
              {userpermission.Create ? (
                <Link to="/addcustomer">
                  <Button type="primary">Add Customer</Button>
                </Link>
              ) : null}
            </div>
          </div>
          <div className="bg-white padd0">
            <Table
              className="components-table-demo-nested"
              columns={columns}
              dataSource={Customers}
              expandedRowRender={(record) => (
                <div className="row">
                  <div className="col-md-3 col-sm-4 col-xs-12">
                    <p>
                      <b>Address</b> <br />
                      {record.Address}
                    </p>
                  </div>
                  <div className="col-md-3 col-sm-4 col-xs-12">
                    <p>
                      <b>GSTnumber</b> <br />
                      {record.GSTnumber}
                    </p>
                  </div>
                  <div className="col-md-3 col-sm-4 col-xs-12">
                    <p>
                      <b>PANnumber</b> <br />
                      {record.PANnumber}
                    </p>
                  </div>
                </div>
              )}
              // scroll={{ x: true }}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    userinfo: state.userinfo,
  };
}

export default connect(mapStateToProps, {})(Customers);
