import React from "react";
import "../styles/css/loading.css";
class Loading extends React.Component {
  render() {
    return (
      <div className="flex-center position-ref full-height">
        <div className="truckloader">
          <div class="loadingio-spinner-radio-zugvvusf13">
            <div class="loadingio-spinner-radio-c3k54w5i8kw">
              <div class="ldio-8g4i47htpex">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
          {/* <div className="loader triangle">
            <svg viewBox="0 0 86 80">
              <polygon points="43 8 79 72 7 72" />
            </svg>
          </div> */}
        </div>
      </div>
    );
  }
}
export default Loading;
