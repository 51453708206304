/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import FreshChat from "react-freshchat";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Tag, Result, Button } from "antd";
import { get_app_version_info } from "../actions/userAction";

class Support extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      loading: false,
      appversion: "",
    };
  }
  componentWillMount() {
    this.props
      .get_app_version_info()
      .then((res) => {
        console.log(res.data[0]);
        this.setState({ appversion: res.data[0] });
      })
      .catch((err) => {});
  }
  render() {
    return (
      <div>
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
          extra={<Button type="primary">Back Home</Button>}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user };
}

export default connect(mapStateToProps, { get_app_version_info })(Support);
